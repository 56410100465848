import React, { createContext, useRef } from 'react'

export const ContentContext = createContext({
  contentRef: null,
  scrollToTop: () => {},
  addScrollListener: () => {},
  removeScrollListener: () => {},
})

const ContentProvider = ({ children }) => {
  const contentRef = useRef(null)

  const scrollToTop = () => {
    if (contentRef && contentRef.current) {
      contentRef.current.scrollTo(0, 0)
    }
  }

  const addScrollListener = (callback) => {
    if (contentRef && contentRef.current) {
      contentRef.current.addEventListener('scroll', callback)
    }
  }

  const removeScrollListener = (callback) => {
    if (contentRef && contentRef.current) {
      contentRef.current.removeEventListener('scroll', callback)
    }
  }

  return (
    <ContentContext.Provider
      value={{
        contentRef,
        scrollToTop,
        addScrollListener,
        removeScrollListener,
      }}
    >
      {children}
    </ContentContext.Provider>
  )
}

export default ContentProvider
