import React, { useEffect } from "react";
import cn from "classnames";
import s from "styled-components";
import { useHistory } from "react-router-dom";

import { ButtonRoundBack } from "components/buttons";
import { Container } from "layout";
import useResponsive from "components/hooks/useResponsive";
import PageFooter from "./PageFooter";
import PageHeaderHome from "./PageHeader/PageHeaderHome";

const PageContainer = s.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .back-button {
    position: absolute;
    top: 108px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.--relative {
    position: relative;
  }

  main {
    flex: 1;
    padding: ${({ noPadding }) => (noPadding ? 0 : "68px 0 100px 0")};

    @media (max-width: 768px) {
      padding: ${({ noPadding }) => (noPadding ? 0 : "40px 0 60px 0")};
    }
  }
`;

const Page = ({ className, children, noPadding, isSecond = false, back = false }) => {
  const history = useHistory();
  const { isLaptopBigOrMobile } = useResponsive();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location]);

  const handleGoBack = () => {
    if (typeof back === "number") {
      history.push(history.location.pathname.slice(0, history.location.pathname.lastIndexOf("/")));
    } else if (typeof back === "string") {
      history.push(back);
    } else {
      history.goBack();
    }
  };

  return (
    <PageContainer noPadding={noPadding} className={cn("page", { "--relative": back }, className)}>
      {back && !isLaptopBigOrMobile && (
        <Container className="back-button">
          <ButtonRoundBack onClick={handleGoBack} />
        </Container>
      )}
      <PageHeaderHome isSecond={isSecond} />
      <main>{children}</main>
      <PageFooter />
    </PageContainer>
  );
};

export default Page;
