import React, { Suspense } from 'react'
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'

import InterfacePreloader from 'layout/Interface/InterfacePreloader'
import ErrorPage from 'pages/ErrorPage'

const RoutesTransition = (props) => {
  const {
    routes,
    rootPath = '',
    dispatch,
  } = props
  const match = useRouteMatch()
  const history = useHistory()

  return (
    <Suspense fallback={<InterfacePreloader position="absolute" />}>
      <Switch>
        {routes.map(({ path, ...otherProps }, idx) => (
          <Route
            key={idx}
            history={history}
            path={`${rootPath}${path}`}
            match={match}
            dispatch={dispatch}
            {...otherProps}
          />
        ))}
        <Route path="*" render={() => <ErrorPage title="👽&nbsp;Страница не&nbsp;найдена..." />} />
      </Switch>
    </Suspense>
  )
}

export default RoutesTransition
