import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Formik, Field } from 'formik'

// components
import { Button } from 'components/base'
import useFirebase from 'services/Firebase'
import { Box } from 'layout'
import { Title } from 'components/typo'

import {
  Form,
  FormGroup,
} from 'components/form'
import useResponsive from 'components/hooks/useResponsive'

// actions
import { setPhone } from 'redux/actions/AuthActions'
import { setModal } from 'redux/actions/SystemActions'

// constants
import { SchemaPhone } from 'constants/ValidationSchemas'

// helpers
import { getNumbers } from 'helpers/RegExp'

// images
import { ReactComponent as IconDefault } from 'assets/icons/multy/key-default.svg'
import { ReactComponent as IconSuccess } from 'assets/icons/multy/key-success.svg'
import FormFieldPhone from 'components/form/FormFieldPhone'

const ForgetPassword = () => {
  const { isLaptopOrMobile } = useResponsive()
  const dispatch = useDispatch()
  const captchaRef = useRef(null)
  const {
    phoneSignIn,
    isFetchingPhoneAuth,
    resetRecaptcha,
  } = useFirebase()

  const handleSignIn = (phone, setSubmitting) => {
    resetRecaptcha(captchaRef.current)
    phoneSignIn(phone)
      .then(() => {
        setSubmitting(false)
        dispatch(setModal({
          name: 'phone-confirm',
          back: true,
          data: { password: true },
        }))
      })
      .catch(() => {
        setSubmitting(false)
        resetRecaptcha()
      })
  }

  return (
    <Formik
      initialValues={{ phone: '' }}
      onSubmit={(values, { setSubmitting }) => {
        const phone = `+${getNumbers(values.phone)}`

        dispatch(setPhone(phone))
        handleSignIn(phone, setSubmitting)
      }}
      validationSchema={SchemaPhone}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          isSubmitting,
        } = props

        const attrs = {
          onChange: handleChange,
          onBlur: handleBlur,
          errors,
          touched,
          size: 'big',
        }

        const formIsValid
          = isValid && dirty

        const Icon = formIsValid ? IconSuccess : IconDefault

        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Box flex jc="center" className={isLaptopOrMobile ? 'mb-3' : 'mb-30'}>
              <Icon width={55} height={52.5} />
            </Box>
            <Box flex jc="center" className={isLaptopOrMobile ? 'mb-20' : 'mb-5'}>
              <Title tag="h2">Забыли пароль?</Title>
            </Box>
            <FormGroup>
              <Field>
                {() => (
                  <FormFieldPhone
                    {...attrs}
                    value={values.phone}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup>
              <Button
                defaultStyle="acsent"
                type="submit"
                size="big"
                full
                title="Получить код по SMS"
                isFetching={isFetchingPhoneAuth || isSubmitting}
                isDisabled={isFetchingPhoneAuth || isSubmitting || !formIsValid}
              />
            </FormGroup>
            <div ref={captchaRef}>
              <div id="recaptcha-container" />
            </div>

          </Form>
        )
      }}
    </Formik>
  )
}

export default ForgetPassword
