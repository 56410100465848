import { CategoriesActionTypes } from 'redux/types/CategoriesTypes'
import { ProductsActionTypes } from 'redux/types/ProductsTypes'
import { UserActionTypes } from 'redux/types/UserTypes'

const INITIAL_STATE = {
  name: 'categories',
  homeList: [],
  homeListIsEmpty: [],
  modalList: [],
  modalListIsEmpty: [],
  allList: [],
  allListIsEmpty: [],
  isFetching: true,
  isFetchingToggleActive: false,
  isSubmitting: false,
}

const categoriesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE }

  case CategoriesActionTypes.SET_FETCHING_CATEGORIES:
    return {
      ...state,
      isFetching: action.payload,
    }

  case CategoriesActionTypes.FETCH_CATEGORIES_HOME_SUCCESS:
    return {
      ...state,
      homeList: [ ...action.payload.filter((c) => !c.is_no_category) ],
    }

  case CategoriesActionTypes.SET_CATEGORIES_HOME_IS_EMPTY:
    return {
      ...state,
      homeListIsEmpty: action.payload,
    }

  case CategoriesActionTypes.CLEAR_CATEGORIES_HOME:
    return {
      ...state,
      homeList: [],
      homeListIsEmpty: false,
    }

  case CategoriesActionTypes.FETCH_CATEGORIES_MODAL_SUCCESS:
    return {
      ...state,
      modalList: action.payload,
    }

  case CategoriesActionTypes.SET_CATEGORIES_MODAL_IS_EMPTY:
    return {
      ...state,
      modalListIsEmpty: action.payload,
    }

  case CategoriesActionTypes.CLEAR_CATEGORIES_MODAL:
    return {
      ...state,
      modalList: [],
      modalListIsEmpty: false,
    }

  case CategoriesActionTypes.FETCH_CATEGORIES_ALL_SUCCESS:
    return {
      ...state,
      allList: action.payload,
    }

  case CategoriesActionTypes.SET_CATEGORIES_ALL_IS_EMPTY:
    return {
      ...state,
      allListIsEmpty: action.payload,
    }

  case CategoriesActionTypes.CLEAR_CATEGORIES_ALL:
    return {
      ...state,
      allList: [],
      allListIsEmpty: false,
    }

  case CategoriesActionTypes.SET_FETCHING_CATEGORY_SUBMITTING:
    return {
      ...state,
      isSubmitting: action.payload,
    }

  case CategoriesActionTypes.FETCH_CATEGORY_CREATE_SUCCESS:
    return {
      ...state,
      homeList: [ ...state.homeList, action.payload ].sort((a, b) => {
        if (a.position > b.position) return 1
        if (a.position < b.position) return -1
        return -1
      }),
    }

  case CategoriesActionTypes.FETCH_CATEGORY_UPDATE_SUCCESS: {
    const isExistIdx = state.homeList.findIndex((c) => c.id === action.payload.id)

    if (isExistIdx >= 0) {
      return {
        ...state,
        homeList: [
          ...state.homeList.slice(0, isExistIdx),
          action.payload,
          ...state.homeList.slice(isExistIdx + 1),
        ],
      }
    }

    return state
  }

  case CategoriesActionTypes.FETCH_CATEGORY_DELETE_SUCCESS:
    return {
      ...state,
      homeList: state.homeList.filter((c) => c.id !== action.payload),
    }

  case CategoriesActionTypes.SET_FETCHING_CATEGORY_TOGGLE_ACTIVE:
    return {
      ...state,
      isFetchingToggleActive: action.payload,
    }

  case CategoriesActionTypes.FETCH_CATEGORY_TOGGLE_ACTIVE_SUCCESS:
    return {
      ...state,
      homeList: [
        ...state.homeList.map((p) => (p.id === action.payload.id
          ? action.payload
          : p)),
      ],
    }

  case CategoriesActionTypes.FETCH_CATEGORY_TOGGLE_PRODUCT_ACTIVE_SUCCESS: {
    const isCategoryIdxExist = state.allList.findIndex((c) => c.id === action.payload.category)
    const isProductExist = isCategoryIdxExist !== -1
      ? state.allList[isCategoryIdxExist].products.find((p) => p.id === action.payload.id) : null

    if (isProductExist) {
      return {
        ...state,
        allList: [ ...state.allList.map((category) => {
          if (category.id === action.payload.category) {
            return {
              ...category,
              products: [ ...category.products.map((product) => {
                if (product.id === action.payload.id) {
                  return {
                    ...product,
                    is_active: !product.is_active,
                  }
                }

                return product
              }) ],
            }
          }

          return category
        }) ],
      }
    }

    return state
  }

  case ProductsActionTypes.FETCH_PRODUCT_UPDATE_SUCCESS: {
    if (state.allList.length) {
      return {
        ...state,
        allList: [
          ...state.allList.map((category) => {
            const isProductIdx = category.products.findIndex((product) => (product.id === action.payload.id))

            if (category.id === action.payload.category) {
              return {
                ...category,
                products: isProductIdx >= 0 ? [ ...category.products.map((product) => (product.id === action.payload.id
                  ? action.payload
                  : product)) ]
                  : [ action.payload, ...category.products ],
              }
            }

            // filter product from categories if product category changed
            return isProductIdx >= 0 ? {
              ...category,
              products: [ ...category.products.filter((p) => p.id !== action.payload.id) ],
            } : category
          }),
        ],
      }
    }

    return state
  }

  case ProductsActionTypes.FETCH_PRODUCT_DELETE_SUCCESS: {
    if (state.allList.length) {
      return {
        ...state,
        allList: [
          ...state.allList.map((category) => {
            if (category.id === action.payload.category) {
              return {
                ...category,
                products: [ ...category.products.filter((product) => (product.id !== action.payload.id)) ],
              }
            }

            return category
          }),
        ],
      }
    }

    return state
  }

  case ProductsActionTypes.FETCH_PRODUCT_CREATE_SUCCESS: {
    if (state.allList.length) {
      return {
        ...state,
        allList: [
          ...state.allList.map((category) => {
            if (category.id === action.payload.category) {
              return {
                ...category,
                products: [ action.payload, ...category.products ],
              }
            }

            return category
          }),
        ],
      }
    }

    return state
  }

  default:
    return state
  }
}

export default categoriesReducer
