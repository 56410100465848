import React, { useState } from 'react'

import { ReactComponent as IconDocument } from 'assets/icons/document.svg'

// components
import { Box } from 'layout'
import {
  Modal,
  ModalContent,
} from 'components/modal'
import { Button } from 'components/base'
import { Text } from 'components/typo'
import { FormSelect } from 'components/form'

import { clientsStatusesModel } from 'constants/ClientsTypes'

const ModalConfirm = (props) => {
  const [ selected, setSelected ] = useState(null)
  const {
    // closeModal,
    modal: {
      // onYes,
      isSubmitting,
    },
  } = props

  // const handleYes = () => {
  //   onYes(closeModal)
  // }

  const handleDownload = () => {
    // TODO DOWNLOAD PDF
  }

  return (
    <Modal
      center
      width="confirm"
      noOverflow
    >
      <ModalContent
        display="flex"
        overflowX="unset"
        overflowY="unset"
        column
        p="39px 36px 36px 36px"
      >
        <div className="mb-3">
          <IconDocument fill="currentColor" />
        </div>
        <div className="mb-3">
          <Text fw="sb">Сохранить базу клиентовв Excel файл</Text>
        </div>
        <Box width="100%" className="mb-4">
          <FormSelect
            name="status"
            isMulti
            value={selected}
            selectedOption={selected}
            options={clientsStatusesModel}
            onChange={(value) => setSelected(value)}
            placeholder="Все клиенты"
            errors={{}}
            touched={{}}
            {...props}
          />
        </Box>
        <Button
          full
          defaultStyle="green"
          title="Выбрать"
          isDisabled={isSubmitting}
          isFetching={isSubmitting}
          onClick={handleDownload}
        />
      </ModalContent>
    </Modal>
  )
}

export default ModalConfirm
