import React from 'react'

import { ButtonRound } from 'components/base'
import { ReactComponent as IconBack } from 'assets/icons/arrow-back.svg'
import { ReactComponent as IconForward } from 'assets/icons/arrow-forward.svg'

const ButtonRoundBack = ({
  onClick,
  forward = false,
  ...otherProps
}) => (
  <ButtonRound
    {...otherProps}
    padding="normal"
    bgColor="light"
    onClick={onClick}
    renderIcon={() => (forward ? <IconForward /> : <IconBack />)}
  />
)

export default ButtonRoundBack
