import React from 'react'

import Box from 'layout/Box'
import Link from 'components/typo/Link'
import Card from 'components/Card/Card'
import Text from 'components/typo/Text'

import { LICENSE, POLITIC } from 'constants/PathsTypes'
import Formatted from 'components/typo/Formatted'

const ModalSubscriptionsBanner = ({ isLaptopOrMobile }) => (
  <Card bg="grey-blur" p={isLaptopOrMobile ? '20px 60px 20px 20px' : '20px 80px'} center={!isLaptopOrMobile}>
    <Box display="block" m="0 auto" maxWidth="812px">
      <Text size={isLaptopOrMobile ? 'small' : 'normal'} color="grey500">
        <Formatted id="tariff.banner.text.1" />
        {' '}
        <Link
          browser
          target="_blank"
          size={isLaptopOrMobile ? 'small' : ''}
          display="inline"
          fs="inherit"
          lh="inherit"
          href={LICENSE}
        >
          <Formatted id="tariff.banner.license" />
        </Link>
        {' '}
        <Formatted id="tariff.banner.text.2" />
        {' '}
        <Link
          browser
          target="_blank"
          size={isLaptopOrMobile ? 'small' : ''}
          display="inline"
          fs="inherit"
          lh="inherit"
          href={POLITIC}
        >
          <Formatted id="tariff.banner.politic" />
          {' '}
          <span aria-label="emoji" role="img">🎩</span>
        </Link>
      </Text>
    </Box>
  </Card>
)

export default ModalSubscriptionsBanner
