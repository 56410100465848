import React, { useState } from 'react'

const WithHover = (WrappedComponent) => {
  const WithHoverComponent = (props) => {
    const [ isHovered, setHover ] = useState(false)
    const [ isFocused, setFocus ] = useState(false)

    const handleMouseEnter = () => {
      setHover(true)
    }
    const handleMouseLeave = () => {
      setHover(false)
    }

    const handleFocus = () => {
      setFocus(true)
    }
    const handleBlur = () => {
      setFocus(false)
    }

    return (
      <WrappedComponent
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={handleFocus}
        onBlur={handleBlur}
        isHovered={isHovered}
        isFocused={isFocused}
        {...props}
      />
    )
  }

  return WithHoverComponent
}

export default WithHover
