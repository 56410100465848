import React from 'react'
import s from 'styled-components'
import cn from 'classnames'

import useResponsive from 'components/hooks/useResponsive'
import TextFormatted from 'components/typo/TextFormatted'

const defaultStyles = ({ theme }) => `
  display: inline-flex;
  padding: 8px;
  background-color: ${theme.colors.grey100};
  border-radius: 100px;

  &.--mob {
    width: 100%;
  }
`

const TabsContainer = s.div`
  ${(props) => defaultStyles(props)}
`

const Tabs = ({
  title,
  children,
  items = [],
  onToggle = () => {},
  active,
  ...otherProps
}) => {
  const { isLaptopOrMobile } = useResponsive()

  return (
    <TabsContainer
      className={cn('w-tabs', { '--mob': isLaptopOrMobile })}
      {...otherProps}
    >
      {children || items.map((tab) => (
        <Tab
          key={tab.id}
          isLaptopOrMobile={isLaptopOrMobile}
          active={active === tab.id}
          onClick={() => onToggle(tab.id)}
          {...tab}
        />
      ))}
    </TabsContainer>
  )
}

const tabStyles = ({ theme }) => `
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 124px;
  padding: 8px;
  background-color: ${theme.colors.grey100};
  color: ${theme.colors.grey500};
  border-radius: 100px;
  transition: 0.3s ease-in-out;

  &.--mob {
    height: 36px;
    padding: 6.5px 8px;
    width: auto;
    flex: 1;
  }

  &:not(.--active):hover .w-tab-text {
    color: ${theme.colors.black};
  }

  &.--active {
    background-color: ${theme.colors.black};
    color: ${theme.colors.white};
  }
`

const TabContainer = s.button`
  ${(props) => tabStyles(props)}
`

const Tab = ({
  title = '',
  active = false,
  onClick = () => {},
  isLaptopOrMobile = false,
}) => (
  <TabContainer
    onClick={onClick}
    className={cn('w-tab', {
      '--active': active,
      '--mob': isLaptopOrMobile,
    })}
  >
    <TextFormatted
      className="w-tab-text"
      size="medium"
      fw="sb"
      color={active ? 'white' : 'grey500'}
      transitionOn
      titleId={title}
    />
  </TabContainer>
)

export default Tabs
