import firebase from 'firebase/app'
import 'firebase/auth'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

// redux
import { setNotification } from 'redux/actions/SystemActions'

// types
import { messageTypes } from 'constants/NotifierTypes'

const firebaseConfig = {
  apiKey: 'AIzaSyAwS3sXwmvCVEok_n7IkYkFfFocTksZ3g8',
  authDomain: 'wiby-test.firebaseapp.com',
  projectId: 'wiby-test',
  storageBucket: 'wiby-test.appspot.com',
  messagingSenderId: '834276963311',
  appId: '1:834276963311:web:d7380d5fc20140a805bc1a',
  measurementId: 'G-6Z8LN7E803',
}

firebase.initializeApp(firebaseConfig)

const FirebaseService = () => {
  const dispatch = useDispatch()
  const [ isFetchingPhoneAuth, setFetchingPhoneAuth ] = useState()
  const [ isFetchingCodeVerification, setFetchingCodeVerification ] = useState()

  const setupRecaptcha = (phone) => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      callback: () => phoneSignIn(phone),
      'expired-callback': () => {
        resetRecaptcha()
      },
    })
  }

  const resetRecaptcha = () => {
    if (window.recaptchaVerifier) {
      const recaptchaWrapper = document.getElementById('recaptcha-container').parentElement

      window.recaptchaVerifier.clear()

      if (recaptchaWrapper) {
        recaptchaWrapper.innerHTML = `<div id="recaptcha-container"></div>`
      }
    }
  }

  const phoneSignIn = (phone) => {
    setFetchingPhoneAuth(true)
    setupRecaptcha()

    return new Promise((res, rej) => {
      firebase
        .auth()
        .signInWithPhoneNumber(phone, window.recaptchaVerifier)
        .then((confirmationResult) => {
          setFetchingPhoneAuth(false)
          window.confirmationResult = confirmationResult
          return res(confirmationResult)
        })
        .catch((error) => {
          setFetchingPhoneAuth(false)
          dispatch(setNotification({
            message: error.message,
            type: messageTypes.ERROR,
          }))

          return rej(error)
        })
    })
  }

  const codeVerification = (code) => {
    setFetchingCodeVerification(true)

    return new Promise((res, rej) => {
      window.confirmationResult
        .confirm(code)
        .then((result) => {
          // User signed in successfully.
          setFetchingCodeVerification(false)
          return res(result)
        })
        .catch((error) => {
          setFetchingCodeVerification(false)
          dispatch(setNotification({
            message: error.message,
            type: messageTypes.ERROR,
          }))

          return rej(error)
        })
    })
  }

  const getCurrentUserTokenId = () => new Promise((res, rej) => firebase.auth().currentUser.getIdToken()
    .then((token) => res(token))
    .catch((error) => {
      if (typeof error === 'string') {
        dispatch(setNotification({
          message: error.message,
          type: messageTypes.ERROR,
        }))
      }
      else if (typeof error === 'object') {
        Object.keys(error).forEach((key) => {
          dispatch(setNotification({
            message: error[key][0],
            type: messageTypes.ERROR,
          }))
        })
      }

      return rej(error)
    }))

  const signOut = () => {
    firebase.auth().signOut().then(() => {
      dispatch(setNotification({
        message: 'Выход выполнен успешно',
        type: messageTypes.CHECK,
      }))
    }).catch((error) => {
      dispatch(setNotification({
        message: error,
        type: messageTypes.ERROR,
      }))
    })
  }

  return {
    isFetchingPhoneAuth,
    isFetchingCodeVerification,
    setupRecaptcha,
    phoneSignIn,
    codeVerification,
    resetRecaptcha,
    signOut,
    getCurrentUserTokenId,
  }
}

export default FirebaseService
