import { DemosActionTypes } from 'redux/types/DemosTypes'
import { UserActionTypes } from 'redux/types/UserTypes'

const INITIAL_STATE = {
  name: 'demos',
  homeList: [],
  homeListIsEmpty: false,
  isFetching: false,
}

const demosReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE }

  case DemosActionTypes.SET_FETCHING_DEMOS:
    return {
      ...state,
      isFetching: action.payload,
    }

  case DemosActionTypes.FETCH_DEMOS_HOME_SUCCESS:
    return {
      ...state,
      homeList: action.payload,
    }

  case DemosActionTypes.SET_DEMOS_HOME_IS_EMPTY:
    return {
      ...state,
      homeListIsEmpty: action.payload,
    }

  case DemosActionTypes.CLEAR_DEMOS_HOME:
    return {
      ...state,
      homeList: [],
      homeListIsEmpty: false,
    }

  default:
    return state
  }
}

export default demosReducer
