import { ReactComponent as IconMoney } from 'assets/icons/payment/money.svg'
import { ReactComponent as IconCard } from 'assets/icons/payment/card.svg'
import { ReactComponent as IconCardOnline } from 'assets/icons/payment/card-online.svg'
import { ReactComponent as IconApplePay } from 'assets/icons/payment/apple-pay.svg'
import { ReactComponent as IconGooglePay } from 'assets/icons/payment/google-pay.svg'

export const scheduleWeek = []

export const WEEK_DAYS = [
  'days.monday',
  'days.tuesday',
  'days.wednesday',
  'days.thursday',
  'days.friday',
  'days.saturday',
  'days.sunday',
]

WEEK_DAYS.forEach((label, idx) => {
  scheduleWeek.push({
    id: null,
    week_day: idx,
    label,
    time_from: '',
    real_time_from: '',
    time_to: '',
    real_time_to: '',
    active: false,
  })
})

export const PAYMENT_TYPES = {
  CASH_FACT: {
    id: 'CASH_FACT',
    titleId: 'payment.card.method.cash_fact',
    icon: IconMoney,
  },
  CARD_FACT: {
    id: 'CARD_FACT',
    titleId: 'payment.card.method.card_fact',
    icon: IconCard,
  },
  CARD_ONLINE: {
    id: 'CARD_ONLINE',
    titleId: 'payment.card.method.card_online',
    icon: IconCardOnline,
  },
  APPLE_PAY: {
    id: 'APPLE_PAY',
    titleId: 'payment.card.method.apple_pay',
    icon: IconApplePay,
  },
  GOOGLE_PAY: {
    id: 'GOOGLE_PAY',
    titleId: 'payment.card.method.google_pay',
    icon: IconGooglePay,
  },
}

export const SOCIALS_TYPES = {
  INSTAGRAM: 'INSTAGRAM',
  VK: 'VK',
  FACEBOOK: 'FACEBOOK',
  TELEGRAM: 'TELEGRAM',
  WHATS_APP: 'WHATS_APP',
}

export const APP_MENU_OPTIONS_TYPES = {
  MENU: 'MENU',
  FAVS: 'FAVS',
  CONTACTS: 'CONTACTS',
  PROFILE: 'PROFILE',
  BUCKET: 'BUCKET',
}

export const SOCIALS = {
  [SOCIALS_TYPES.INSTAGRAM]: 'Instagram',
  [SOCIALS_TYPES.VK]: 'Вконтакте',
  [SOCIALS_TYPES.FACEBOOK]: 'Facebook',
  [SOCIALS_TYPES.TELEGRAM]: 'Telegram',
  [SOCIALS_TYPES.WHATS_APP]: 'WhatsApp',
}

export const socialsModel = [
  ...Object.keys(SOCIALS).map((key) => ({
    id: key,
    label: SOCIALS[key],
    value: '',
    isActive: '',
  })),
]
