import React from 'react'
import { useDispatch } from 'react-redux'

// components
import { Box } from 'layout'
import { ButtonArrow } from 'components/buttons'
import {
  Modal,
  ModalBanner,
  ModalContent,
  ModalActions,
  ModalContentWrapper,
} from 'components/modal'
import Registration from 'components/Registration'

// redux
import { setModal } from 'redux/actions/SystemActions'

// images
import { ReactComponent as Icon } from 'assets/icons/profile-create.svg'
import bgImage from 'assets/images/modals/signup-bg.jpg'

const ModalRegistration = ({
  closeModal,
  isLaptopOrMobile,
  isLaptopBigOrMobile,
}) => {
  const dispatch = useDispatch()

  const actions = (
    <ButtonArrow
      size="big"
      defaultStyle="blured"
      titleId="button.account.login"
      onClick={() => handleModal({ name: 'login' })}
    />
  )

  const handleModal = (modal) => dispatch(setModal(modal))

  return (
    <Modal flex size="full">
      <ModalActions closeModal={closeModal} />
      {!isLaptopBigOrMobile && (
        <ModalBanner
          center
          img={bgImage}
          title="modals.signin.title"
          description="modals.signin.description"
          actions={actions}
          author={null}
        />
      )}
      <ModalContentWrapper>
        <ModalContent p="withSidebar">
          <Box ai="center" flex direction="column">
            <Box className={isLaptopOrMobile ? 'mb-3' : 'mb-30'}>
              <Icon width={55} height={52.5} />
            </Box>
            <Box self="stretch">
              <Registration isLaptopOrMobile={isLaptopOrMobile} handleModal={handleModal} />
            </Box>
          </Box>
        </ModalContent>
      </ModalContentWrapper>
    </Modal>
  )
}

export default ModalRegistration
