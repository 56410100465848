import React from 'react'
import { Box } from 'layout'

const Line = ({
  children,
  ...props
}) => (
  <Box
    flex
    jc="space-between"
    ai="center"
    {...props}
  >
    {children}
  </Box>
)

export default Line
