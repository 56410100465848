import React, {
  useEffect, useRef, useState,
} from 'react'
import s from 'styled-components'
import cn from 'classnames'

const ScrollListContainer = s.div`
  overflow-y: auto;
  scroll-width: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  .scroll-inner {
    display: flex;
    align-items: stretch;
    margin: 0 -15px;
  }

  &.--full-h .scroll-item > * {
    height: 100%;
  }

  .scroll-item {
    flex: 1;
    padding: 0 10px;


    @media (max-width: 768px) {
      padding: 0 5px;
    }

    &:first-child {
      padding-left: 15px;
    }

    &:last-child {
      padding-right: 15px;
    }
  }

  // &.--mob {
  //   padding-top: 32px;
  // }
`

const ScrollList = ({
  isLaptopOrMobile,
  isLaptopBigOrMobile = false,
  isFullHeight = false,
  items = [],
  renderCard = () => {},
}) => {
  const containerRef = useRef(null)
  const [ offsetValue, setOffsetValue ] = useState(null)

  const resizeListener = () => {
    const container = document.querySelector('.c-container')

    if (container) {
      const containerWidth = container.getBoundingClientRect().width
      const offset = (window.innerWidth - containerWidth) / 2
      setOffsetValue(offset < 15 ? 15 : offset.toFixed(0))
    }
  }

  useEffect(() => {
    if (containerRef && containerRef.current) {
      resizeListener()
      window.addEventListener('resize', resizeListener)

      return () => {
        window.removeEventListener('resize', resizeListener)
      }
    }
  }, [ containerRef ])

  return (
    <ScrollListContainer
      ref={containerRef}
      className={cn('scroll-list', {
        '--mob': isLaptopOrMobile,
        '--full-h': isFullHeight,
        '--laptop-big': !isLaptopOrMobile && isLaptopBigOrMobile,
      })}
      style={{
        margin: offsetValue ? `0 -${offsetValue}px` : null,
        padding: offsetValue ? `0 ${offsetValue}px` : null,
      }}
    >

      <div className="scroll-inner">
        {items.map((item, idx) => (
          <div className="scroll-item" key={idx}>
            {renderCard(item)}
          </div>
        ))}
      </div>
    </ScrollListContainer>
  )
}

export default ScrollList
