import React from 'react'

import { Button } from 'components/base'
import { ReactComponent as IconPlus } from 'assets/icons/plus.svg'

const ButtonAdd = ({
  titleId = 'button.add',
  title = '',
  iconHide = false,
  defaultStyle = 'transparent',
  ...otherProps
}) => (
  <Button
    titleId={titleId}
    title={title}
    defaultStyle={defaultStyle}
    nopadding={defaultStyle === 'transparent'}
    add={defaultStyle !== 'transparent'}
    {...otherProps}
  >

    {!iconHide && (
      <IconPlus
        width={18}
        height={18}
      />
    )}
  </Button>
)

export default ButtonAdd
