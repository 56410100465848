import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import s from 'styled-components'
import cn from 'classnames'

import { Tabs, ModalCenterWrapper } from 'components/base'
import { Box } from 'layout'
import SubscriptionsList from 'components/Subscriptions'
import useResponsive from 'components/hooks/useResponsive'

import {
  fetchBuySubscriptionsAsync,
  fetchSubscriptions,
  setCompanySubscription,
} from 'redux/actions/SubscriptionsActions'
import { setModal } from 'redux/actions/SystemActions'
import { SUBSCRIPTIONS, PeriodTabsModel } from 'constants/SubscriptionsTypes'

import StorageService from 'services/StorageService'
import ModalConfirm from './modals/ModalConfirm'
import Spinner from './base/Spinner'

const SubscriptionsBlockContainer = s.div`
  .subscriptions__tabs {
    @media (max-width: 768px) {
      max-width: 500px;
      margin: 0 auto;
    }
  }
`

const SubscriptionsBlock = ({
  children,
  withHover = false,
  ...props
}) => {
  const dispatch = useDispatch()
  const { list: subscriptions, isFetchingBuy: isFetchingSubscription } = useSelector((state) => state.subscriptions)
  const { profile } = useSelector((state) => state.user)
  const [ activeTab, setActiveTab ] = useState(PeriodTabsModel[1].id)
  const [ showConfirmModal, setShowConfirmModal ] = useState(false)
  const [ buyPeriodId, setBuyPeriodId ] = useState(false)
  const { isLaptopOrMobile } = useResponsive()

  const isAuth = !!profile

  const onToggle = useCallback((subscription) => {
    if (isAuth) {
      dispatch(setModal({
        name: 'order-subscription',
        data: subscription,
      }))
    }
    else {
      dispatch(setModal({ name: 'signup' }))
    }
  }, [ isAuth, dispatch ])

  useEffect(() => {
    if (!subscriptions.length) {
      dispatch(fetchSubscriptions())
    }

    if (profile && profile.company && profile.company.active_subscription && profile.company.active_subscription.rate_period.period) {
      setActiveTab(profile.company.active_subscription.rate_period.period)
    }

    // eslint-disable-next-line
  }, [])

  const handleSubscription = useCallback((id) => {
    setActiveTab(id)
  }, [ setActiveTab ])

  const userSubscription = useMemo(() => {
    if (profile && profile.company && profile.company.active_subscription) {
      return {
        ended_at: profile.company.active_subscription.ended_at,
        period: profile.company.active_subscription.rate_period.id,
        subscriptionId: profile.company.active_subscription.rate_period.rate,
      }
    }

    return {}
  }, [ profile ])

  const mappedSubscriptions = useMemo(() => SUBSCRIPTIONS.map((subscriptionMock) => {
    const subscription = subscriptions.find((s) => s.id === subscriptionMock.id)

    if (subscription) {
      return {
        ...subscriptionMock,
        title: subscription.title,
        description: subscription.description,
        list: [ ...subscription.options.map((o) => o.title) ],
        periodsPrices: subscriptionMock.periodsPrices || [],
        info: subscription.comment || null,
        rate_type: subscription.rate_type,
      }
    }

    return subscriptionMock
  }), [ subscriptions ])

  const handleCloseConfirmModal = useCallback(() => {
    setShowConfirmModal(false)
  }, [ setShowConfirmModal ])

  const handleShowConfirmModal = useCallback((activePeriodPriceId) => {
    setShowConfirmModal(true)
    setBuyPeriodId(activePeriodPriceId)
  }, [ setShowConfirmModal ])

  const handleBuySubscription = useCallback((ratePeriodId, isModal) => {
    const data = {
      payment_token: StorageService.getToken(),
      rate_period: ratePeriodId,
      // is_auto_renewal: false,
    }

    if (isModal) {
      handleCloseConfirmModal()
    }

    dispatch(fetchBuySubscriptionsAsync(data)).then((res) => {
      const { yandex_payment: yaPayment } = res

      if (!yaPayment) {
        dispatch(setCompanySubscription(res))
      }
      else {
        const { payment_url: paymenUrl } = yaPayment
        window.location.href = paymenUrl
      }
    })
  }, [ dispatch, handleCloseConfirmModal ])

  return (
    <SubscriptionsBlockContainer className={cn('subscriptions', { '--mob': isLaptopOrMobile })} {...props}>
      <Box
        jc="center"
        className={cn('subscriptions__tabs', {
          'mb-6': !isLaptopOrMobile,
          'mb-4 --mob': isLaptopOrMobile,
        })}
      >
        <Tabs
          items={PeriodTabsModel}
          active={activeTab}
          onToggle={handleSubscription}
        />
      </Box>
      <div className="subscriptions__list">
        {isFetchingSubscription || !subscriptions || subscriptions.length === 0
          ? <Spinner />
          : (
            <SubscriptionsList
              activeSubscriptionEndedAt={userSubscription.ended_at}
              activeSubscription={userSubscription.subscriptionId}
              activeSubscriptionPeriod={userSubscription.period}
              activeUserSubscription={userSubscription}
              onToggle={onToggle}
              isAuth={isAuth}
              onBuy={handleBuySubscription}
              showConfirmModal={handleShowConfirmModal}
              activePeriod={activeTab}
              subscriptions={mappedSubscriptions}
              withHover={withHover}
              isFetchingSubscription={isFetchingSubscription}
            />
          )}
      </div>
      {showConfirmModal && (
        <ModalCenterWrapper
          closeModal={handleCloseConfirmModal}
          modal={{
            onYes: () => handleBuySubscription(buyPeriodId, true),
            type: 'cancel-subscription',
          }}
          show={showConfirmModal}
          component={ModalConfirm}
        />
      )}
    </SubscriptionsBlockContainer>
  )
}

export default SubscriptionsBlock
