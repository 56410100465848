import React from 'react'
import s from 'styled-components'

import { CardDragHandler } from 'components/cards'

const CardContainer = s.div`
  display: block;
  padding: ${({ padding }) => padding};
  background-color: ${({ bg, theme }) => (bg && theme.colors[bg] ? theme.colors[bg] : bg || theme.colors.white)};
  border: ${({ theme, isBordered }) => (isBordered ? `1px solid ${theme.colors.grey300}` : '')};
  border-radius: ${({ theme, br }) => (br && theme.radius[br] ? theme.radius[br] : br || theme.radius.normal)};
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'initial')};
  ${({ borderColor, theme }) => (borderColor ? `border: 1px solid ${theme.colors[borderColor] || theme.colors.grey300};` : '')}
  ${({ relative }) => (relative ? `position: relative` : '')}

  ${({ isAnimated }) => (isAnimated
    ? `
    transition: transform 0.3s ease-in-out;
    transform: translateY(0px);
    curdor: pointer;

      &:hover {
        transform: translateY(-6px);
      }
    `
    : '')}

  ${({ absolute }) => (absolute
    ? `
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    margin-right: 0 !important;
    `
    : '')}

  ${({ alignCenter }) => (alignCenter
    ? `
      display: flex;
      align-items: center;
    `
    : '')}
`

const Card = ({
  children,
  isHovered,
  dragHandleProps,
  isShowDrag,
  dragOffset,
  ...props
}) => (
  <CardContainer isHovered={isHovered} {...props}>
    {dragHandleProps && (
      <CardDragHandler
        isShowDrag={isShowDrag}
        isHovered={isHovered}
        rightOffset={dragOffset}
        {...dragHandleProps}
      />
    )}
    {children}
  </CardContainer>
)

export default Card
