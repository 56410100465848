export const BannersActionTypes = {
  SET_FETCHING_BANNERS: 'SET_FETCHING_BANNERS',
  FETCH_BANNERS_ERROR: 'FETCH_BANNERS_ERROR',

  CLEAR_BANNERS_HOME: 'CLEAR_BANNERS_HOME',
  SET_BANNERS_HOME_IS_EMPTY: 'SET_BANNERS_HOME_IS_EMPTY',
  FETCH_BANNERS_HOME_SUCCESS: 'FETCH_BANNERS_HOME_SUCCESS',

  CLEAR_BANNERS_ALL: 'CLEAR_BANNERS_ALL',
  SET_BANNERS_ALL_IS_EMPTY: 'SET_BANNERS_ALL_IS_EMPTY',
  FETCH_BANNERS_ALL_SUCCESS: 'FETCH_BANNERS_ALL_SUCCESS',

  SET_FETCHING_GET_BANNER_BY_ID: 'SET_FETCHING_GET_BANNER_BY_ID',
  FETCH_GET_BANNER_BY_ID_SUCCESS: 'FETCH_GET_BANNER_BY_ID_SUCCESS',

  SET_FETCHING_BANNER_SUBMITTING: 'SET_FETCHING_BANNER_SUBMITTING',
  SET_FETCHING_BANNER_TOGGLE_ACTIVE: 'SET_FETCHING_BANNER_TOGGLE_ACTIVE',

  FETCH_BANNER_CREATE_SUCCESS: 'FETCH_BANNER_CREATE_SUCCESS',
  FETCH_BANNER_UPDATE_SUCCESS: 'FETCH_BANNER_UPDATE_SUCCESS',
  FETCH_BANNER_DELETE_SUCCESS: 'FETCH_BANNER_DELETE_SUCCESS',
  FETCH_BANNER_TOGGLE_ACTIVE_SUCCESS: 'FETCH_BANNER_TOGGLE_ACTIVE_SUCCESS',
}
