import React, { useState, useEffect } from "react";
import s from "styled-components";

import placeholder from "assets/icons/round-placeholder.svg";

import { checkCorrectUrl } from "helpers/System";

const defaultStyles = ({ src, theme, variant = "normal", isDisabled = false, background, width, height, $objectFit, $br }) => {
  const sizes = theme.components.cardImage[variant] || theme.components.cardImage.normal;

  return `
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: ${height || (sizes.height ? `${sizes.height}px` : "")};
    width: ${width || (sizes.width ? `${sizes.width}px` : "")};
    border-radius: ${$br || (sizes.br ? `${sizes.br}px` : 0)};
    opacity: ${isDisabled ? 0.5 : 1};
    overflow: ${!background ? "hidden" : "unset"};
    transition: opacity 0.3s ease-in-out;
    background-color: ${!src ? theme.colors.grey200 : theme.colors.transparent};
    object-fit: ${$objectFit};
    `;
};

const bgStyles = ({ src }) => `
  background-image: url(${checkCorrectUrl(src)});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const Image = s.img`
  ${defaultStyles}
`;
const ImageBackground = s.div`
  ${defaultStyles}
  ${bgStyles}
`;

export const LazyImage = ({
  src,
  alt,
  isActive = undefined,
  handleLoad = () => {},
  isNoPlaceholder = false,
  srcSet = null,
  isBackground = false,
  objectFit = "",
  br = "",
  ...props
}) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [imageSrcSet, setImageSrcSet] = useState(null);
  const [imageRef, setImageRef] = useState();

  const onLoad = (event) => {
    event.target.classList.add("loaded");
    handleLoad();
  };

  const onError = (event) => {
    event.target.classList.add("has-error");
  };

  useEffect(() => {
    let observer;
    let didCancel = false;

    if (imageRef && imageSrc !== src) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (!didCancel && (entry.intersectionRatio > 0 || entry.isIntersecting)) {
                if (isActive || isActive === undefined) {
                  if (src) {
                    setImageSrc(checkCorrectUrl(src));
                  }
                  if (srcSet) {
                    setImageSrcSet(checkCorrectUrl(srcSet));
                  }

                  observer.unobserve(imageRef);
                }
              }
            });
          },
          {
            threshold: 0.01,
            rootMargin: "75%",
          }
        );
        observer.observe(imageRef);
      } else {
        // Old browsers fallback
        setImageSrc(src || placeholder);

        if (srcSet) {
          setImageSrcSet(srcSet || placeholder);
        }
      }
    }
    return () => {
      didCancel = true;
      if (observer && observer.unobserve) {
        observer.unobserve(imageRef);
      }
    };
  }, [src, imageSrc, imageRef, isActive, srcSet]);

  return isBackground && imageSrc ? (
    <ImageBackground isNoPlaceholder={isNoPlaceholder} src={imageSrc} $objectFit={objectFit} {...props} />
  ) : (
    <Image
      $objectFit={objectFit}
      isNoPlaceholder={imageSrc || isNoPlaceholder}
      ref={setImageRef}
      src={imageSrc || placeholder}
      srcSet={imageSrcSet}
      alt={alt}
      onLoad={onLoad}
      onError={onError}
      $br={br}
      {...props}
    />
  );
};

export default LazyImage;
