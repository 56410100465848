import React from 'react'
import { useDispatch } from 'react-redux'

// components
import { Box } from 'layout'
import { ButtonArrow } from 'components/buttons'
import {
  Modal,
  ModalBanner,
  ModalContent,
  ModalActions,
  ModalContentWrapper,
} from 'components/modal'
import Login from 'components/Login'

// images
import { ReactComponent as Icon } from 'assets/icons/multy/profile-green.svg'
import bgImage from 'assets/images/modals/login-bg.jpg'

// redux
import { setModal } from 'redux/actions/SystemActions'

const ModalLogin = ({
  closeModal,
  isLaptopOrMobile,
  isLaptopBigOrMobile,
}) => {
  const dispatch = useDispatch()

  const actions = (
    <ButtonArrow
      defaultStyle="blured"
      size="big"
      titleId="button.account.signin"
      onClick={() => dispatch(setModal({ name: 'signup' }))}
    />
  )

  return (
    <Modal flex size="full">
      <ModalActions closeModal={closeModal} />
      {!isLaptopBigOrMobile && (
        <ModalBanner
          center
          img={bgImage}
          title="modals.login.title"
          description="modals.login.description"
          actions={actions}
          author={null}
        />
      )}
      <ModalContentWrapper>
        <ModalContent p="withSidebar">
          <Box
            height="100%"
            jc="center"
            ai="center"
            flex
            direction="column"
          >
            <Box className={isLaptopOrMobile ? 'mb-3' : 'mb-30'}>
              <Icon width={44} height={55} />
            </Box>
            <Box self="stretch">
              <Login />
            </Box>
          </Box>
        </ModalContent>
      </ModalContentWrapper>
    </Modal>
  )
}

export default ModalLogin
