import React, { useContext } from 'react'
import s, { ThemeContext } from 'styled-components'
import cn from 'classnames'

const Styles = ({
  display,
  direction,
  m,
  p,
  ai,
  self,
  jc,
  height,
  flex,
  theme,
  br,
  width,
  pointer,
  textAlign,
  jcs,
  $maxWidth,
}) => `
  height: ${height || 'auto'};
  display: ${display || 'flex'};
  max-width: ${$maxWidth};
  width: ${width || 'auto'};
  flex-direction: ${direction || 'row'};
  align-items: ${ai || 'flex-start'};
  align-self: ${self || ''};
  justify-content: ${jc || 'flex-start'};
  justify-self: ${jcs || 'unset'};
  margin: ${m || 'unset'};
  padding: ${p || 'unset'};
  flex: ${flex || 'unset'} !important;
  border-radius: ${theme.radius[br] || '0'}px;
  ${pointer ? 'cursor: pointer;' : ''}
  ${textAlign ? `text-align: ${textAlign};` : ''}
`

const BoxContainerDiv = s.div`
  ${(props) => Styles(props)}
`

const BoxContainerSpan = s.span`
  ${(props) => Styles(props)}
`

const BoxContainerLabel = s.label`
  ${(props) => Styles(props)}
`

const Box = ({
  children = null,
  as = null,
  className = '',
  br = 'unset',
  maxWidth = '',
  ...otherProps
}) => {
  const theme = useContext(ThemeContext)
  const classes = cn('w-box', className)
  const props = {
    theme,
    br,
    className: classes,
    $maxWidth: maxWidth,
    ...otherProps,
  }

  if (as === 'span') {
    return <BoxContainerSpan {...props}>{children}</BoxContainerSpan>
  } if (as === 'label') {
    return <BoxContainerLabel>{children}</BoxContainerLabel>
  }
  return <BoxContainerDiv {...props}>{children}</BoxContainerDiv>
}

export default Box
