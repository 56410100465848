import React, { useContext } from 'react'
import cn from 'classnames'
import s, { ThemeContext } from 'styled-components'

import { ReactComponent as IconQuestion } from 'assets/icons/question-circle.svg'
import DropdownMenu from 'components/dropdown/DropdownMenu'
import TextFormatted from 'components/typo/TextFormatted'

const TooltipContainer = s.span`
position: relative;
cursor: pointer;

svg {
  transition: fill 0.3s ease-in-out;
  fill: ${({ theme, color }) => theme.colors[color]};
}

&:hover svg {
  fill: ${({ theme }) => theme.colors.black};
}

&:hover .w-tooltip-content {
  visibility: visible;
  pointer-events: initial;
}

.w-tooltip-content {
  position: absolute;
  z-index: 100000;
  min-width: 258px;
  max-width: 258px;
  padding: 16px;
  border-radius: 10px;
  visibility: hidden;
  pointer-events: none;
}
`

const TooltipBase = ({
  className,
  position = 'top-right',
  color = 'grey500',
  text = 'Подсказка',
}) => {
  const theme = useContext(ThemeContext)

  return (
    <TooltipContainer
      color={color}
      theme={theme}
      className={cn('w-tooltip', className)}
    >
      <DropdownMenu
        position={position}
        className={cn('w-tooltip-content', { [`--${position}`]: position })}
        variant={position}
      >
        <TextFormatted size="small" titleId={text} />
      </DropdownMenu>
      <div className="w-tooltip-icon">
        <IconQuestion width={22} height={22} />
      </div>
    </TooltipContainer>
  )
}

export default TooltipBase
