import React from 'react'

import Container from 'layout/Container'
import Section from 'layout/Section'

import imgCase1 from 'assets/images/home/cases/case-1.jpg'
import imgCase1x2 from 'assets/images/home/cases/case-1@2x.jpg'

import imgCase2 from 'assets/images/home/cases/case-2.jpg'
import imgCase2x2 from 'assets/images/home/cases/case-2@2x.jpg'

import imgCase3 from 'assets/images/home/cases/case-3.jpg'
import imgCase3x2 from 'assets/images/home/cases/case-3@2x.jpg'

import ScrollList from './ScrollList'
import SectionTitle from './SectionTitle'
import CardCase from './CardCase'

const LIST = [
  {
    img: {
      src: imgCase1,
      srcSet: `${imgCase1x2} 2x`,
    },
    title: 'Flash pizza',
    links: {
      google: 'https://play.google.com/store/apps/details?id=com.shaurmission.andriod.app',
      app: 'https://apps.apple.com/ru/app/шаурмиссия/id1590021904',
    },
  },
  {
    img: {
      src: imgCase2,
      srcSet: `${imgCase2x2} 2x`,
    },
    title: 'Burgers and Brews',
    links: {
      google: 'https://play.google.com/store/apps/details?id=com.fusion.android.app',
      app: 'https://apps.apple.com/ru/app/фьюжн/id1595535414',
    },
  },
  {
    img: {
      src: imgCase3,
      srcSet: `${imgCase3x2} 2x`,
    },
    title: 'Wok This Way',
    links: {
      google: 'https://play.google.com/store/apps/details?id=com.cheretto.leningradsky.android.app',
      app: 'https://apps.apple.com/ru/app/черетто-ленинградский/id1591493833 ',
    },
  },
]

const SectionCases = ({ isLaptopOrMobile }) => (
  <Section
    linkId="cases"
    bg="light"
    br="all"
    className="pb-0"
  >
    <Container>
      <SectionTitle titleId="home.heading.already" />
      <ScrollList items={LIST} renderCard={(props) => <CardCase isShadow isMobile={isLaptopOrMobile} {...props} />} />
    </Container>
  </Section>
)

export default SectionCases
