export const getPrevLocation = () => {
  const pn = window.location.pathname
  return pn.slice(0, pn.lastIndexOf('/'))
}

export const getPrevLocationDinamic = (path, repeat = 1) => {
  const newPath = path.split('/')

  for (let length = 0; length < repeat; length++) {
    newPath.pop()
  }

  return newPath.join('/')
}

export function clearHistoryOnRefreshPage (history) {
  if (history.location.state && (history.location.state.noFetch || history.location.state.from || history.location.state.prevScrollPosition)) {
    const state = { ...history.location.state }
    delete state.noFetch
    delete state.from
    delete state.prevScrollPosition

    history.replace({
      ...history.location,
      state,
    })
  }
}
