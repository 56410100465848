import React from 'react'
import s from 'styled-components'
import cn from 'classnames'

import { ReactComponent as IconThreeDots } from 'assets/icons/icon-three-dots.svg'

const CardMenuIconContainer = s.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
`

const CardMenuIcon = ({
  active, className, ...otherProps
}) => (
  <CardMenuIconContainer
    className={cn('ps-card-settings', { '--active': active }, className)}
    {...otherProps}
  >
    <IconThreeDots width={23} hright={5} />
  </CardMenuIconContainer>
)

export default CardMenuIcon
