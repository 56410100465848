import React from 'react'
import s from 'styled-components'
import cn from 'classnames'

import { Box, Container } from 'layout'
import { Title } from 'components/typo'
import ThemesPreview from './ThemesPreview'

const HeroContainer = s.div`
  &.--mob {
    padding-top: 32px;
  }

  .hero-content {
    width: 100%;
    max-width: 790px;
    align-self: center;
  }

  &.--r-content .hero-content {
    max-width: 700px;
    min-width: 360px;
  }

  &.--mob.--r-content .hero-content {
    min-width: unset;
    margin-bottom: 32px;
  }

  &.--laptop-big .hero-content {
    max-width: 340px;
  }

  &.--mob .hero-content {
    max-width: 320px;
  }

  .hero-preview {
    padding: 0 50px;
    padding-top: 63px;
    flex: 1;
    display: flex;

    & > * {
      margin-bottom: -125px;
    }

    &.--offset-b > * {
      margin-bottom: -200px;
    }
  }

  &.--mob .hero-preview {
    max-width: 320px;
    padding-top: 32px;
    justify-content: center;

    & > * {
      margin-bottom: -100px;
    }
  }

  .hero-image {
    // position: relative;
    display: flex;
    flex: 1;
    justify-content: flex-end;

    &.--absolute {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`

const Hero = ({
  title = null,
  actions = null,
  items = null,
  rightContent = null,
  isLaptopOrMobile,
  isLaptopBigOrMobile = false,
  imageOffsetBig = false,
}) => (
  <HeroContainer className={cn('w-hero', {
    '--mob': isLaptopOrMobile,
    '--laptop-big': !isLaptopOrMobile && isLaptopBigOrMobile,
    '--r-content': rightContent,
  })}
  >
    <Container>
      <Box
        direction={isLaptopOrMobile ? 'column' : 'row'}
        ai={isLaptopOrMobile ? 'center' : ''}
        className="hero-inner"
      >
        <div className="hero-content">
          <Title
            className="mb-5"
            tag="h1"
            center={isLaptopOrMobile}
            fs
          >
            {title}
          </Title>
          <Box
            jc={isLaptopOrMobile ? 'center' : ''}
            direction={isLaptopBigOrMobile && !isLaptopOrMobile ? 'column' : 'row'}
          >
            {actions}
          </Box>
        </div>
        {items && (
          <div className={cn('hero-preview', { '--offset-b': imageOffsetBig })}>
            <ThemesPreview items={items} />
          </div>
        )}
        {rightContent && (
          <div className="hero-image">
            {rightContent}
          </div>
        )}
      </Box>
    </Container>
  </HeroContainer>
)

export default Hero
