import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Field } from 'formik'
import { injectIntl } from 'react-intl'

// components
import { Button } from 'components/base'
import { Box } from 'layout'
import {
  Form,
  FormField,
  FormGroup,
} from 'components/form'
import { Title } from 'components/typo'
import useResponsive from 'components/hooks/useResponsive'

// constants
import { SchemaFormQuestion } from 'constants/ValidationSchemas'
import { fetchFormRequesе } from 'redux/actions/SystemActions'
import Formatted from 'components/typo/Formatted'

const FormQuestion = ({
  type,
  title,
  descriptionPlaceholder = 'form.textarea.message',
  isEmpty = false,
  intl,
}) => {
  const dispatch = useDispatch()
  const placeholderMessage = intl.formatMessage({ id: descriptionPlaceholder })
  const { isLaptopOrMobile } = useResponsive()
  const { profile = {} } = useSelector((state) => state.user)

  const handleRequest = (values, { setSubmitting }) => {
    const data = {
      email: values.email,
      message: values.message,
      order_type: type,
    }

    dispatch(fetchFormRequesе(data)).finally(() => setSubmitting(false))
  }

  return (
    <Formik
      initialValues={{
        email: isEmpty ? '' : (profile && profile.email) || '',
        message: '',
      }}
      onSubmit={handleRequest}
      validationSchema={SchemaFormQuestion}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          isSubmitting,
        } = props

        const attrs = {
          onChange: handleChange,
          onBlur: handleBlur,
          errors,
          touched,
          size: 'big',
        }

        const formIsValid = dirty && isValid

        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Box jc="center" className={isLaptopOrMobile ? 'mb-20' : 'mb-5'}>
              <Title tag="h2">
                <Formatted id={title} />
              </Title>
            </Box>
            <FormGroup>
              <Field>
                {() => (
                  <FormField
                    {...attrs}
                    name="email"
                    placeholder="Email"
                    type="email"
                    value={values.email}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup>
              <Field>
                {() => (
                  <FormField
                    {...attrs}
                    tag="textarea"
                    name="message"
                    value={values.message}
                    placeholder={placeholderMessage}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup>
              <Button
                defaultStyle="acsent"
                type="submit"
                size="big"
                full
                titleId="form.button.sendRequest"
                isFetching={isSubmitting}
                isDisabled={isSubmitting || !formIsValid}
              />
            </FormGroup>
          </Form>
        )
      }}
    </Formik>
  )
}

export default injectIntl(FormQuestion)
