export const FiltersActionTypes = {
  SET_FILTER: 'SET_FILTER',
  SET_FILTERS: 'SET_FILTERS',
  SET_OFFSET: 'SET_OFFSET',
  SET_LIMIT: 'SET_LIMIT',
  RESET_ALL_FILTERS: 'RESET_ALL_FILTERS',
}

export const FiltersNamesTypes = {
  CATEGORIES: 'categories',
  SEARCH: 'search',
  LIMIT: 'limit',
  OFFSET: 'offset',
}

export const CATEGORIES_INIT = null
export const SEARCH_INIT = ''
export const OFFSET_INIT = 0
export const LIMIT_INIT = 40
