import React, {
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import s from 'styled-components'
import Websocket from 'react-websocket'

import ErrorPage from 'pages/ErrorPage'
import useResponsive from 'components/hooks/useResponsive'

import { loadInitState } from 'redux/actions/SystemActions'
import { setNewOrdersCount, fetchOrdersHome } from 'redux/actions/OrdersActions'

import ContentProvider from 'providers/ContentProvider'
import StorageService from 'services/StorageService'

import { PublishTypes } from 'constants/PublishTypes'
import { OrdersStatuses } from 'constants/OrdersTypes'
import * as PathsTypes from 'constants/PathsTypes'

import InterfaceNavigation from './InterfaceNavigation'
import InterfacePreloader from './InterfacePreloader'
import InterfaceContent from './InterfaceContent'
import InterfaceRoutes from './InterfaceRoutes'
import InterfacePreloaderAutosave from './InterfacePreloaderAutosave'

const InterfaceContainer = s.div`
  display: flex;
  height: 100%;
`

const Interface = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { isLaptopBigOrMobile } = useResponsive()
  const { init, preloader } = useSelector((state) => state.system)
  const { isSubmitting: isAutosaveSettings } = useSelector((state) => state.settings)
  const { status } = useSelector((state) => state.user.profile.company.company_app)

  useEffect(() => {
    document.body.classList.add('interface')

    if (!init) {
      dispatch(loadInitState())
    }

    return () => {
      document.body.classList.remove('interface')
    }
    // eslint-disable-next-line
  }, [])

  const handleOrders = useCallback((data) => {
    const parsedData = JSON.parse(data) || {}

    if (parsedData && parsedData.message) {
      const { data, orders_count: countAll } = parsedData.message

      if (countAll) {
        dispatch(setNewOrdersCount(parsedData.message.orders_count || 0))
      }
      else if (data && data.status) {
        const { orders_count: countAllNewOrder, status } = data
        const isNew = status === OrdersStatuses.WAITING_COMPANY
        dispatch(setNewOrdersCount(countAllNewOrder || 0, isNew))

        if (isNew && history.location.pathname.indexOf(PathsTypes.INTERFACE_ORDERS) >= 0) {
          dispatch(fetchOrdersHome(history.location.search))
        }
      }
    }
  }, [ dispatch, history ])

  const isPublished = useMemo(() => status === PublishTypes.PUBLISHED, [ status ])

  return isLaptopBigOrMobile ? <ErrorPage noBtn title="Используйте&nbsp;компьютер или&nbsp;планшет&nbsp;💻" /> : (
    <InterfaceContainer>
      <InterfaceNavigation history={history} />
      <ContentProvider>
        <InterfaceContent history={history}>
          <InterfaceRoutes history={history} />
        </InterfaceContent>
      </ContentProvider>
      {preloader ? <InterfacePreloader /> : ''}
      {isAutosaveSettings ? <InterfacePreloaderAutosave /> : ''}
      {isPublished && (
        <Websocket
          url={`wss://wibyback.host/ws/orders/?token=${StorageService.getToken()}`}
          onMessage={handleOrders}
        />
      )}
    </InterfaceContainer>
  )
}

export default Interface
