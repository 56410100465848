import React from 'react'
import { Field } from 'formik'

// components
import { Link, Text } from 'components/typo'
import { FormCheckbox } from 'components/form'

// constants
import * as PathsTypes from 'constants/PathsTypes'
import Formatted from 'components/typo/Formatted'

const FormTrems = ({ isLaptopOrMobile, ...props }) => (
  <Field>
    {() => (
      <FormCheckbox
        {...props}
        html={(
          <Text
            size={isLaptopOrMobile ? 'small' : 'normal'}
            tag="p"
            color="grey500"
          >
            <Formatted id="form.term.accept" />
            {' '}
            <Link
              browser
              size="inherit"
              href={PathsTypes.POLITIC}
              target="_blank"
            >
              <Formatted id="form.term.link" />
            </Link>
          </Text>
        )}
      />
    )}
  </Field>
)

export default FormTrems
