import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import s from 'styled-components'
import cn from 'classnames'
import { useSwipeable } from 'react-swipeable'

import useResponsive from 'components/hooks/useResponsive'

import { WAYPOINT_PREVIEW_TOGGLE_SELECTOR, WAYPOINT_PREVIEW_TOGGLE_CLASS } from 'constants/AnimationsTypes'
import { scrollObserver } from 'utils'
import { TimelineMax } from 'services/GsapService'

import ThemesPreviewImages from './ThemesPreviewImages'

const ThemesPreviewContainer = s.div`
  .w-themes-images {
    img {
      margin: 0 auto;
    }
  }

  &.--mob {
    .w-themes-images {
      img {
        width: 100%;
        max-width: 265px
      }
    }
  }
`

const SLIDES_TIMEOUT = 3000
const INIT_ACTIVE_TAB = 1

const ThemesPreview = ({ isInitImagesLoaded = true, items = [] }) => {
  const { isLaptopOrMobile, isLaptopBigOrMobile } = useResponsive()
  const [ activeTheme, setActiveTheme ] = useState(null)
  const tl = useRef(new TimelineMax())

  const handleToggleTab = useCallback((id) => {
    if (tl.current) {
      tl.current.play(0)
    }

    if (activeTheme !== id) {
      setActiveTheme(null)
      setActiveTheme(id)
    }
  }, [
    setActiveTheme,
    activeTheme,
  ])

  useEffect(() => {
    const timeout = setInterval(() => {
      const newActive = activeTheme < items.length ? activeTheme + 1 : items[0].id
      handleToggleTab(newActive)
    }, SLIDES_TIMEOUT)

    return () => {
      clearInterval(timeout)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ activeTheme, items ])

  const slideNext = useCallback(() => {
    const newActive = activeTheme < items.length ? activeTheme + 1 : items[0].id
    handleToggleTab(newActive)
  }, [ activeTheme, handleToggleTab, items ])

  const slidePrev = useCallback(() => {
    const newActive = activeTheme < items[0].id + 1 ? items.length : activeTheme - 1
    handleToggleTab(newActive)
  }, [ activeTheme, handleToggleTab, items ])

  const handlers = useSwipeable({
    onSwipedLeft: () => slideNext(),
    onSwipedRight: () => slidePrev(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  const swipeProps = !items || items.length === 1 ? {} : handlers

  const handleToggleAnimation = useCallback(() => {
    handleToggleTab(INIT_ACTIVE_TAB)
  }, [ handleToggleTab ])

  useEffect(() => {
    if (!isLaptopOrMobile && activeTheme) {
      handleToggleTab(activeTheme)
    }
  }, [ isLaptopOrMobile, activeTheme, handleToggleTab ])

  useEffect(() => {
    if (isInitImagesLoaded) {
      scrollObserver(
        WAYPOINT_PREVIEW_TOGGLE_SELECTOR,
        handleToggleAnimation,
        { rootMargin: '-15% 0px' }
      )
    }
    // eslint-disable-next-line
  }, [isInitImagesLoaded])

  return (
    <ThemesPreviewContainer className={cn(`w-themes-preview ${WAYPOINT_PREVIEW_TOGGLE_CLASS}`, { '--mob': isLaptopOrMobile })}>
      <div {...swipeProps} className="w-themes-images">
        <ThemesPreviewImages
          items={items}
          activeTheme={isInitImagesLoaded && activeTheme}
          isLaptopBigOrMobile={isLaptopBigOrMobile}
        />
      </div>
    </ThemesPreviewContainer>
  )
}

export default ThemesPreview
