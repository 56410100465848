import React from "react";
import s from "styled-components";
import cn from "classnames";

const GridListContainer = s.div`
  .grid-inner {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    margin: -15px;

    @media (max-width: 768px) {
      flex-direction: column;
      flex-wrap: unset;
      margin: 0
    }
  }

  .grid-item {
    width: 50%;
    padding: 10px;

    @media (max-width: 768px) {
      width: 100%;
      padding: 0;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const GridList = ({ isLaptopOrMobile, items = [], renderCard = () => {} }) => {
  return (
    <GridListContainer
      className={cn("grid-list", {
        "--mob": isLaptopOrMobile,
      })}
    >
      <div className="grid-inner">
        {items.map((item, idx) => (
          <div className="grid-item" key={idx}>
            {renderCard(item)}
          </div>
        ))}
      </div>
    </GridListContainer>
  );
};

export default GridList;
