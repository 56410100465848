import React from 'react'
import s from 'styled-components'

const CardHeadContainer = s.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ p }) => (p || '20px 20px 15px 20px;')};
  border-bottom: ${({ noborder }) => (noborder ? 'none' : '1px solid #E9EFF4')};
`

const CardHead = ({ children, ...otherProps }) => (
  <>
    <CardHeadContainer className="ps-card-head" {...otherProps}>
      {children}
    </CardHeadContainer>
  </>
)

export default CardHead
