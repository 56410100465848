import React, { useContext, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import s, { ThemeContext } from 'styled-components'
// import FormikErrorFocus from 'formik-error-focus'

import { Grid } from 'layout'
import { Button, NavigationGuard } from 'components/base'
import { ButtonDelete } from 'components/buttons'

import { setModal } from 'redux/actions/SystemActions'

const FormFooterContainer = s.div`
  position: ${({ isSticky }) => (isSticky ? 'sticky' : 'relative')};
  bottom: 0;
  left: 0;
  z-index: ${({ isSticky }) => (isSticky ? 99 : '')};
  width: 100%;
  max-width: ${({ theme }) => theme.components.ipage.maxWidth}px;
  margin-top: 52px;
  padding: 19px 0;
  background-color: ${({ theme }) => theme.colors.whitet90};

  &::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: 9999px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    transform: translateX(-50%);
    content: "";
  }
`

const FormFooter = ({
  isSubmitting, isValid, onReset, onNavigationYes, onDelete, modalTitle = null, isSubmitted = false, isDirty = false,
}) => {
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)

  const handleDelete = useCallback(() => {
    dispatch(
      setModal({
        name: 'confirm',
        type: 'delete',
        center: true,
        onYes: (closeModal) => {
          onDelete()
          closeModal()
        },
        title: modalTitle,
      })
    )
  }, [ dispatch, onDelete, modalTitle ])

  return (
    <>
      <FormFooterContainer className="form-footer" theme={theme} isSticky={isValid}>
        <Grid>
          <Button
            defaultStyle={isValid ? 'acsent' : 'grey'}
            type="submit"
            titleId="button.save"
            isFetching={isSubmitting}
            isDisabled={!isValid}
            opacityFull
          />
          {isDirty && (
            <Button
              defaultStyle="whitegrey"
              titleId="button.reset"
              type="reset"
              onClick={onReset}
            />
          )}
          {onDelete && <ButtonDelete className="ml-a" onClick={handleDelete} />}
        </Grid>
      </FormFooterContainer>
      {/* <FormikErrorFocus
        offset={0}
        align="center"
        focusDelay={100}
        ease="linear"
        duration={1}
      /> */}
      {isValid && isDirty && !isSubmitted && <NavigationGuard onYes={onNavigationYes} />}
    </>
  )
}

export default FormFooter
