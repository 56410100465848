import React from 'react'
import { useField, useFormikContext } from 'formik'

import { FormDropdown } from 'components/form'
import Datepicker from 'components/Datepicker'

import { formatDate } from 'helpers/Date'

const FormFieldDatepicker = ({
  placeholder,
  defaultStyle = 'field',
  formatter = 'dd MMMM yyyy',
  size = 'big',
  position,
  isTop,
  ...props
}) => {
  const { setFieldValue } = useFormikContext()
  const [ field ] = useField(props)

  return (
    <FormDropdown
      size={size}
      defaultStyle={defaultStyle}
      placeholder={placeholder}
      value={field.value ? formatDate(field.value, formatter) : ''}
      isError={field.error}
      isTouched={field.touched}
      position={position}
      isTop={isTop}
      renderDropdown={({ closeDropdown }) => (
        <Datepicker
          {...field}
          {...props}
          selected={(field.value && new Date(field.value)) || null}
          onChange={(val) => {
            setFieldValue(field.name, val)
            closeDropdown()
          }}
        />
      )}
    />
  )
}

export default FormFieldDatepicker
