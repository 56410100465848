import React from 'react'
import s from 'styled-components'

import { CardImage } from 'components/Card'

const ImageBlockContainer = s.div`
  position: relative;
  width: 100%;
  max-width: 500px;

  img {
    display: block;
    width: 100%;
  }

  @media (max-width: 768px) {
    max-width: 342px;
  }
`
const ImageBlock = ({
  src,
  srcX2,
  children,
  isActive,
  ...otherProps
}) => (
  <ImageBlockContainer {...otherProps}>
    {src && (
      <CardImage
        src={src}
        srcSet={`${srcX2} 2x`}
        height="auto"
        width="100%"
        alt="img"
        isNoPlaceholder
        isActive={isActive}
      />
    )}
    {children}
  </ImageBlockContainer>
)
export default ImageBlock
