export const UserActionTypes = {
  USER_LOGIN_START: 'USER_LOGIN_START',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_ERROR: 'USER_LOGIN_ERROR',

  USER_RESET_PASSWORD_START: 'USER_RESET_PASSWORD_START',
  USER_RESET_PASSWORD_SUCCESS: 'USER_RESET_PASSWORD_SUCCESS',
  USER_RESET_PASSWORD_ERROR: 'USER_RESET_PASSWORD_ERROR',

  USER_RESET_PASSWORD_SUBMIT_START: 'USER_RESET_PASSWORD_SUBMIT_START',
  USER_RESET_PASSWORD_SUBMIT_SUCCESS: 'USER_RESET_PASSWORD_SUBMIT_SUCCESS',
  USER_RESET_PASSWORD_SUBMIT_ERROR: 'USER_RESET_PASSWORD_SUBMIT_ERROR',

  USER_SET_NEW_PASSWORD_START: 'USER_SET_NEW_PASSWORD_START',
  USER_SET_NEW_PASSWORD_SUCCESS: 'USER_SET_NEW_PASSWORD_SUCCESS',
  USER_SET_NEW_PASSWORD_ERROR: 'USER_RESET_PASSWORD_SUBMIT_ERROR',

  USER_LOGOUT_START: 'USER_LOGOUT_START',
  USER_LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
  USER_LOGOUT_ERROR: 'USER_LOGOUT_ERROR',

  USER_REGISTER_START: 'USER_REGISTER_START',
  USER_REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
  USER_REGISTER_ERROR: 'USER_REGISTER_ERROR',

  FETCH_USER_START: 'FETCH_USER_START',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_ERROR: 'FETCH_USER_ERROR',

  FETCH_INTERFACE_PROFILE_UPDATE_START: 'FETCH_INTERFACE_PROFILE_UPDATE_START',
  FETCH_INTERFACE_PROFILE_UPDATE_SUCCESS:
    'FETCH_INTERFACE_PROFILE_UPDATE_SUCCESS',
  FETCH_INTERFACE_PROFILE_UPDATE_ERROR: 'FETCH_INTERFACE_PROFILE_UPDATE_ERROR',

  FETCH_CHANGE_PASSWORD_START: 'FETCH_CHANGE_PASSWORD_START',
  FETCH_CHANGE_PASSWORD_SUCCESS: 'FETCH_CHANGE_PASSWORD_SUCCESS',
  FETCH_CHANGE_PASSWORD_ERROR: 'FETCH_CHANGE_PASSWORD_ERROR',

  FETCH_CHANGE_AVATAR_START: 'FETCH_CHANGE_AVATAR_START',
  FETCH_CHANGE_AVATAR_SUCCESS: 'FETCH_CHANGE_AVATAR_SUCCESS',
  FETCH_CHANGE_AVATAR_ERROR: 'FETCH_CHANGE_AVATAR_ERROR',

  FETCH_USER_SUBSCRIPTIONS_START: 'FETCH_USER_SUBSCRIPTIONS_START',
  FETCH_USER_SUBSCRIPTIONS_SUCCESS: 'FETCH_USER_SUBSCRIPTIONS_SUCCESS',
  FETCH_USER_SUBSCRIPTIONS_ERROR: 'FETCH_USER_SUBSCRIPTIONS_ERROR',

  FETCH_USER_BUY_SUBSCRIPTIONS_START: 'FETCH_USER_BUY_SUBSCRIPTIONS_START',
  FETCH_USER_BUY_SUBSCRIPTIONS_SUCCESS: 'FETCH_USER_BUY_SUBSCRIPTIONS_SUCCESS',
  FETCH_USER_BUY_SUBSCRIPTIONS_ERROR: 'FETCH_USER_BUY_SUBSCRIPTIONS_ERROR',
  SET_FETCHING_USER_BUY_SUBSCRIPTIONS: 'SET_FETCHING_USER_BUY_SUBSCRIPTIONS',
  ADD_USER_SUBSCRIPTION: 'ADD_USER_SUBSCRIPTION',

  CHECK_USER_SESSION: 'CHECK_USER_SESSION',
  SET_ERROR: 'SET_ERROR',
  SET_AUTH_FETCHING: 'SET_AUTH_FETCHING',
  SET_FETCHING_SEND_EMAIL: 'SET_FETCHING_SEND_EMAIL',

  SET_SUBMITING: 'SET_SUBMITING',
  SET_FETCHING_ACCOUNT_UPDATE: 'SET_FETCHING_ACCOUNT_UPDATE',
  FETCH_ACCOUNT_UPDATE_SUCCESS: 'FETCH_ACCOUNT_UPDATE_SUCCESS',

  SET_COMPANY_SUBSCRIPTION: 'SET_COMPANY_SUBSCRIPTION',
}
