import React from 'react'
import s from 'styled-components'

const FormGroupContainer = s.div`
  & + & {
    margin-top: 16px;

    @media (max-width: 768px) {
      margin-top: 14px;
    }
  }

  margin-bottom: ${({ big }) => (big ? '40px' : '')};
`

const FormGroup = ({ children, ...otherProps }) => (
  <FormGroupContainer {...otherProps}>{children}</FormGroupContainer>
)

export default FormGroup
