import React from 'react'
import s from 'styled-components'
import cn from 'classnames'

const DividerContainer = s.div`
  width: 100%;
  height: 1px;
  background: ${({ theme, color }) => theme.colors[color]};
`

const Divider = ({
  className,
  color = 'black',
  ...props
}) => (
  <DividerContainer
    color={color}
    className={cn('w-divider', className)}
    {...props}
  />
)

export default Divider
