import React, {
  useState,
  useRef,
  useEffect,
} from 'react'
import s from 'styled-components'
import { TweenLite } from 'services/GsapService'

const AppAnimateHeightContainer = s.div`

`

const AppAnimateHeight = ({ children, show }) => {
  const [ init, setinit ] = useState(false)
  const expandRef = useRef(null)

  useEffect(() => {
    if (!show) {
      expandHandler()
    }

    setinit(true)
    // eslint-disable-next-line
    }, [])

  useEffect(() => {
    if (init) expandHandler()
    // eslint-disable-next-line
  }, [show])

  const expandHandler = () => {
    const expandEl = expandRef.current

    if (expandEl) {
      if (!show) {
        TweenLite.to(expandEl, {
          duration: 0.3,
          alpha: 0,
          height: 0,
        })
        expandEl.classList.add('closed')
      }
      else {
        TweenLite.set(expandEl, {
          alpha: 1,
          height: 'auto',
        })
        TweenLite.from(expandEl, {
          duration: 0.3,
          alpha: 0,
          height: 0,
          onComplete: () => {
            expandEl.style.height = 'auto'
          },
        })
        expandEl.classList.remove('closed')
      }
    }
  }

  return (
    <AppAnimateHeightContainer
      className="expand-block"
      ref={expandRef}
    >
      {children}
    </AppAnimateHeightContainer>
  )
}

export default AppAnimateHeight
