import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { setModal } from 'redux/actions/SystemActions'

const NavigationGuard = ({ onYes = () => {}, isNoFetch = false }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    const prevLocation = history.location

    const unblock = history.block((location) => {
      if (prevLocation.pathname !== location.pathname) {
        dispatch(setModal({
          name: 'confirm',
          center: true,
          type: 'save',
          onYes: (closeModal = () => {}) => {
            unblock()
            closeModal()
            onYes()
            return false
          },
          onNo: (closeModal = () => {}) => {
            unblock()
            closeModal()
            history.push(location.pathname, {
              ...prevLocation.state,
              noFetch: true,
              noScrollTop: false,
            } || {})
            return false
          },
        }))

        return false
      }
    })

    return () => unblock()

    // eslint-disable-next-line
  }, [])

  return null
}

export default NavigationGuard
