import React, { useState } from 'react'

// components
import { Box } from 'layout'
import {
  Modal,
  ModalContent,
  ModalContentWrapper,
  ModalActions,
  ModalFooterHelp,
} from 'components/modal'
import { Title } from 'components/typo'
import PhoneConfirm from 'components/PhoneConfirm'

// images
import { ReactComponent as IconTying } from 'assets/icons/multy/code-confirm-typing.svg'
import { ReactComponent as IconError } from 'assets/icons/multy/code-confirm-error.svg'
import { ReactComponent as IconSuccess } from 'assets/icons/multy/code-confirm-success.svg'

const ModalPhoneConfirm = ({
  prevModal,
  closeModal,
  data,
  isLaptopOrMobile,
}) => {
  const [ isCodeError, setCodeError ] = useState(false)
  const [ isCodeSuccess, setCodeSuccess ] = useState(false)

  const Icon = isCodeError
    ? IconError
    : isCodeSuccess
      ? IconSuccess
      : IconTying

  return (
    <Modal flex size="full">
      <ModalActions prevModal={prevModal} closeModal={closeModal} />
      <ModalContentWrapper>
        <ModalContent
          display="flex"
          column
          center
          p="withSidebar"
        >
          <Box
            height="100%"
            jc="center"
            ai="center"
            flex
            direction="column"
            className={isLaptopOrMobile ? '' : 'mt-a'}
          >
            <Box className={isLaptopOrMobile ? 'mb-3' : 'mb-30'}>
              <Icon width={55} height={52.5} />
            </Box>
            <Box className={isLaptopOrMobile ? 'mb-20' : 'mb-5'}>
              <Title tag="h2">Код из SMS</Title>
            </Box>
            <Box className="mb-5">
              <PhoneConfirm
                isCodeError={isCodeError}
                setCodeError={setCodeError}
                isCodeSuccess={isCodeSuccess}
                setCodeSuccess={setCodeSuccess}
                data={data}
              />
            </Box>
          </Box>
          <div className="mt-a mx-a">
            <Box jc="center">
              <ModalFooterHelp text="Неприходит код?" linkText="Обратиться в поддержку" />
            </Box>
          </div>
        </ModalContent>
      </ModalContentWrapper>
    </Modal>
  )
}

export default ModalPhoneConfirm
