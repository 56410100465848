import React, {
  useEffect,
  useState,
  useContext,
} from 'react'
import s from 'styled-components'
import cn from 'classnames'

import { ContentContext } from 'providers/ContentProvider'

import useResponsive from 'components/hooks/useResponsive'

const InterfaceContentContainer = s.div`
  position: relative;
  display: flex;

  flex-direction: column;
  width: 100vw;
  height: 100vh;
  padding-left: ${({ theme }) => theme.components.sidebar.width}px;

  &.--desktop-small {
    padding-left: 92px;
  }

  .i-content-inner {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
`

const InterfaceContent = ({ children, history }) => {
  const [ prevLocation, setPrevLocation ] = useState(history.location)
  const { contentRef, scrollToTop } = useContext(ContentContext)
  const { isDesktopSmall } = useResponsive()

  // scroll to top custom scroll component on history change
  useEffect(() => {
    const unlisten = history.listen((location) => {
      const { state } = location

      if (contentRef && contentRef.current) {
        setPrevLocation(location)

        if ((prevLocation.pathname !== location.pathname
            && (!state || (state && !state.noScrollTop)))
            || (state && state.prevScrollPosition === null)) { // scroll to top only if new pathname (not search changed)
          setTimeout(() => {
            scrollToTop()
          }, 100)
        }
      }
    })

    return () => unlisten()
    // eslint-disable-next-line
  }, [])

  return (
    <InterfaceContentContainer className={cn('i-content', { '--desktop-small': isDesktopSmall })}>
      <div ref={contentRef} id="scroll-i-content" className="i-content-inner">
        {children}
      </div>
    </InterfaceContentContainer>
  )
}

export default InterfaceContent
