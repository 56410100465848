import React from 'react'
import styled from 'styled-components'

const FormBodyContainer = styled.div`
  padding: 10px 0;
`

const FormBody = ({ children = null, ...otherProps }) => (
  <FormBodyContainer {...otherProps}>{children}</FormBodyContainer>
)

export default FormBody
