import React from 'react'

// components
import { Container, Box } from 'layout'
import { Title } from 'components/typo'
import {
  Modal,
  ModalContent,
  ModalActions,
} from 'components/modal'
import Logo from 'components/Logo'
import SubscriptionsBlock from 'components/SubscriptionsBlock'
import ModalSubscriptionsBanner from 'components/modals/ModalSubscriptionsBanner'
import Positioner from 'components/base/Positioner'
import Formatted from 'components/typo/Formatted'

const ModalSubscriptions = ({
  closeModal,
  isLaptopOrMobile,
}) => (
  <Modal flex size="full">
    <Positioner
      i="10"
      top="0"
      left="0"
      right="0"
    >
      <ModalActions btnStyle="white" closeModal={closeModal} />
      <ModalSubscriptionsBanner isLaptopOrMobile={isLaptopOrMobile} />
    </Positioner>
    <ModalContent
      display="flex"
      column
      p={`${isLaptopOrMobile ? '160px' : '130px'} 46px 46px 46px`}
    >
      <Box
        jc="center"
        ai="center"
        flex
        direction="column"
      >
        <Box self="stretch">
          <div>
            <Box jc="center" className={isLaptopOrMobile ? 'mb-3' : 'mb-5'}>
              <Logo
                defaultStyle="black"
                notitle
                nopointers
              />
            </Box>
            <div className={isLaptopOrMobile ? 'mb-4' : 'mb-7'}>
              <Title center tag="h2"><Formatted id="modals.tariff.title" /></Title>
            </div>
            <Container>
              <SubscriptionsBlock />
            </Container>
          </div>
        </Box>
      </Box>
    </ModalContent>
  </Modal>
)

export default ModalSubscriptions
