import React from 'react'
import { NavLink } from 'react-router-dom'
import s from 'styled-components'

import { Bage, BageCount } from 'components/base'
import Formatted from 'components/typo/Formatted'

const NavigationLinkContainer = s(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 20px 18px 32px;
  color: ${({ theme }) => theme.colors.grey500};
  font-weight: ${({ theme }) => theme.fw.m};
  font-size: 16px;
  line-height: 24px;
  text-decoration: unset;
  cursor: pointer;
  transition: color 0.3s ease-in-out;

  &::before {
    position: absolute;
    top: 50%;
    left: 12px;
    width: 6px;
    height: 6px;
    background-color: ${({ theme }) => theme.colors.black};
    border-radius: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    content: "";
    pointer-events: none;
  }

  svg {
    width: 28px;
    height: 28px;
    margin-right: 16px;
    transition: fill 0.3s ease-in-out;
    pointer-events: none;
    fill: ${({ theme }) => theme.colors.grey500};
  }

  &.active {
    &::before {
      opacity: 1;
    }
  }

  &.active,
  &:hover {
    color: ${({ theme }) => theme.colors.black};

    svg {
      fill: ${({ theme }) => theme.colors.black};
    }
  }

  & > * {
    width: 100%;
    height: 100%;
  }

  .navigation-link-bage {
    flex: 0;
    margin-left: 20px;
  }
`

const InterfaceNavigationLink = ({
  onClick = () => {},
  icon: Icon,
  bage,
  count,
  newOrdersCount,
  isDesktopSmall,
  to,
  label,
}) => (

  <NavigationLinkContainer
    className="navigation-link"
    to={to}
    hover="black"
    onClick={onClick}
  >
    <Icon />
    {!isDesktopSmall && (
      <span className="mr-a"><Formatted id={label} /></span>
    )}
    {bage && !isDesktopSmall && (
      <div className="navigation-link-bage">
        <Bage {...bage} />
      </div>
    )}
    {count && newOrdersCount > 0 && !isDesktopSmall && (
      <div className="navigation-link-bage">
        <BageCount count={newOrdersCount} />
      </div>
    )}
  </NavigationLinkContainer>

)

export default InterfaceNavigationLink
