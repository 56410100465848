export const ProductsActionTypes = {
  SET_FETCHING_PRODUCTS: 'SET_FETCHING_PRODUCTS',
  FETCH_PRODUCTS_ERROR: 'FETCH_PRODUCTS_ERROR',

  CLEAR_PRODUCTS_HOME: 'CLEAR_PRODUCTS_HOME',
  SET_PRODUCTS_HOME_IS_EMPTY: 'SET_PRODUCTS_HOME_IS_EMPTY',
  FETCH_PRODUCTS_HOME_SUCCESS: 'FETCH_PRODUCTS_HOME_SUCCESS',

  CLEAR_PRODUCTS_MODAL: 'CLEAR_PRODUCTS_MODAL',
  SET_PRODUCTS_MODAL_IS_EMPTY: 'SET_PRODUCTS_MODAL_IS_EMPTY',
  FETCH_PRODUCTS_MODAL_SUCCESS: 'FETCH_PRODUCTS_MODAL_SUCCESS',

  CLEAR_PRODUCTS_ALL: 'CLEAR_PRODUCTS_ALL',
  SET_PRODUCTS_ALL_IS_EMPTY: 'SET_PRODUCTS_ALL_IS_EMPTY',
  FETCH_PRODUCTS_ALL_SUCCESS: 'FETCH_PRODUCTS_ALL_SUCCESS',

  CLEAR_PRODUCTS_NO_CATEGORY: 'CLEAR_PRODUCTS_NO_CATEGORY',
  SET_PRODUCTS_NO_CATEGORY_IS_EMPTY: 'SET_PRODUCTS_NO_CATEGORY_IS_EMPTY',
  FETCH_PRODUCTS_NO_CATEGORY_SUCCESS: 'FETCH_PRODUCTS_NO_CATEGORY_SUCCESS',

  SET_FETCHING_GET_PRODUCT_BY_ID: 'SET_FETCHING_GET_PRODUCT_BY_ID',
  CLEAR_PRODUCT_BY_ID: 'CLEAR_PRODUCT_BY_ID',
  FETCH_GET_PRODUCT_BY_ID_SUCCESS: 'FETCH_GET_PRODUCT_BY_ID_SUCCESS',

  SET_FETCHING_PRODUCT_SUBMITTING: 'SET_FETCHING_PRODUCT_SUBMITTING',
  SET_FETCHING_PRODUCT_TOGGLE_ACTIVE: 'SET_FETCHING_PRODUCT_TOGGLE_ACTIVE',

  FETCH_PRODUCT_CREATE_SUCCESS: 'FETCH_PRODUCT_CREATE_SUCCESS',
  FETCH_PRODUCT_UPDATE_SUCCESS: 'FETCH_PRODUCT_UPDATE_SUCCESS',
  FETCH_PRODUCT_DELETE_SUCCESS: 'FETCH_PRODUCT_DELETE_SUCCESS',
  FETCH_PRODUCT_TOGGLE_ACTIVE_SUCCESS: 'FETCH_PRODUCT_TOGGLE_ACTIVE_SUCCESS',
}
