import React from 'react'

import Container from 'layout/Container'
import Section from 'layout/Section'

import imgPush from 'assets/images/home/services/push.jpg'
import imgPush2x from 'assets/images/home/services/push@2x.jpg'

import { ReactComponent as IconZoho } from 'assets/images/home/services/icons/zoho.svg'
import { ReactComponent as IconOdoo } from 'assets/images/home/services/icons/odoo.svg'
import { ReactComponent as IconFirebase } from 'assets/images/home/services/icons/firebase.svg'
import { ReactComponent as IconAppFluer } from 'assets/images/home/services/icons/app-flyer.svg'
import { ReactComponent as IconApple } from 'assets/images/home/services/icons/apple-pay.svg'
import { ReactComponent as IconGoogle } from 'assets/images/home/services/icons/google-pay.svg'

import SectionTitle from './SectionTitle'
import CardService from './CardService'
import GridList from './GridList'

const LIST = [
  {
    title: 'home.services.one.title',
    description: 'home.services.one.description',
    icons: [
      {
        icon: IconZoho,
        title: '',
      },
      {
        icon: IconOdoo,
        title: '',
      },
    ],
  },
  {
    img: {
      src: imgPush,
      srcSet: `${imgPush2x} 2x`,
    },
    title: 'home.services.two.title',
    description: 'home.services.two.description',
  },
  {
    title: 'home.services.three.title',
    description: 'home.services.three.description',
    icons: [
      {
        icon: IconFirebase,
        title: '',
      },
      {
        icon: IconAppFluer,
        title: '',
      },
    ],
  },
  {
    title: 'home.services.four.title',
    description: 'home.services.four.description',
    icons: [
      {
        icon: IconApple,
        title: '',
      },
      {
        icon: IconGoogle,
        title: '',
      },
    ],
  },
]

const SectionServices = ({ isLaptopOrMobile }) => (
  <Section>
    <Container>
      <SectionTitle titleId="home.heading.delivery" />
      <GridList
        items={LIST}
        renderCard={(props) => (
          <CardService
            fullHeight
            isMobile={isLaptopOrMobile}
            {...props}
          />
        )}
      />
    </Container>
  </Section>
)

export default SectionServices
