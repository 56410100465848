import React from 'react'
import s from 'styled-components'
import cn from 'classnames'

const renderStyles = ({
  display,
  flex,
  width,
  maxWidth,
  minWidth,
  onClick = null,
  noPointers = false,
  ai,
  jc,
  padding,
}) => `
  width: ${width || 'auto'};
  padding: ${padding || ''};
  max-width: ${maxWidth ? isNaN(maxWidth) ? maxWidth : `${maxWidth}px` : 'unset'};
  min-width: ${minWidth ? isNaN(minWidth) ? minWidth : `${minWidth}px` : 'unset'};
  display: ${display || 'block'};
  flex: ${flex || 1};
  align-items: ${ai || 'center'};
  justify-content: ${jc || 'initial'};
  overflow-wrap: anywhere;
  cursor: ${onClick ? 'pointer' : 'initial'};
  pointer-events: ${noPointers ? 'none' : 'initial'};
`

const GridItemContainerDiv = s.div`
  ${(props) => renderStyles(props)}
`

const GridItem = ({
  children,
  className,
  ...otherProps
}) => {
  const classes = cn('w-grid-item', className)

  const props = {
    className: classes,
    ...otherProps,
  }

  return (
    <GridItemContainerDiv {...props}>
      {children}
    </GridItemContainerDiv>
  )
}

export default GridItem
