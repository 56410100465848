import React from 'react'
import { ToastContainer } from 'react-toastify'

const Notifier = (props) => (
  <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar
    closeOnClick
    pauseOnFocusLoss
    pauseOnHover
    draggable
    newestOnTop={false}
    rtl={false}
    closeButton={false}
    {...props}
  />
)

export default Notifier
