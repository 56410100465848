import React from 'react'
import s from 'styled-components'

import useResponsive from 'components/hooks/useResponsive'
import ListTextItem from './ListTextItem'

const ListTextContainer = s.div`
  max-width: 460px;

  & > * {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
`
const ListText = ({
  children,
  items = [],
  isFormatted,
  ...props
}) => {
  const { isLaptopOrMobile } = useResponsive()

  return (
    <ListTextContainer>
      {children || items.map((item, idx) => (
        <ListTextItem
          key={idx}
          isFormatted={isFormatted}
          isLaptopOrMobile={isLaptopOrMobile}
          {...item}
          {...props}
        />
      ))}
    </ListTextContainer>
  )
}
export default ListText
