import React, { useContext } from 'react'

import { Box } from 'layout'
import { Button } from 'components/base'
import s, { ThemeContext } from 'styled-components'
import TextFormatted from 'components/typo/TextFormatted'

const CardEmptyContainer = s(Box)`
width: 100%;
padding: 86px 80px 80px 80px !important;
text-align: center;
background-color: ${({ theme }) => theme.colors.grey100};
border-radius: ${({ theme }) => theme.radius.normal};
cursor: pointer;

.card-icon {
  width: 55px;
  height: 55px;
  background-color: ${({ theme, bg }) => theme.colors[bg] || theme.colors.transparent};
  border-radius: 16px;
}

.card-inner {
  width: 100%;
  max-width: 510px;
}

&:hover {
  button {
    color: ${({ theme }) => theme.colors.black} !important;
  }
}
`

const CardEmpty = ({
  title,
  description,
  icon: Icon,
  btnTitle = '',
  onClick = null,
}) => {
  const theme = useContext(ThemeContext)

  return (
    <CardEmptyContainer
      onClick={onClick}
      br={16}
      direction="column"
      ai="center"
      theme={theme}
      className="card-empty"
    >
      <Box ai="center" jc="center" className="card-icon mb-4">
        {Icon && <Icon />}
      </Box>
      <div className="mb-4">
        <TextFormatted
          fw="sb"
          fs="medium"
          titleId={title}
        />
      </div>
      <div className="card-inner mb-4">
        <TextFormatted color="grey500" titleId={description} />
      </div>
      {btnTitle && (
        <Button
          defaultStyle="bordered"
          titleId={btnTitle}
          size="small"
        />
      )}
    </CardEmptyContainer>
  )
}

export default CardEmpty
