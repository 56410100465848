import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import Section from 'layout/Section'

// THEMES_PREVIEW IMAGES
import imgPreviewTheme1 from 'assets/images/home/themes/theme-1.png'
import imgPreviewTheme1en from 'assets/images/home/themes/theme-1-en.png'
import imgPreviewTheme1x2 from 'assets/images/home/themes/theme-1@2x.png'

import imgPreviewTheme2 from 'assets/images/home/themes/theme-2.png'
import imgPreviewTheme2en from 'assets/images/home/themes/theme-2-en.png'
import imgPreviewTheme2x2 from 'assets/images/home/themes/theme-2@2x.png'

import imgPreviewTheme3 from 'assets/images/home/themes/theme-3.png'
import imgPreviewTheme3en from 'assets/images/home/themes/theme-3-en.png'
import imgPreviewTheme3x2 from 'assets/images/home/themes/theme-3@2x.png'

import imgPreviewTheme4 from 'assets/images/home/themes/theme-4.png'
import imgPreviewTheme4en from 'assets/images/home/themes/theme-4-en.png'
import imgPreviewTheme4x2 from 'assets/images/home/themes/theme-4@2x.png'

import imgPreviewTheme5 from 'assets/images/home/themes/theme-5.png'
import imgPreviewTheme5en from 'assets/images/home/themes/theme-5-en.png'
import imgPreviewTheme5x2 from 'assets/images/home/themes/theme-5@2x.png'

// import imgPreviewTheme6 from 'assets/images/home/themes/theme-6.png'
// import imgPreviewTheme6en from 'assets/images/home/themes/theme-5-en.png'
// import imgPreviewTheme6x2 from 'assets/images/home/themes/theme-6@2x.png'

import { ReactComponent as IconTheme1 } from 'assets/images/home/themes/theme-1.svg'
import { ReactComponent as IconTheme2 } from 'assets/images/home/themes/theme-2.svg'
import { ReactComponent as IconTheme3 } from 'assets/images/home/themes/theme-3.svg'
import { ReactComponent as IconTheme4 } from 'assets/images/home/themes/theme-4.svg'
import { ReactComponent as IconTheme5 } from 'assets/images/home/themes/theme-5.svg'
// import { ReactComponent as IconTheme6 } from 'assets/images/home/themes/theme-6.svg'
import Button from 'components/base/Button'
import ButtonArrow from 'components/buttons/ButtonArrow'
import Formatted from 'components/typo/Formatted'
import Hero from './Hero'

const SectionHero = ({
  isLaptopOrMobile,
  isLaptopBigOrMobile = false,
  onRegistration = () => {},
  onRequest = () => {},
}) => {
  const { locale } = useSelector((state) => state.system)
  const THEMES_PREVIEW = useMemo(() => {
    const isRu = String(locale).toLowerCase().indexOf('ru') >= 0

    return [
      {
        id: 1,
        img: isRu ? imgPreviewTheme1 : imgPreviewTheme1en,
        imgX2: isRu ? imgPreviewTheme1x2 : imgPreviewTheme1en,
        title: 'Пиццерия',
        company: 'Flash Pizza',
        icon: IconTheme1,
      },
      {
        id: 2,
        img: isRu ? imgPreviewTheme2 : imgPreviewTheme2en,
        imgX2: isRu ? imgPreviewTheme2x2 : imgPreviewTheme2en,
        title: 'Роллы и суши',
        company: 'Sushi Man',
        icon: IconTheme2,
      },
      {
        id: 3,
        img: isRu ? imgPreviewTheme3 : imgPreviewTheme3en,
        imgX2: isRu ? imgPreviewTheme3x2 : imgPreviewTheme3en,
        title: 'Бургерные',
        company: 'Big Bro Burge',
        icon: IconTheme3,
      },
      {
        id: 4,
        img: isRu ? imgPreviewTheme4 : imgPreviewTheme4en,
        imgX2: isRu ? imgPreviewTheme4x2 : imgPreviewTheme4en,
        title: 'Авторская кухня',
        company: 'Black Sea',
        icon: IconTheme4,
      },
      {
        id: 5,
        img: isRu ? imgPreviewTheme5 : imgPreviewTheme5en,
        imgX2: isRu ? imgPreviewTheme5x2 : imgPreviewTheme5en,
        title: 'Кофейни',
        company: 'Coffee Room',
        icon: IconTheme5,
      },
      // {
      //   id: 6,
      //   img: isRu ? imgPreviewTheme6 : imgPreviewTheme6en,
      //   imgX2: isRu ? imgPreviewTheme6x2 : imgPreviewTheme6en,
      //   title: 'Дессерты и торты',
      //   company: 'Marry Cake',
      //   icon: IconTheme6,
      // },
    ]
  }, [ locale ])

  return (
    <Section br="bottom" className="p-0" m="0" isRelative isOverflowOff>
      <Hero
        isLaptopBigOrMobile={isLaptopBigOrMobile}
        isLaptopOrMobile={isLaptopOrMobile}
        items={THEMES_PREVIEW}
        title={(
          <>
            <Formatted id="home.hero.title1" />
            <br />
            <Formatted id="home.hero.title2" />
            <br />
            <Formatted id="home.hero.title3" />
            {' '}
            <i className="dec-under --s"><Formatted id="home.hero.title4" /></i>
          </>
        )}
        actions={(
          <>
            <Button
              size="big"
              width="big"
              titleId="button.schedule.call"
              onClick={onRequest}
            />
            {!isLaptopOrMobile && (
              <ButtonArrow
                defaultStyle="link-black"
                size="big"
                titleId="button.startFree"
                onClick={onRegistration}
                className={isLaptopBigOrMobile ? 'mt-3' : ''}
              />
            )}
          </>
        )}
      />
    </Section>
  )
}

export default SectionHero
