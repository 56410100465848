import React from 'react'

import Container from 'layout/Container'
import Section from 'layout/Section'

import { ReactComponent as Icon1 } from 'assets/icons/advantages/icon-1.svg'
import { ReactComponent as Icon2 } from 'assets/icons/advantages/icon-2.svg'
import { ReactComponent as Icon3 } from 'assets/icons/advantages/icon-3.svg'

import ScrollList from './ScrollList'
import SectionTitle from './SectionTitle'
import CardAdvantage from './CardAdvantage'

const LIST = [
  {
    icon: Icon1,
    texts: [ 'home.who.delivery.1', 'home.who.delivery.2', 'home.who.delivery.3' ],
  },
  {
    icon: Icon2,
    text: 'home.who.marketing',
  },
  {
    icon: Icon3,
    texts: [ 'home.who.model.1', 'home.who.model.2', 'home.who.model.3' ],
  },
]

const SectionWho = () => (
  <Section bg="light" br="bottom" isBrTopOff className="pb-0">
    <Container>
      <SectionTitle titleId="home.heading.forWho" />
      <ScrollList
        items={LIST}
        isShadow
        renderCard={(props) => <CardAdvantage isShadow {...props} />}
      />
    </Container>
  </Section>
)

export default SectionWho
