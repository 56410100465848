import React, { useMemo } from 'react'
import cn from 'classnames'

import { Text } from 'components/typo'
import Formatted from 'components/typo/Formatted'

const CardContentText = ({
  isActive,
  isHovered,
  title,
  titleId,
  description,
  descriptionId,
  descriptionSize = 'small',
  initColor = 'black',
  ...props
}) => {
  const titleColor = useMemo(() => {
    let color = initColor

    if (!isActive && !isHovered) {
      color = 'grey500'
    }
    else if (isHovered) {
      color = 'grey700'
    }

    return color
  }, [ isActive, isHovered, initColor ])

  const descriptionColor = useMemo(() => {
    let color = 'grey500'

    if (isHovered) {
      color = 'grey700'
    }

    return color
  }, [ isHovered ])

  return (
    <>
      {(title || titleId) && (
        <Text
          noPointers
          color={titleColor}
          className={cn({ 'mb-1': description })}
          transitionOn
          {...props}
        >
          {titleId ? <Formatted id={titleId} /> : title}
        </Text>
      )}
      {(description || descriptionId) && (
        <Text
          noPointers
          size={descriptionSize}
          color={descriptionColor}
          transitionOn
          {...props}
        >
          {descriptionId ? <Formatted id={descriptionId} /> : description}
        </Text>
      )}
    </>
  )
}

export default CardContentText
