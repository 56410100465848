import React, { Component } from 'react'
import cn from 'classnames'

import { Text } from 'components/typo'

export default class Countdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      time: {},
      seconds: props.seconds || 5,
    }
    this.timer = 0
  }

  componentDidMount() {
    const { seconds } = this.state

    const timeLeftVar = this.secondsToTime(seconds)
    this.setState({ time: timeLeftVar })
    this.startTimer()
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  normalizeNum = (num) => {
    let val = ''

    if (num.toString().length === 0) val = '00'
    else if (num.toString().length === 1) val = `0${num}`
    else val = num

    return val
  }

  startTimer = () => {
    if (+this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000)
    }
  }

  countDown = () => {
    const { seconds } = this.state

    const s = seconds - 1
    this.setState({
      time: this.secondsToTime(s),
      seconds: s,
    })

    // Check if we're at zero.
    if (+seconds === 0) {
      clearInterval(this.timer)
      if (this.props.handleEnd) this.props.handleEnd()
    }
  }

  secondsToTime(secs) {
    const hours = Math.floor(secs / (60 * 60))

    const divisorForMinutes = secs % (60 * 60)
    const minutes = Math.floor(divisorForMinutes / 60)

    const divisorForSeconds = divisorForMinutes % 60
    const seconds = Math.ceil(divisorForSeconds)

    const obj = {
      h: this.normalizeNum(hours),
      m: this.normalizeNum(minutes),
      s: this.normalizeNum(seconds),
    }
    return obj
  }

  render() {
    const {
      className, children, ...otherProps
    } = this.props
    const classes = cn('w-countdown', className)
    const props = {
      className: classes,
      ...otherProps,
    }

    return (
      <Text {...props}>
        {this.state.time.m}
        :
        {this.state.time.s}
      </Text>
    )
  }
}
