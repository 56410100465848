import React from 'react'
import { Link } from 'components/typo'
import s from 'styled-components'
import cn from 'classnames'
import Formatted from 'components/typo/Formatted'

const getStyles = ({ theme }) => `
  display: flex;
  align-items: center;
  padding: 10px 0;
  color: ${theme.colors.black};
  font-size: 16px;
  transition: color 0.3s;
  line-height: 24px;

  &.--active,
  &:hover {
    color: ${theme.colors.black700};
  }
`

const HeaderLinkContainer = s(Link)`
  ${getStyles}
`

const HeaderLinkBrowserContainer = s.a`
  ${getStyles}
`

const PageHeaderLink = ({
  titleId,
  active,
  onClick = null,
  href = null,
  ...otherProps
}) => (href ? (
  <HeaderLinkBrowserContainer
    href={href}
    {...otherProps}
    onClick={onClick}
    className={cn('page-header-link', { '--active': active })}
  >
    <Formatted id={titleId} />
  </HeaderLinkBrowserContainer>
) : (
  <HeaderLinkContainer
    {...otherProps}
    onClick={onClick}
    className={cn('page-header-link', { '--active': active })}
  >
    <Formatted id={titleId} />
  </HeaderLinkContainer>
))

export default PageHeaderLink
