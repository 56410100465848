import React from 'react'
import Select from 'react-select'
import s from 'styled-components'

const SelectContainer = s(Select)`
width: 100%;

.select-group__control:hover,
.select-group__control {
  border-color: #e9eff4;
  box-shadow: unset;
}

.select-group__indicator-separator {
  display: none;
}

.select-group__indicator {
  color: currentColor;

  svg {
    transform: rotateX(0deg);
    fill: currentColor;
  }
}

.select-group__control--menu-is-open .select-group__indicator svg {
  transform: rotateX(180deg);
}

.select-group__single-value {
  color: currentColor;
}

.select-group__menu {
  right: 0;
  z-index: 10;
  width: auto;
  max-height: 250px;
  margin-top: 12px;
  padding: 8px 0 8px 16px;
  overflow-y: auto;
  border: 0.5px solid rgba(226, 232, 240, 1);
  border-radius: 10px;
  box-shadow: 4px 10px 25px 0 rgba(113, 128, 150, 0.12);

  &-list {
    padding-right: 20px;
    overflow-y: unset;
  }
}

.select-group__option {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.select-group__control {
  height: ${({ size, theme }) => (size && theme.sizes[size] ? theme.sizes[size] : theme.sizes.normal)};
  color: ${({ theme, isError }) => (isError ? `${theme.colors.red} !important` : theme.colors.grey500)};
  background-color: ${({ theme, isError }) => (isError ? theme.colors.transparent : theme.colors.grey100)};
  border: ${({ theme, isError }) => `0.5px solid ${isError ? `${theme.colors.red} !important` : theme.colors.grey100}`};
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border-color 0.3s ease-in-out;

  &:hover {
    border-color: ${({ theme }) => theme.colors.grey300};
  }

  &.select-group__control--is-disabled {
    color: ${({ theme, isError }) => (isError ? `${theme.colors.red} !important` : theme.colors.grey400)};
  }
}

.select-group__control.select-group__control--menu-is-open,
.select-group__control.select-group__control--is-focused {
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme }) => theme.colors.black};

  .select-group__indicator {
    color: ${({ theme }) => theme.colors.black};
  }
}

.select-group__placeholder {
  color: ${({ theme }) => theme.colors.grey500};
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
}

.select-group__value-container {
  padding: ${({ icons }) => (icons ? '5px' : '11px 16px')};
}
`
// border: ${({ theme }) => `0.5px solid ${theme.colors.grey300}`};

const SelectBase = ({
  labelName = 'label',
  valueName = 'value',
  classNames = 'select-group',
  ...otherProps
}) => (
  <SelectContainer
    {...otherProps}
    getOptionLabel={(option) => option[labelName]}
    getOptionValue={(option) => option[valueName]}
    classNamePrefix={classNames}
    menuPlacement="auto"
  />
)

export default SelectBase
