import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import s from 'styled-components'
import cn from 'classnames'

import WithHover from 'components/hooks/WithHover'
import { ButtonSettings } from 'components/buttons'
import Dropdown from 'components/base/Dropdown'
import CompanyLogoMenu from 'components/CompanyLogoMenu'

import { fetchLogout } from 'redux/actions/AuthActions'
import { setModal } from 'redux/actions/SystemActions'

import * as PathsTypes from 'constants/PathsTypes'

const styles = ({ theme, bgImage }) => `
  position: relative;
  padding: 24px;
  padding-right: 20px;
  cursor: pointer;

  .company-logo {
    &-inner {
      display: flex;
      align-items: center;
    }

    &-img {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 44px;
      height: 44px;
      color: ${theme.colors.white};
      background-color: ${bgImage ? theme.colors.transparent : theme.colors.blue};
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-image: ${bgImage ? `url(${bgImage})` : 'unset'};
      border-radius: 12px;
      text-transform: uppercase;
      font-weight: ${theme.fw.sb}
    }

    &-title {
      font-weight: 600;
      font-size: 18px;
      padding-right: 20px;
      color: ${theme.colors.black};
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 60px;
      white-space: nowrap;
      margin-left: 15px;
    }

    &-settings {
      align-self: center;
      // opacity: 0;
      // transition: 0.3s opacity ease-in-out;

      &.--toggle-hidden {
        left: -50px;
        top: 20px;
      }
    }
  }
`

const CompanyLogoContainer = s.div`
  ${(props) => styles(props)}
`

const CompanyLogo = ({
  company,
  isHovered,
  isDesktopSmall,
  ...otherProps
}) => {
  const settingsBtn = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()

  const delegateSettingsClick = () => {
    if (settingsBtn && settingsBtn.current) {
      settingsBtn.current.click()
    }
  }

  const handleAccount = () => {
    history.push(PathsTypes.INTERFACE_SETTINGS_ACCOUNT)
  }

  const handleSubscriptions = () => {
    dispatch(setModal({ name: 'subscriptions' }))
  }

  const handleLogout = () => {
    dispatch(fetchLogout(history))
  }

  return (
    <CompanyLogoContainer
      onClick={delegateSettingsClick}
      bgImage={company.image}
      className="company-logo"
      {...otherProps}
    >
      <div className="company-logo-inner">
        <div className="company-logo-img">{!company.image ? company.title.slice(0, 1) : ''}</div>
        {' '}
        {!isDesktopSmall && (
          <span className="company-logo-title">{company.title}</span>
        )}
        <Dropdown
          className={cn('ml-a company-logo-settings', { '--toggle-hidden': isDesktopSmall })}
          toggler={(
            <ButtonSettings
              ref={settingsBtn}
              fillHover="black"
              isHovered={isHovered}
            />
          )}
          isHovered={isHovered}
          renderMenu={({ close }) => (
            <CompanyLogoMenu
              close={close}
              onAccount={handleAccount}
              onSubscription={handleSubscriptions}
              onLogout={handleLogout}
              position={isDesktopSmall ? 'bottom-left' : 'bottom-right'}
            />
          )}
        />
      </div>
    </CompanyLogoContainer>
  )
}

export default WithHover(CompanyLogo)
