import { BannersActionTypes } from 'redux/types/BannersTypes'
import { UserActionTypes } from 'redux/types/UserTypes'

const INITIAL_STATE = {
  name: 'banners',
  homeList: [],
  homeListIsEmpty: [],
  isFetching: false,
  isFetchingToggleActive: false,
  isSubmitting: false,
}

const bannersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE }

  case BannersActionTypes.SET_FETCHING_BANNERS:
    return {
      ...state,
      isFetching: action.payload,
    }

  case BannersActionTypes.FETCH_BANNERS_HOME_SUCCESS:
    return {
      ...state,
      homeList: action.payload,
    }

  case BannersActionTypes.SET_BANNERS_HOME_IS_EMPTY:
    return {
      ...state,
      homeListIsEmpty: action.payload,
    }

  case BannersActionTypes.CLEAR_BANNERS_HOME:
    return {
      ...state,
      homeList: [],
      homeListIsEmpty: false,
    }

  case BannersActionTypes.FETCH_BANNERS_ALL_SUCCESS:
    return {
      ...state,
      allList: action.payload,
    }

  case BannersActionTypes.SET_BANNERS_ALL_IS_EMPTY:
    return {
      ...state,
      allListIsEmpty: action.payload,
    }

  case BannersActionTypes.CLEAR_BANNERS_ALL:
    return {
      ...state,
      allList: [],
      allListIsEmpty: false,
    }

  case BannersActionTypes.SET_FETCHING_BANNER_SUBMITTING:
    return {
      ...state,
      isSubmitting: action.payload,
    }

  case BannersActionTypes.FETCH_BANNER_CREATE_SUCCESS:
    return {
      ...state,
      homeList: [ ...state.homeList, action.payload ].sort((a, b) => {
        if (a.position - b.position > 0) return 1
        if (a.position - b.position < 0) return -1
        return 0
      }),
    }

  case BannersActionTypes.FETCH_BANNER_UPDATE_SUCCESS: {
    const isExistIdx = state.homeList.findIndex((c) => c.id === action.payload.id)

    if (isExistIdx >= 0) {
      return {
        ...state,
        homeList: [
          ...state.homeList.slice(0, isExistIdx),
          action.payload,
          ...state.homeList.slice(isExistIdx + 1),
        ],
      }
    }

    return state
  }

  case BannersActionTypes.FETCH_BANNER_DELETE_SUCCESS:
    return {
      ...state,
      homeList: state.homeList.filter((c) => c.id !== action.payload),
    }

  case BannersActionTypes.SET_FETCHING_BANNER_TOGGLE_ACTIVE:
    return {
      ...state,
      isFetchingToggleActive: action.payload,
    }

  case BannersActionTypes.FETCH_BANNER_TOGGLE_ACTIVE_SUCCESS:
    return {
      ...state,
      homeList: [
        ...state.homeList.map((p) => (p.id === action.payload.id
          ? action.payload
          : p)),
      ],
    }

  default:
    return state
  }
}

export default bannersReducer
