/* eslint-disable import/no-cycle */
import React, { useState } from 'react'
import s from 'styled-components'

import { OutsideAlerter } from 'utils'

const DropdownContainer = s.div`
  position: relative;
  display: flex;
  align-items: center;
  align-self: stretch;
  padding: ${({ padding }) => padding || 0}px;

  &.--toggle-hidden .dropdown-toggle {
    position: absolute;
    top: 100%;
    left: -50%;
    transform: translate3d(-50%, 0%, 0);
    visibility: hidden;
  }
`

const Dropdown = ({
  children,
  toggler,
  renderMenu,
  ...otherProps
}) => {
  const [ isOpen, setIsOpen ] = useState(false)

  const toggleDropdownMenu = (e) => {
    e.stopPropagation()
    setIsOpen(!isOpen)
  }

  const closeDropdown = () => setIsOpen(false)

  // const ToggleMenu = React.cloneElement(children[0], { isOpen })

  // const DropdownMenu = React.cloneElement(children[1], {
  //   closeDropdown,
  //   isOpen,
  // })

  return (
    <DropdownContainer {...otherProps}>
      <OutsideAlerter onClickOutside={closeDropdown}>
        <div>
          <div className="dropdown-toggle" onClick={toggleDropdownMenu}>
            {toggler}
          </div>
          {isOpen && renderMenu && (
            renderMenu({ close: closeDropdown })
          )}
        </div>
      </OutsideAlerter>
    </DropdownContainer>
  )
}

export default Dropdown
