import React from 'react'
import s from 'styled-components'
import cn from 'classnames'

const paddings = {
  none: '0px',
  small: '6px',
  normal: '12px',
  medium: '14px',
  big: '16px',
  full: 0,
  withSidebar: '60px 70px',
}

const paddingsLaptop = {
  none: '0px',
  small: '6px',
  normal: '12px',
  medium: '14px',
  big: '16px',
  full: 0,
  withSidebar: '50px',
}

const paddingsMobile = {
  none: '0px',
  small: '6px',
  normal: '12px',
  medium: '14px',
  big: '16px',
  full: 0,
  withSidebar: '46px 16px 24px 16px',
}

const styles = ({
  p,
  flex,
  column,
  display,
  center,
  overflowX,
  overflowY,
}) => {
  const isModalWithSidebar = p === 'withSidebar'

  return `
    display: ${display};
    width: 100%;
    padding: ${p ? paddings[p] || p : paddings.withSidebar};
    flex: ${flex ? 1 : 'unset'};
    flex-direction: ${column ? 'column' : ''};
    align-items: ${column ? 'center' : ''};
    text-align: ${center ? 'center' : 'left'};
    color: #000;
    overflow-x: ${overflowX || 'hidden'};
    overflow-y: ${overflowY || 'none'};

    @media (max-width: 1140px) {
      padding: ${p ? paddingsLaptop[p] || p : paddingsLaptop.withSidebar};
    }

    @media (max-width: 600px) {
      padding: ${p ? paddingsMobile[p] || p : paddingsMobile.withSidebar};
    }

    ${isModalWithSidebar ? `
      min-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 600px) {
        align-items: flex-start;
      }

      & > * {
        @media (max-width: 600px) {
          justify-content: flex-start;
        }

        max-width: 510px;
        width: 100%;
        margin: 0 auto;
      }
    ` : `
      flex: 1;
    `}
  `
}

const ModalContentContainer = s.div`
  ${(props) => styles(props)}
`

const ModalContent = ({
  className,
  children,
  display = 'block',
  ...otherProps
}) => {
  const classes = cn('w-modal-content', className)

  const props = {
    ...otherProps,
    className: classes,
    display,
  }

  return (
    <ModalContentContainer className={classes} {...props}>
      {children}
    </ModalContentContainer>
  )
}

export default ModalContent
