import React from 'react'
import cn from 'classnames'

import {
  Grid,
  GridItem,
} from 'layout'
import Dropdown from 'components/base/Dropdown'
import { ButtonSettings } from 'components/buttons'

import {
  Card,
  CardMenuSettings,
  CardBtnTrash,
} from 'components/cards'

const CardFull = ({
  children,
  onDelete = null,
  onEdit = null,
  onInfo = null,
  onClick = null,
  onDelivery = null,
  onAccept = null,
  onReject = null,
  onStart = null,
  onComplete = null,
  onStopListToggle = null,
  trash = false,
  gutter = 'big',
  isBlocked,
  isHovered,
  lastAuto = false,
  deleteTitle,
  ...otherProps
}) => (
  <Card
    {...otherProps}
    onClick={onClick}
    isHovered={isHovered}
    isBlocked={isBlocked}
    br={16}
    direction="column"
    padding="24px 0"
    ai="center"
    pointer
  >
    <Grid noPointers gutter={gutter} ai="center">
      {children}
      <GridItem className={cn({ 'ml-a': lastAuto })} flex="0">
        {trash
          ? (
            <CardBtnTrash
              isHovered={isHovered}
              onClick={(e) => {
                e.stopPropagation()
                onDelete()
              }}
            />
          ) : (
            <Dropdown
              toggler={<ButtonSettings fillHover="black" isHovered={isHovered} />}
              isHovered={isHovered}
              renderMenu={({ close }) => (
                <CardMenuSettings
                  isHovered={isHovered}
                  isBlocked={isBlocked}
                  onDelete={onDelete}
                  deleteTitle={deleteTitle}
                  onEdit={onEdit}
                  onInfo={onInfo}
                  onClose={close}
                  onStopListToggle={onStopListToggle}
                  onAccept={onAccept}
                  onReject={onReject}
                  onDelivery={onDelivery}
                  onComplete={onComplete}
                  onStart={onStart}
                />
              )}
            />
          )}
      </GridItem>
    </Grid>
  </Card>
)

export default CardFull
