import React, { useContext } from 'react'
import s, { ThemeContext } from 'styled-components'
import TextFormatted from 'components/typo/TextFormatted'

const DropdownMenuItemContainer = s.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  color: ${({ theme, light }) => (light ? theme.colors.grey500 : theme.colors.black)};
  cursor: pointer;

  &:hover {
    color: ${({ theme, dark }) => (dark ? theme.colors.grey700 : theme.colors.black)};

    .w-dropdown-icon {
      color: currentColor;
    }
  }

  .w-dropdown {
    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      margin-right: 20px;
      padding: 3px;
      color: ${({ theme }) => theme.colors.grey500};
      transition: color 0.3s ease-in-out;

      svg {
        fill: currentColor;
      }
    }

    &-title {
      white-space: nowrap;
    }
  }
`

const DropdownMenuItem = ({
  show,
  color = 'grey500',
  children,
  icon: Icon = null,
  iconWidth = 24,
  iconHeight = 24,
  light = false,
  title = null,
  ...otherProps
}) => {
  const theme = useContext(ThemeContext)

  return (
    <DropdownMenuItemContainer
      {...otherProps}
      className="w-dropdown"
      theme={theme}
      color={color}
      light={light}
    >
      {Icon && (
        <div className="w-dropdown-icon">
          <Icon width={iconWidth} height={iconHeight} />
        </div>
      )}
      {children}
      {title && (
        <TextFormatted
          transitionOn
          className="w-dropdown-title"
          color="inherit"
          titleId={title}
        />
      )}
    </DropdownMenuItemContainer>
  )
}

export default DropdownMenuItem
