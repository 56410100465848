import React from 'react'
import s from 'styled-components'
import PropTypes from 'prop-types'
import { theme } from 'theme'
import Formatted from 'components/typo/Formatted'

export { Text } from 'components/typo'

const defaultStyles = ({
  br = 6,
  theme,
  variant = 'grey',
  height = '',
  width = '',
}) => {
  const styles = theme.components.bage[variant] || theme.components.bage.grey

  return `
    display: inline-flex;
    padding: 5px 8px;
    background-color: ${styles.bg};
    color: ${styles.color};
    border-radius: ${br}px;
    height: ${height || ''};
    min-width: ${width || ''};
    font-size: 14px;
    line-height: ${height ? height - '5px' : '21px'};
    font-weight: ${theme.fw.sb};
    white-space: nowrap;
    text-transform: uppercase;
    letter-spacing: 1px;
  `
}

const BageContainer = s.div`
  ${(props) => defaultStyles(props)}
`

const Bage = ({
  title,
  titleId,
  children,
  variant = 'grey',
  ...otherProps
}) => (
  <BageContainer
    className="w-bage"
    variant={variant}
    {...otherProps}
  >
    <span>
      {titleId
        ? <Formatted id={titleId} />
        : title || children}
    </span>
  </BageContainer>
)

const bageVariants = Object.keys(theme.components.bage)

Bage.propTypes = { variant: PropTypes.oneOf(bageVariants) }

export default Bage
