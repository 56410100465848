import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Link, Title } from 'components/typo'
import { Formik, Field } from 'formik'
import { injectIntl } from 'react-intl'

// components
import { Button } from 'components/base'

import {
  Form,
  FormField,
  FormGroup,
} from 'components/form'
import useResponsive from 'components/hooks/useResponsive'

// actions
import { fetchGetAuthToken } from 'redux/actions/AuthActions'
import { setModal } from 'redux/actions/SystemActions'

// constants
import * as PathsTypes from 'constants/PathsTypes'
import { SchemaLogin } from 'constants/ValidationSchemas'
import { PublishTypes } from 'constants/PublishTypes'
import Formatted from 'components/typo/Formatted'

const Login = ({ intl }) => {
  const { isLaptopOrMobile } = useResponsive()
  const placeholderPassword = intl.formatMessage({ id: 'form.input.password' })

  const dispatch = useDispatch()
  const { auth: isFetchingAuth } = useSelector((state) => state.user.isFetching)
  const { modal } = useSelector((state) => state.system)
  const history = useHistory()

  const handleForgetModal = (e) => {
    e.preventDefault()
    dispatch(setModal({
      name: 'forget-password',
      back: true,
    }))
  }

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(fetchGetAuthToken(values))
          .then((res) => {
            setSubmitting(false)
            const { status } = res.company.company_app

            if (modal.name) dispatch(setModal({ close: true }))

            history.push(status && status === PublishTypes.PUBLISHED
              ? PathsTypes.INTERFACE_ORDERS
              : PathsTypes.INTERFACE_SETTINGS)
          })
          .catch(() => setSubmitting(false))
      }}
      validationSchema={SchemaLogin}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
        } = props

        const attrs = {
          onChange: (e) => {
            e.target.value = e.target.value.trim()
            handleChange(e)
          },
          onBlur: handleBlur,
          errors,
          touched,
          size: 'big',
        }

        const formIsValid = dirty && !errors.username && values.username.length && !errors.password && values.password.length && !isSubmitting

        return (
          <Form onSubmit={handleSubmit}>
            <div className={isLaptopOrMobile ? 'mb-20' : 'mb-5'}>
              <Title center tag="h2"><Formatted id="form.login.title" /></Title>
            </div>
            <FormGroup>
              <Field>
                {() => (
                  <FormField
                    {...attrs}
                    autoFocus
                    name="username"
                    placeholder="Email"
                    type="text"
                    value={values.username.trim()}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup>
              <Field>
                {() => (
                  <FormField
                    {...attrs}
                    name="password"
                    placeholder={placeholderPassword}
                    type="password"
                    value={values.password.trim()}
                  />
                )}
              </Field>
            </FormGroup>
            <div className={isLaptopOrMobile ? 'my-20' : 'my-4'}>
              <Link to="/#forget-password" onClick={handleForgetModal}>
                <Formatted id="form.button.forgetPassword" />
              </Link>
            </div>
            <FormGroup>
              <Button
                id="sign-in-button"
                defaultStyle="acsent"
                type="submit"
                size="big"
                full
                titleId="button.login"
                isDisabled={!formIsValid || isFetchingAuth}
                isFetching={isFetchingAuth || isSubmitting}
              />
            </FormGroup>
          </Form>
        )
      }}
    </Formik>
  )
}

export default injectIntl(Login)
