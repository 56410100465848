import { toast } from 'react-toastify'

import ApiService from 'services/ApiService'
import StorageService from 'services/StorageService'

import { setMessage } from 'components/base/NotifierMessage'
import { messageTypes } from 'constants/NotifierTypes'

import * as PathsTypes from 'constants/PathsTypes'
import { setNotification, setModal } from './SystemActions'
import { AuthActionTypes } from '../types/AuthTypes'

export const setPhone = (phone) => ({
  type: AuthActionTypes.SET_PHONE,
  payload: phone,
})

export const setRegistrationForm = (data) => ({
  type: AuthActionTypes.SET_REGISTRATION_FORM,
  payload: data,
})

export const setPhoneConfirmationForm = (data) => ({
  type: AuthActionTypes.SET_PHONE_CONFIRMATION_FORM,
  payload: data,
})

export const setFetchinghGetAuthToken = (value) => ({
  type: AuthActionTypes.SET_FETCHING_GET_AUTH_TOKEN,
  payload: value,
})

export const fetchGetAuthTokenSuccess = (value) => ({
  type: AuthActionTypes.FETCH_GET_AUTH_TOKEN_SUCCESS,
  payload: value,
})

export const fetchGetAuthToken = (data, login = true) => (dispatch) => {
  dispatch(setFetchinghGetAuthToken(true))

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'POST',
    data,
    url: `${ApiService.paths.auth.GET_TOKEN}`,
  })
    .then(({ token }) => {
      dispatch(setFetchinghGetAuthToken(false))

      if (login) {
        return dispatch(fetchLogin(token)).then((response) => res(response))
      }

      return res(token)
    })
    .catch(({ data }) => {
      dispatch(setFetchinghGetAuthToken(false))
      return rej(data)
    }))
}

export const setFetchinghGetFirebaseAuthToken = (value) => ({
  type: AuthActionTypes.SET_FETCHING_GET_AUTH_TOKEN,
  payload: value,
})

export const fetchhGetFirebaseAuthToken = (value) => ({
  type: AuthActionTypes.FETCH_GET_AUTH_TOKEN_SUCCESS,
  payload: value,
})

export const fetchGetFirebaseAuthToken = (data) => (dispatch) => {
  dispatch(setFetchinghGetAuthToken(true))

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'POST',
    data,
    url: `${ApiService.paths.auth.GET_FIREBASE_AUTH_TOKEN}`,
  })
    .then(({ token }) => {
      dispatch(setFetchinghGetFirebaseAuthToken(false))

      return res(token)
    })
    .catch(({ data }) => {
      dispatch(setFetchinghGetFirebaseAuthToken(false))
      return rej(data)
    }))
}

export const setFetchingRegistration = (user) => ({
  type: AuthActionTypes.SET_FETCHING_REGISTRATION,
  payload: user,
})

export const fetchRegistrationSuccess = (user) => ({
  type: AuthActionTypes.FETCH_REGISTRATION_SUCCESS,
  payload: user,
})

export const fetchUserRegistrationAsync = (data, history) => (dispatch) => {
  dispatch(setFetchingRegistration(true))

  return new Promise((res, rej) => ApiService.apiCall({
    url: ApiService.paths.auth.REGISTRATION,
    method: 'POST',
    data,
  })
    .then((response) => {
      dispatch(setFetchingRegistration(false))
      dispatch(fetchRegistrationSuccess())
      StorageService.setUserSession(response)

      dispatch(setNotification({
        message: 'Регистрация прошла успешно',
        type: messageTypes.CHECK,
      }))

      return dispatch(fetchGetAuthToken({
        username: data.phone.substring(1),
        password: data.password,
      }, true)).then(() => {
        dispatch(setModal({ close: true }))
        history.push(PathsTypes.INTERFACE_SETTINGS)
        return res()
      })
    })
    .catch(({ data }) => {
      dispatch(setFetchingRegistration(false))
      return rej(data)
    }))
}

export const setFetchinghLogin = (value) => ({
  type: AuthActionTypes.SET_FETCHING_LOGIN,
  payload: value,
})

export const fetchLoginSuccess = (value) => ({
  type: AuthActionTypes.FETCH_LOGIN_SUCCESS,
  payload: value,
})

export const fetchLogin = (token, isMessage = true) => (dispatch) => {
  dispatch(setFetchinghLogin(true))

  return new Promise((res, rej) => ApiService.apiCall({
    url: ApiService.paths.auth.LOGIN,
    token,
  })
    .then((user) => {
      dispatch(setFetchinghLogin(false))
      dispatch(fetchLoginSuccess(user))
      StorageService.setUserSession(user)
      StorageService.setToken(token)

      if (isMessage) {
        dispatch(setNotification({
          message: 'Вход выполнен успешно',
          type: messageTypes.CHECK,
        }))
      }

      return res(user)
    })
    .catch(({ data }) => {
      dispatch(setFetchinghLogin(false))
      return rej(data)
    }))
}

export const fetchLogoutSuccess = (value) => ({
  type: AuthActionTypes.FETCH_LOGOUT_SUCCESS,
  payload: value,
})

export const fetchLogout = (history) => (dispatch) => {
  history.push('/')
  dispatch(fetchLogoutSuccess())
  StorageService.removeUserSession()
  StorageService.removeToken()

  dispatch(setNotification({
    message: 'Выход выполнен успешно',
    type: messageTypes.CHECK,
  }))
}

export const setFetchingChangePassword = (value) => ({
  type: AuthActionTypes.SET_FETCHING_CHANGE_PASSWORD,
  payload: value,
})

export const fetchChangePasswordSuccess = (value) => ({
  type: AuthActionTypes.FETCH_CHANGE_PASSWORD,
  payload: value,
})

export const fetchChangePassword = (data, token = null) => (dispatch) => {
  dispatch(setFetchingChangePassword(true))

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'POST',
    data,
    token: token || StorageService.getToken(),
    url: ApiService.paths.auth.CHANGE_PASSWORD,
  })
    .then((response) => {
      dispatch(setFetchingChangePassword(false))
      dispatch(setNotification({
        message: 'Пароль успешно изменен',
        type: messageTypes.MESSAGE,
      }))

      return res(response)
    })
    .catch(({ data }) => {
      dispatch(setFetchingChangePassword(false))
      return rej(data)
    }))
}

export const setFetchingEmailConfirm = (value) => ({
  type: AuthActionTypes.SET_FETCHING_EMAIL_CONFIRM,
  payload: value,
})

export const fetchEmailConfirmSuccess = (value) => ({
  type: AuthActionTypes.FETCH_EMAIL_CONFIRM,
  payload: value,
})

export const fetchEmailConfirm = ({ code, uid }) => (dispatch) => {
  dispatch(setFetchingEmailConfirm(true))

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'POST',
    data: { code },
    url: `${ApiService.paths.auth.EMAIL_CONFIRM(uid)}`,
  })
    .then((response) => {
      dispatch(setFetchingEmailConfirm(false))
      toast.success(`Email подтвержден!`)
      return res(response)
    })
    .catch(({ data }) => {
      const { non_field_errors: errorMessages } = data
      dispatch(setFetchingEmailConfirm(false))
      dispatch(setMessage({
        message: (errorMessages && errorMessages[0]) || JSON.stringify(data),
        type: messageTypes.ERROR,
      }))

      return rej(data)
    }))
}
