import React from 'react'

// components
import { Title, Text } from 'components/typo'
import { Container, Page } from 'layout'
import PartnersList from 'components/PartnersList'

// constants
import * as PathsTypes from 'constants/PathsTypes'
import imgSmd from 'assets/images/partners/smd.svg'
import useResponsive from 'components/hooks/useResponsive'

const PARTNERS = [
  {
    img: imgSmd,
    href: `https://smd.agency/?utm_source=${window.location.hostname}`,
    title: 'SMD Agency',
  },
]

const PartnersPage = () => {
  const { isLaptopOrMobile } = useResponsive()
  const link = (
    <Text
      target="_blank"
      tag="a"
      color="purple"
      hoverColor="purplet80"
      size="inherit"
      href={PathsTypes.PARTNERSHIP_LINK}
    >
      {PathsTypes.PARTNERSHIP_LINK}
    </Text>
  )

  return (
    <Page back="/">
      <Container size="xs" className="pt-5">
        <div className={isLaptopOrMobile ? 'mb-4' : 'mb-6'}>
          <Title tag="h1">Партнерство</Title>
        </div>
        <div className={isLaptopOrMobile ? 'mb-4' : 'mb-6'}>
          <p>
            Откройте представительство Wiby в вашем городе!
            Продавайте конструктор мобильных приложений, в котором нуждается любой малый и средний бизнес.
            {' '}
            {link}
          </p>
        </div>
        <PartnersList items={PARTNERS} />
      </Container>
    </Page>
  )
}

export default PartnersPage
