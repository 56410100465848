export const OrdersActionTypes = {
  SET_FETCHING_ORDERS: 'SET_FETCHING_ORDERS',
  FETCH_ORDERS_ERROR: 'FETCH_ORDERS_ERROR',

  CLEAR_ORDERS_HOME: 'CLEAR_ORDERS_HOME',
  SET_ORDERS_HOME_IS_EMPTY: 'SET_ORDERS_HOME_IS_EMPTY',
  FETCH_ORDERS_HOME_SUCCESS: 'FETCH_ORDERS_HOME_SUCCESS',

  CLEAR_ORDERS_CLIENT: 'CLEAR_ORDERS_CLIENT',
  SET_ORDERS_CLIENT_IS_EMPTY: 'SET_ORDERS_CLIENT_IS_EMPTY',
  FETCH_ORDERS_CLIENT_SUCCESS: 'FETCH_ORDERS_CLIENT_SUCCESS',

  SET_FETCHING_GET_ORDER_BY_ID: 'SET_FETCHING_GET_ORDER_BY_ID',
  FETCH_GET_ORDER_BY_ID_SUCCESS: 'FETCH_GET_ORDER_BY_ID_SUCCESS',

  SET_FETCHING_ORDER_SUBMITTING: 'SET_FETCHING_ORDER_SUBMITTING',
  SET_FETCHING_ORDER_TOGGLE_ACTIVE: 'SET_FETCHING_ORDER_TOGGLE_ACTIVE',

  FETCH_ORDER_DELETE_SUCCESS: 'FETCH_ORDER_DELETE_SUCCESS',

  FETCH_ORDER_STATUS_READY_SUCCESS: 'FETCH_ORDER_STATUS_READY_SUCCESS',
  FETCH_ORDER_STATUS_READY_ERROR: 'FETCH_ORDER_STATUS_READY_ERROR',

  FETCH_ORDER_STATUS_COMPLETE_SUCCESS: 'FETCH_ORDER_STATUS_COMPLETE_SUCCESS',
  FETCH_ORDER_STATUS_COMPLETE_ERROR: 'FETCH_ORDER_STATUS_COMPLETE_ERROR',

  FETCH_ORDER_ACCEPT_SUCCESS: 'FETCH_ORDER_ACCEPT_SUCCESS',
  FETCH_ORDER_ACCEPT_ERROR: 'FETCH_ORDER_ACCEPT_ERROR',

  FETCH_ORDER_REJECT_SUCCESS: 'FETCH_ORDER_REJECT_SUCCESS',
  FETCH_ORDER_REJECT_ERROR: 'FETCH_ORDER_REJECT_ERROR',

  SET_NEW_ORDERS_COUNT: 'SET_NEW_ORDERS_COUNT',
}
