import React, { useContext } from 'react'
import s, { ThemeContext } from 'styled-components'
import cn from 'classnames'

const widths = {
  small: '502px',
  normalB: '660px',
  normal: '606px',
  smallM: '550px',
  confirm: '406px',
  medium: '800px',
  full: '100vw',
  auto: 'auto',
  initial: 'initial',
}

const heights = {
  small: '500px',
  smallM: '550px',
  normal: '606px',
  medium: '800px',
  full: '100%',
  auto: 'auto',
  initial: 'initial',
}

const paddings = {
  none: 0,
  small: 6,
  normal: 12,
  medium: 14,
  big: 16,
  full: 0,
}

const commonStyles = ({
  theme,
  bgColor,
  color,
  width,
  height,
  noOverflow,
  p,
}) => `
width: ${width ? widths[width] : widths.normal};
padding: ${p ? paddings[p] : paddings.none}px;
background-color: ${theme.colors[bgColor] || theme.colors.white};
color: ${theme.colors[color] || theme.colors.black};
overflow: ${noOverflow ? 'unset' : 'hidden'};
height: ${height ? heights[height] : 'auto'};
pointer-events: initial;
transform: scaleX(1) scaleY(1);
transition: transform 0.15s ease-in;

&.--fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  width: 100%;
  height: 100%;
}

&.--under {
    transition: transform 0.3s linear 0.5s;
    overflow: initial;
    touch-action: none;
    position: relative;
    transform: scaleX(1.05) scaleY(0.96);
  }
`

const stylesDefault = ({
  flex,
  right,
  direction,
}) => `
  display: ${flex ? 'flex' : 'block'};
  flex-direction: ${flex && direction ? direction : 'row'};
  align-self: center;
  ${right ? 'margin-left: auto' : ''}
`

const stylesSidebar = ({ theme }) => `
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-left: auto;
  border-top-left-radius: ${theme.radius.normal};
  border-bottom-left-radius: ${theme.radius.normal};
`

const stylesCenter = ({ theme }) => `
  display: flex;
  flex-direction: column;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  border-radius: ${theme.radius.normal};
  background-color: ${theme.colors.white};
`

const ModalContainer = s.div`
  ${(props) => (!props.center && !props.sidebar ? stylesDefault(props) : '')}
  ${(props) => (commonStyles(props))}
  ${(props) => (props.sidebar ? stylesSidebar(props) : '')}
  ${(props) => (props.center ? stylesCenter(props) : '')}
`

const Modal = ({
  className,
  children,
  width = 'full',
  height = 'full',
  fixedTop = false,
  noOverflow = false,
  isUnder = false,
  ...otherProps
}) => {
  const theme = useContext(ThemeContext)
  const classes = cn('w-modal', {
    '--fixed-top': fixedTop,
    '--under': isUnder,
  }, className)
  const props = {
    className: classes,
    theme,
    width,
    height,
    noOverflow,
    ...otherProps,
  }

  return (
    <ModalContainer {...props}>
      {children}
    </ModalContainer>
  )
}

export default Modal
