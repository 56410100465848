import React from 'react'
import s from 'styled-components'
import { Scrollbars } from 'react-custom-scrollbars'

import { colors } from 'theme'

const ScrollbarContainer = s(Scrollbars)`
.scroll-track {
  position: absolute;

  &.--vertical {
    top: 10px;
    right: ${({ right }) => right};
    bottom: 10px;
    width: 3px !important;
  }

  &.--horizontal {
    right: 10px;
    bottom: ${({ bottom }) => bottom};
    left: 10px;
    height: 3px !important;
  }
}

.scroll-thumb {
  background-color: yellow;
  background-color: ${colors.grey300};
  border-radius: 3px;

  &.--vertical {
    width: 100%;
  }

  &.--horizontal {
    height: 100%;
  }
}
`

const Scrollbar = ({
  children,
  width = '3px',
  top = '5px',
  bottom = '5px',
  right = '5px',
  autoHide = true,
  ...otherProps
}) => (
  <ScrollbarContainer
    width={width}
    top={top}
    bottom={bottom}
    right={right}
    autoHide={autoHide}
    renderTrackHorizontal={(props) => <div {...props} className="scroll-track --horizontal" />}
    renderTrackVertical={(props) => <div {...props} className="scroll-track --vertical" />}
    renderThumbHorizontal={(props) => <div {...props} className="scroll-thumb --horizontal" />}
    renderThumbVertical={(props) => <div {...props} className="scroll-thumb --vertical" />}
    renderView={(props) => <div {...props} className="view" />}
    {...otherProps}
  >
    {children}
  </ScrollbarContainer>
)

export default Scrollbar
