import React from 'react'
import cn from 'classnames'
import s from 'styled-components'

const Styles = () => `
  &.--ul {
    li:before {
      content: "";
      position: absolute;
      left: 0;
      top: 6px;
      display: inline-block;
      width: 12px;
      height: 12px;
      background-color: #0F737E;
      border-radius: 50%;
    }
  }

  &.--ol {
    counter-reset: section;

    li:before {
      content: counter(section);
      counter-increment: section;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      color: #0F737E;
      font-weight: bold;
    }
  }

  li {
    position: relative;
    padding-left: 30px;
  }

  li + li {
    margin-top: 30px;
  }
`

const TextListContainerUl = s.ul`
  ${(props) => Styles(props)}
`

const TextListContainerOl = s.ol`
  ${(props) => Styles(props)}
`
const TextList = ({
  children, tag, color, className, list, ...otherProps
}) => {
  const classes = cn(
    'ps-text-list',
    {
      [`--c-${color}`]: color,
      '--ol': tag === 'ol',
      '--ul': tag === 'ul' || !tag,
    },
    className
  )

  if (tag === 'ol') {
    return (
      <TextListContainerOl className={classes} {...otherProps}>
        {children
          || (list
            && list.length
            && list.map((item, idx) => <li key={idx}>{item}</li>))}
      </TextListContainerOl>
    )
  }
  return (
    <TextListContainerUl className={classes} {...otherProps}>
      {children
          || (list
            && list.length
            && list.map((item, idx) => <li key={idx}>{item}</li>))}
    </TextListContainerUl>
  )
}

export default TextList
