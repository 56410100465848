import React, { useMemo } from 'react'
import cn from 'classnames'
import s from 'styled-components'

import { Text } from 'components/typo'
import { ReactComponent as IconWarning } from 'assets/icons/messages/warning.svg'
import { ReactComponent as IconMessage } from 'assets/icons/messages/message.svg'
import { ReactComponent as IconCheck } from 'assets/icons/messages/check.svg'
import { ReactComponent as IconStar } from 'assets/icons/messages/star.svg'
import { ReactComponent as IconTrash } from 'assets/icons/messages/trash.svg'
import { ReactComponent as IconStop } from 'assets/icons/messages/stop.svg'
import { ReactComponent as IconOrder } from 'assets/icons/messages/order.svg'

import { messageTypes, messageTypesColors } from 'constants/NotifierTypes'

const NotifierMessageContainer = s.div`
  display: flex;

  .notifier-message {
    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      margin-right: 14px;
    }

    &-content {
      align-self: flex-start;
      padding-top: 2px;
    }

    &-descr {
      margin-top: 6px;
    }
  }
`

const NotifierMessage = (props) => {
  const {
    message,
    description,
    toastProps,
  } = props
  const { type } = toastProps

  const Icon = useMemo(() => {
    switch (type) {
    case messageTypes.ERROR:
      return IconWarning
    case messageTypes.MESSAGE:
      return IconMessage
    case messageTypes.CHECK:
      return IconCheck
    case messageTypes.TRASH:
      return IconTrash
    case messageTypes.STAR:
      return IconStar
    case messageTypes.STOP:
      return IconStop
    case messageTypes.ORDER:
      return IconOrder

    default:
      return null
    }
  }, [ type ])

  const titleColor = useMemo(() => {
    switch (type) {
    case messageTypes.ERROR:
      return messageTypesColors.ERROR

    default:
      return messageTypesColors.DEFAULT
    }
  }, [ type ])

  return (
    <NotifierMessageContainer
      className={cn('notifier-message', { [`--${toastProps.type}`]: toastProps && toastProps.type })}
    >
      {Icon && (
        <div className="notifier-message-icon">
          <Icon fill={titleColor} />
        </div>
      )}
      <div className="notifier-message-content">
        <div className="notifier-message-title">
          <Text color={titleColor} fw="m">{message}</Text>
        </div>

        {description && (
          <div className="notifier-message-descr">
            <Text color="grey500">{description}</Text>
          </div>
        )}
      </div>
    </NotifierMessageContainer>
  )
}

export const setMessage = (props) => <NotifierMessage {...props} />

export default NotifierMessage
