class StorageService {
  constructor() {
    this.storage = localStorage
    this.sessionStorage = sessionStorage
    this.token = 'w_token'
    this.uSession = 'w_usession'
    this.iPanel = 'w_panel'
  }

  /* storage common actions */
  setItem(name, value) {
    if (name) this.storage.setItem(name, value)
  }

  getItem(name) {
    return this.storage.getItem(name) || ''
  }

  removeItem(name) {
    this.storage.removeItem(name)
  }

  /* storage token actions */
  getToken() {
    return this.storage.getItem(this.token) || ''
  }

  setToken(token) {
    if (token) this.storage.setItem(this.token, token)
  }

  removeToken() {
    this.storage.removeItem(this.token)
  }

  /* user auth session */
  getUserSession() {
    return JSON.parse(this.storage.getItem(this.uSession)) || ''
  }

  setUserSession(uSession) {
    if (uSession) this.storage.setItem(this.uSession, JSON.stringify(uSession))
  }

  removeUserSession() {
    this.storage.removeItem(this.uSession)
  }

  /* interface panel toggle */
  getInterfacePanelIsCollapsed() {
    return JSON.parse(this.storage.getItem(this.iPanel)) || false
  }

  setInterfacePanelIsCollapsed(isCollapsed) {
    this.storage.setItem(this.iPanel, JSON.stringify(isCollapsed))
  }

  removeInterfacePanelIsCollapsed() {
    this.storage.removeItem(this.iPanel)
  }
}

export default new StorageService()
