import { MailingsActionTypes } from 'redux/types/MailingsTypes'
import { UserActionTypes } from 'redux/types/UserTypes'

const INITIAL_STATE = {
  name: 'mailings',
  homeList: [],
  homeListIsEmpty: false,
  isFetching: true,
  isSubmitting: false,
}

const mailingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE }

  case MailingsActionTypes.SET_FETCHING_MAILINGS:
    return {
      ...state,
      isFetching: action.payload,
    }

  case MailingsActionTypes.FETCH_MAILINGS_HOME_SUCCESS:
    return {
      ...state,
      homeList: action.payload,
    }

  case MailingsActionTypes.SET_MAILINGS_HOME_IS_EMPTY:
    return {
      ...state,
      homeListIsEmpty: action.payload,
    }

  case MailingsActionTypes.CLEAR_MAILINGS_HOME:
    return {
      ...state,
      homeList: [],
      homeListIsEmpty: false,
    }

  case MailingsActionTypes.SET_FETCHING_MAILING_SUBMITTING:
    return {
      ...state,
      isSubmitting: action.payload,
    }

  case MailingsActionTypes.FETCH_MAILING_CREATE_SUCCESS:
    return {
      ...state,
      homeList: [ ...state.homeList, action.payload ],
      homeListIsEmpty: false,
    }

  case MailingsActionTypes.FETCH_MAILING_UPDATE_SUCCESS: {
    const isExistIdx = state.homeList.findIndex((c) => c.id === action.payload.id)

    if (isExistIdx >= 0) {
      return {
        ...state,
        homeList: [
          ...state.homeList.slice(0, isExistIdx),
          action.payload,
          ...state.homeList.slice(isExistIdx + 1),
        ],
      }
    }

    return state
  }

  case MailingsActionTypes.FETCH_MAILING_DELETE_SUCCESS: {
    const newMailings = [ ...state.homeList.filter((c) => c.id !== action.payload) ]

    return {
      ...state,
      homeList: newMailings,
      homeListIsEmpty: newMailings.length === 0,
    }
  }

  default:
    return state
  }
}

export default mailingsReducer
