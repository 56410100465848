import React, { useMemo } from 'react'
import s from 'styled-components'
import cn from 'classnames'

const IconBlockContainer = s.div`
  display: block;
  width: 46px;
  height: 46px;
  border-color:  ${({ theme, color }) => (color.length ? theme.colors.grey400 : theme.colors.transparent)};
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
`

const IconBlock = ({
  color = '',
  className = '',
  ...otherProps
}) => {
  const classes = cn('color-block', className)
  const props = {
    className: classes,
    ...otherProps,
  }

  const mappedColor = useMemo(() => {
    if (!color.length) return ''
    const c = color[0] === '#' ? color : `#${color}`

    return c.length !== 4 && c.length !== 7 ? '' : c
  }, [ color ])

  return (
    <IconBlockContainer
      style={{ backgroundColor: mappedColor, }}
      color={mappedColor}
      {...props}
    />
  )
}

export default IconBlock
