import React from 'react'
import s from 'styled-components'
import cn from 'classnames'

import Title from 'components/typo/Title'
import Text from 'components/typo/Text'
import Card from 'components/cards/Card'
import LazyImage from 'components/cards/LazyImage'
import Box from 'layout/Box'
import Formatted from 'components/typo/Formatted'

const getStyles = () => `
  width: 100%;
  overflow: hidden;

  &.--full-h {
    height: 100%;
  }

  .head {
    height: 238px;
    padding: 64px 46px 10px;
    width: 100%;

    @media (max-width: 768px) {
      padding: 32px 24px 10px;
      height: 133px;
    }

    &.--img {
      padding: 0;
    }
  }

  .body {
    padding: 46px;
    padding-top: 24px;

    @media (max-width: 768px) {
      padding: 24px;
      padding-top: 16px;
    }
  }

  .title {
    margin-bottom: 24px;

    @media (max-width: 768px) {
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 1.6;
    }
  }

  .icons {
    & > * {
      margin-right: 23px;

      @media (max-width: 768px) {
        margin-right: 16px;
      }

      &:last-child {
        margin-right: 0 !important;
      }
    }

    svg {
      width: 132px;
      height: 132px;

      @media (max-width: 768px) {
        width: 80px;
        height: 80px;
      }
    }
  }

  .c-description {
    max-width: 480px;
  }
`

const CardServiceContainer = s(Card)`
  ${getStyles}
`

const CardService = ({
  bgColor = 'grey100',
  title = '',
  img = null,
  isMobile = false,
  icons = null,
  description,
  fullHeight = false,
}) => (
  <CardServiceContainer
    bg={bgColor}
    className={cn('c-service', {
      '--mob': isMobile,
      '--full-h': fullHeight,
    })}
    br="xl"
  >
    <div className={cn('head', { '--img': img })}>
      {img && (
        <LazyImage
          br="0px"
          width="100%"
          height="100%"
          objectFit="cover"
          src={img.src}
          srcSet={img.srcSet}
        />
      )}

      {icons && (
        <Box className="icons">
          {icons.map(({ icon: Icon }, idx) => (
            <div key={idx}>
              <Icon />
            </div>
          ))}
        </Box>
      )}
    </div>
    <div className="body">
      <Title
        fw="n"
        tag="h3"
        tagStyle="h-2"
        className="title"
      >
        <Formatted id={title} />
      </Title>
      <Text className="c-description" color="grey900">
        <Formatted id={description} />
      </Text>
    </div>
  </CardServiceContainer>
)

export default CardService
