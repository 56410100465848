/* eslint-disable import/no-cycle */
import React, { forwardRef } from 'react'
import s from 'styled-components'
import cn from 'classnames'
import { Styles } from 'components/form/Input'
import InputMask from 'react-input-mask'

const FormFieldMaskedContainer = s(InputMask)`
  ${Styles}
  background-color: unset;
  border: unset;

  &:focus {
    color: unset;
    background-color: unset;
    border-color: unset;
  }

  &:hover {
    border-color: unset;
  }
`

const FormFieldMasked = forwardRef(({
  type = 'text',
  mask = '9 9 9 9',
  defaultStyle,
  ...otherProps
}, ref) => (
  <FormFieldMaskedContainer
    type={type}
    mask={mask}
    ref={ref}
    alwaysShowMask
    className={cn({ [`--${defaultStyle}`]: defaultStyle })}
    {...otherProps}
  />
))

FormFieldMasked.displayName = 'FormFieldMasked'

export default FormFieldMasked
