// SYSTEM
export const SERVER_URL = process.env.REACT_APP_HOST
export const APP_IMAGES = '/assets/images'
export const APP_API_FILES = `${SERVER_URL}/media`

/** *************  COMMON ************** */
export const HOME = '/'
export const SERVICES = '/services'
export const ERROR_PAGE = '/error'
export const PARTNERS = '/partners'
export const HELP_CENTER = '/help-center'
export const POLITIC = '/privacy-policy'
export const LICENSE = '/license-agreement'
export const SITE_LABEL = 'wiby.io'
export const PARTNERSHIP_LINK = 'https://join.bonplace.com/wiby/'
export const SITE = `https://${SITE_LABEL}`
export const PARTNERSHIP_EMAIL = 'partnership@wiby.io'

// SOCIALS
export const WIBY_PHONE = '+971 52 239 6866'
export const WIBY_EMAIL = 'info@wiby.io'
export const TELEGRAM = 'https://t.me/wibyio'
export const WHATSUP = 'https://wa.me/79851787296'

// AUTH
export const AUTH = '/auth'
export const UI = '/ui'
export const LOGIN = `${AUTH}/login`
export const REGISTRATION = `${AUTH}/registration`
export const RESET_PASSWORD = `${AUTH}/reset-password`
export const RESET_PASSWORD_SUBMIT = `${AUTH}/reset-password-submit`
export const SET_NEW_PASSWORD = `${AUTH}/new-password`
export const PHONE_ACTIVATION = `${AUTH}/activation/phone/`

/** *************  INTERFACE MAIN ROUTES ************** */
export const ORDERS = 'orders'
export const CATALOG = 'catalog'
export const MAILINGS = 'mailings'
export const CLIENTS = 'clients'
export const SETTINGS = 'settings'
export const ANALITICS = 'analitics'
export const INTEGRATIONS = 'integrations'

/** ****************  CATALOG PAGES  ******************* */
export const CATEGORIES = 'categories'
export const CATEGORIES_ADD = `${CATEGORIES}/add`
export const CATEGORIES_EDIT = `${CATEGORIES}/edit/:categoryId`

export const PRODUCTS = 'products'
export const PRODUCTS_ADD = `${PRODUCTS}/add`
export const PRODUCTS_EDIT = `${PRODUCTS}/edit/:productId`

export const ADDITIONS = 'additions'
export const ADDITIONS_ADD = `${ADDITIONS}/add`
export const ADDITIONS_EDIT = `${ADDITIONS}/edit/:additionId`

export const BANNERS = 'banners'
export const BANNERS_ADD = `${BANNERS}/add`
export const BANNERS_EDIT = `${BANNERS}/edit/:bannerId`

/** ****************  SETTINGS PAGES  ******************* */

export const SETTINGS_MAIN = 'main'

export const SETTINGS_LOCATIONS = 'locations'
export const SETTINGS_LOCATIONS_ADD = `${SETTINGS_LOCATIONS}/add`
export const SETTINGS_LOCATIONS_EDIT = `${SETTINGS_LOCATIONS}/edit/:locationId`

export const SETTINGS_PAYMENT = 'payments'
export const SETTINGS_DESIGN = 'design'
export const SETTINGS_ICONS = 'icons'
export const SETTINGS_ACCOUNT = 'account'

export const INTERFACE = '/app'

/** ****************  CATALOG  ******************* */
export const INTERFACE_CATALOG = `${INTERFACE}/${CATALOG}`

export const INTERFACE_CATALOG_CATEGORIES = `${INTERFACE_CATALOG}/${CATEGORIES}`
export const INTERFACE_CATALOG_CATEGORIES_ADD = `${INTERFACE_CATALOG}/${CATEGORIES_ADD}`
export const INTERFACE_CATALOG_CATEGORIES_EDIT = `${INTERFACE_CATALOG}/${CATEGORIES_EDIT}`

export const INTERFACE_CATALOG_PRODUCTS = `${INTERFACE_CATALOG}/${PRODUCTS}`
export const INTERFACE_CATALOG_PRODUCTS_ADD = `${INTERFACE_CATALOG}/${PRODUCTS_ADD}`
export const INTERFACE_CATALOG_PRODUCTS_EDIT = `${INTERFACE_CATALOG}/${PRODUCTS_EDIT}`

export const INTERFACE_CATALOG_ADDITIONS = `${INTERFACE_CATALOG}/${ADDITIONS}`
export const INTERFACE_CATALOG_ADDITIONS_ADD = `${INTERFACE_CATALOG}/${ADDITIONS_ADD}`
export const INTERFACE_CATALOG_ADDITIONS_EDIT = `${INTERFACE_CATALOG}/${ADDITIONS_EDIT}`

export const INTERFACE_CATALOG_BANNERS = `${INTERFACE_CATALOG}/${BANNERS}`
export const INTERFACE_CATALOG_BANNERS_ADD = `${INTERFACE_CATALOG}/${BANNERS_ADD}`
export const INTERFACE_CATALOG_BANNERS_EDIT = `${INTERFACE_CATALOG}/${BANNERS_EDIT}`

/** ****************  SETTINGS  ******************* */
export const INTERFACE_SETTINGS = `${INTERFACE}/${SETTINGS}`

export const INTERFACE_SETTINGS_MAIN = `${INTERFACE_SETTINGS}/${SETTINGS_MAIN}`

export const INTERFACE_SETTINGS_LOCATIONS = `${INTERFACE_SETTINGS}/${SETTINGS_LOCATIONS}`
export const INTERFACE_SETTINGS_LOCATIONS_ADD = `${INTERFACE_SETTINGS}/${SETTINGS_LOCATIONS_ADD}`
export const INTERFACE_SETTINGS_LOCATIONS_EDIT = `${INTERFACE_SETTINGS}/${SETTINGS_LOCATIONS_EDIT}`

export const INTERFACE_SETTINGS_PAYMENTS = `${INTERFACE_SETTINGS}/${SETTINGS_PAYMENT}`
export const INTERFACE_SETTINGS_DESIGN = `${INTERFACE_SETTINGS}/${SETTINGS_DESIGN}`
export const INTERFACE_SETTINGS_ICONS = `${INTERFACE_SETTINGS}/${SETTINGS_ICONS}`
export const INTERFACE_SETTINGS_ACCOUNT = `${INTERFACE_SETTINGS}/${SETTINGS_ACCOUNT}`

/** ****************  OTHERS  ******************* */
export const INTERFACE_CLIENTS = `${INTERFACE}/${CLIENTS}`
export const INTERFACE_ANALITICS = `${INTERFACE}/${ANALITICS}`
export const INTERFACE_INTEGRATIONS = `${INTERFACE}/${INTEGRATIONS}`
export const INTERFACE_ORDERS = `${INTERFACE}/${ORDERS}`
export const INTERFACE_MAILINGS = `${INTERFACE}/${MAILINGS}`
