import React from 'react'
import s from 'styled-components'

import { Spinner } from 'components/base'

const InterfacePreloaderAutosaveContainer = s.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1001;
  padding: 10px;
  background-color: ${(
    {
      theme,
      bgColor,
      transparent,
    }
  ) => theme.colors[transparent ? 'transparent' : bgColor]};
  border-radius: 10px;
  box-shadow: ${({ transparent }) => (transparent ? 'unset' : '4px 10px 25px rgba(113, 128, 150, 0.12)')};
  backdrop-filter: ${({ transparent }) => (transparent ? 'unset' : 'blur(5px)')};
`

const InterfacePreloaderAutosave = ({ bgColor = 'whitet40', transparent = true }) => (
  <InterfacePreloaderAutosaveContainer
    bgColor={bgColor}
    transparent={transparent}
    className="autosave-loader"
  >
    <Spinner
      relative
      defaultStyle="grey"
    />
  </InterfacePreloaderAutosaveContainer>
)

export default InterfacePreloaderAutosave
