import React from 'react'
import s from 'styled-components'
import cn from 'classnames'

const CardActionContainer = s.div`
  width: 44px;
  height: 44px;
  padding: 10px;
  cursor: pointer;

  &.--s {
    &-medium {
      width: 24px;
      height: 24px;
    }
  }

  &:hover {
    svg {
      fill: #0f737e;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    fill: #979797;
  }
`

const CardAction = ({
  children, size, ...otherProps
}) => (
  <>
    <CardActionContainer
      {...otherProps}
      classNmae={cn({ [`--s-${size}`]: size })}
    >
      {children}
    </CardActionContainer>
  </>
)

export default CardAction
