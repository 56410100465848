import React from 'react'

import { ButtonRound } from 'components/base'
import { ReactComponent as Icon } from './icons/clear.svg'

const ButtonRemove = ({
  size = 'xs',
  defaultStyle = 'light',
}) => (
  <ButtonRound
    defaultStyle={defaultStyle}
    size={size}
    renderIcon={() => <Icon />}
  />
)

export default ButtonRemove
