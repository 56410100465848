import React from 'react'
import s from 'styled-components'
import cn from 'classnames'

import placeholderImage from 'assets/images/common/image-placeholder.jpg'

const CardBgImageContainer = s.div`
  width: ${({ width }) => width || 'initial'};
  height: ${({ height }) => height || 'initial'};
  background: no-repeat center;

  &.--size {
    &-full {
      width: 100%;
      height: 100%;
    }
  }

  &.--round {
    border-radius: 50%;
  }

  &.--fit {
    &-contain {
      background-size: contain;
    }

    &-cover {
      background-size: cover;
    }
  }
`

const CardBgImage = ({
  imgSrc,
  size = 'full',
  fit = 'cover',
  className,
  round,
  ...props
}) => {
  const renderImage = () => {
    if (!imgSrc) return placeholderImage
    return `${process.env.REACT_APP_FILE_URL}${imgSrc}`
  }

  return (
    <CardBgImageContainer
      {...props}
      className={cn(
        {
          [`--size-${size}`]: size && !props.height && !props.width,
          [`--fit-${fit}`]: fit,
          '--round': round,
        },
        className
      )}
      style={{ backgroundImage: `url(${renderImage()})`, }}
    />
  )
}

export default CardBgImage
