import axios from 'axios'

import * as PathTypes from 'constants/PathsTypes'
import StorageService from 'services/StorageService'
import history from 'history/configureHistory'
import store from 'redux/store/configureStore'

import {
  PRODUCTS,
  // CATALOG,
  CATEGORIES,
  ADDITIONS,
  BANNERS,
  ORDERS,
} from 'constants/PathsTypes'

// redux
import { setNotificationError } from 'redux/actions/SystemActions'

const LOCATIONS = 'trade_points'

const errorBeautifier = (error) => (error.hasOwnProperty('payload')
  ? error.payload.data.error
  : {
    name: 'Error',
    message: error.message,
    status: error.message.status,
  })

class ApiService {
  constructor() {
    this.client = axios.create({
      baseURL: `${process.env.REACT_APP_HOST}/rest/`,
      timeout: 60 * 1000, // 1 min
    })
    this.paths = {
      auth: {
        REGISTRATION: '/companies/',
        GET_TOKEN: '/accounts/get_token/',
        LOGIN: '/accounts/users/me/',
        // LOGOUT: '/accounts/users/me/',
        EMAIL_CONFIRM: (uid) => `/accounts/email_verify_orders/${uid}/activate/`,
        CHANGE_PASSWORD: `/accounts/users/change_password/`,
        GET_FIREBASE_AUTH_TOKEN: `/accounts/users/firebase_auth/`,
      },
      user: {
        REGISTRATION: '/auth/registration/',
        LOGIN: '/accounts/users/me/',
        LOGOUT: '/auth/logout/',
        RESET_PASSWORD: '/auth/reset/phone/',
        PHONE_ACTIVATION: '/auth/activation/phone/',
        RESET_PASSWORD_SUBMIT: '/auth/reset/phone/submit/',
        SET_NEW_PASSWORD: '/auth/set/password/',
        CHANGE_PASSWORD: '/auth/change/password/',
        CHANGE_AVATAR: '/auth/users/change_avatar/',
        SUBSCRIPTIONS_BUY: '/user_subscriptions/buy_yandex_kassa/',
        NOTIFICATIONS: '/user_notify/',
      },
      categories: {
        ROOT: `${CATEGORIES}/`,
        SWITCH_ACTIVE: (id) => `${CATEGORIES}/${id}/switch_active/`,
      },
      products: {
        ROOT: `${PRODUCTS}/`,
        SWITCH_ACTIVE: (id) => `${PRODUCTS}/${id}/switch_active/`,
      },
      additions: {
        ROOT: `${ADDITIONS}/`,
        SWITCH_ACTIVE: (id) => `${ADDITIONS}/${id}/switch_active/`,
      },
      banners: {
        ROOT: `${BANNERS}/`,
        SWITCH_ACTIVE: (id) => `${BANNERS}/${id}/switch_active/`,
      },
      locations: {
        ROOT: `${LOCATIONS}/`,
        SWITCH_ACTIVE: (id) => `${LOCATIONS}/${id}/switch_active/`,
      },
      demos: { ROOT: '/demo_cases/' },
      mailings: {
        ROOT: `/push_group_orders/`,
        SWITCH_ACTIVE: (id) => `${LOCATIONS}/${id}/switch_active/`,
      },
      orders: {
        ROOT: `${ORDERS}/`,
        ACCEPT: (id) => `${ORDERS}/${id}/accept_company/`,
        REJECT: (id) => `${ORDERS}/${id}/reject_company/`,
        COMPLETE: (id) => `${ORDERS}/${id}/complete/`,
        READY: (id) => `${ORDERS}/${id}/ready/`,
      },
      clients: { ROOT: `/company_clients/` },
      form: { REQUEST: `/form_orders/` },
      subscriptions: {
        ROOT: '/rates/',
        BUY: '/company_rates/buy/',
      },
      company: {
        SCHEDULE: '/schedule_elems/',
        UPDATE: '/companies/edit/',
        LINKS: '/company_links/',
        PAYMENTS: '/company_payment_settings/',
        PAYMENTS_METHOD_TOGGLE: (id) => `/company_payment_settings/${id}/switch_active/`,
      },
      app: {
        UPDATE: '/company_apps/edit/',
        THEMES: '/app_themes/',
        MENU_OPTIONS: '/app_menu_options/',
        MENU_OPTION_TOGGLE: (option) => `app_menu_options/${option}/switch_active/`,
        SEND_ON_MODERATION: '/company_apps/send_on_moderation/',
        CHECK_MODERATION_LIST: '/company_apps/moderation_checklist/',
        PUBLISH: '/company_apps/publish/',
      },
      devices: {
        ROOT: '/device/',
        GET: '/device_types/',
      },
      screenshots: {
        ROOT: '/company_apps_screenshots/',
        DELETE_BY_TYPE_ID: (id) => `/device_types/${id}/clear_app_content/`,
      },
      countries: { ROOT: '/countries/' },
    }
  }

  apiCall({
    url = '',
    method = 'GET',
    token = false,
    isToken,
    ...otherParams
  }) {
    let options = {
      url,
      method,
      headers: this.buildHeaders(token, isToken),
    }

    if (otherParams) {
      options = {
        ...options,
        ...otherParams,
      }
    }

    return this.client(options)
      .then(this.handleCommonSuccess)
      .catch(this.handleCommonError)
  }

  buildHeaders(token, isToken) {
    const headers = {
      'Content-type': 'application/json',
      Accept: `application/json; version=${process.env.REACT_APP_API_VERSION}`,
    }

    if (token || isToken) headers.Authorization = `Bearer ${isToken ? StorageService.getToken() : token}`

    return headers
  }

  handleCommonSuccess(response) {
    return Promise.resolve(response.data)
  }

  handleCommonError(error) {
    const { dispatch } = store

    if (error && error.response) {
      const { data } = error.response

      if (error.response.status === 401) {
        StorageService.removeToken()
        StorageService.removeUserSession()
        history.push(PathTypes.HOME, { setModal: { name: 'login' } })
      }

      dispatch(setNotificationError(data))
    }

    // FOR DEBUG ONLY
    console.info('DEBUG ERROR START')
    console.warn('MESSAGE:', error && error.message)
    console.info('DEBUG ERROR END', error && error.message)

    return Promise.reject(
      error && error.response ? error.response : errorBeautifier(error)
    )
  }
}

export default new ApiService()
