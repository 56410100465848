import React from 'react'
import s from 'styled-components'

import Container from 'layout/Container'

const HeaderContainer = s.header`
  position: relative;
  z-index: 999;
  height: 84px;
  background: ${({ theme }) => theme.colors.whitet90};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey200}`};
  transform: translateY(0);
  backdrop-filter: blur(50px);
  transition: transform ease 0.3s, border-color 0.3s;

  @media (max-width: 768px) {
    height: 68px;
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &.--fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
  }

  .header-nav {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const PageHeader = ({ children }) => (
  <HeaderContainer className="page-header">
    <Container fullHeight>
      <div className="inner">{children}</div>
    </Container>
  </HeaderContainer>
)

export default PageHeader
