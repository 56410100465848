import React, { useMemo } from 'react'

// components
import { Box } from 'layout'
import {
  Modal,
  ModalBanner,
  ModalContent,
  ModalActions,
  ModalContentWrapper,
} from 'components/modal'
import FormRequest from 'components/form/FormRequest'

// images
import { ReactComponent as IconStarMedium } from 'assets/icons/multy/star-modal-green.svg'
import bgImage from 'assets/images/modals/help-bg.jpg'

import { formOrderTypes } from 'constants/FormOrderTypes'
import ModalSidebarBannerHelp from './ModalSidebarBannerHelp'
import { useIntl } from '../../../node_modules/react-intl/index'

const ModalOrderSubscriptions = ({
  closeModal,
  modal,
  isLaptopBigOrMobile,
  isLaptopOrMobile,
}) => {
  const { formatMessage } = useIntl()

  const modalData = useMemo(() => {
    const goOn = formatMessage({ id: 'button.go' })

    if (modal && modal.data && modal.data.id) {
      return {
        title: `${goOn} «${modal.data.title}»`,
        icon: IconStarMedium,
      }
    }

    return {
      title: '',
      icon: null,
    }
  }, [ modal, formatMessage ])

  const Icon = modalData.icon || null

  return (
    <Modal flex size="full">
      <ModalActions closeModal={closeModal} />
      {!isLaptopBigOrMobile && (
        <ModalBanner
          center
          img={bgImage}
          title="modals.banner.help.title"
          descriptionHtml={<ModalSidebarBannerHelp />}
        />
      )}
      <ModalContentWrapper>
        <ModalContent p="withSidebar">
          <Box
            height="100%"
            jc="center"
            ai="center"
            flex
            direction="column"
          >
            {Icon && (
              <Box className={isLaptopOrMobile ? 'mb-3' : 'mb-30'}>
                <Icon width={55} height={49} />
              </Box>
            )}
            <Box self="stretch">
              <FormRequest
                type={formOrderTypes.BUSINESS}
                title={modalData.title}
              />
            </Box>
          </Box>
        </ModalContent>
      </ModalContentWrapper>
    </Modal>
  )
}

export default ModalOrderSubscriptions
