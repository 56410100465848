import React from 'react'
import { FormattedMessage } from 'react-intl'

const Formatted = ({
  id = '',
  values = {},
}) => (
  <FormattedMessage id={id} values={values} />
)

export default Formatted
