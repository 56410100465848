import React from 'react'
import s from 'styled-components'

const CardActionsContainer = s.div`
  display: flex;
  margin: -10px;
`

const CardActions = ({ children, ...otherProps }) => (
  <>
    <CardActionsContainer {...otherProps}>{children}</CardActionsContainer>
  </>
)

export default CardActions
