import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Field } from 'formik'
import { injectIntl } from 'react-intl'

// components
import { Button } from 'components/base'
import { Box } from 'layout'
import { Title } from 'components/typo'

import {
  Form,
  FormField,
  FormGroup,
} from 'components/form'

// redux
import {
  fetchChangePassword,
  fetchGetFirebaseAuthToken,
  fetchLogin,
} from 'redux/actions/AuthActions'
import { setModal } from 'redux/actions/SystemActions'

// constants
import { SchemaChangePassword } from 'constants/ValidationSchemas'
import * as PathsTypes from 'constants/PathsTypes'

// images
import { ReactComponent as Icon } from 'assets/icons/multy/key-new.svg'
import Formatted from 'components/typo/Formatted'

const ChangePassword = ({ intl }) => {
  const dispatch = useDispatch()
  const { modal } = useSelector((state) => state.system)
  const { profile } = useSelector((state) => state.user)
  const history = useHistory()

  const placeholderConfirm = intl.formatMessage({ id: 'form.input.confirm_password' })
  const placeholderNew = intl.formatMessage({ id: 'form.input.new_password' })

  const handleSubmit = (values, { setSubmitting }) => {
    const data = {
      password: values.password,
      password_2: values.passwordConfirm,
    }

    if (profile) {
      dispatch(fetchChangePassword(data))
        .then(() => {
          setSubmitting(false)
          dispatch(setModal({ close: true }))
        })
        .finally(() => setSubmitting(false))
    }
    else {
      dispatch(fetchGetFirebaseAuthToken({ firebase_token: modal.data && modal.data.firebase_token }))
        .then((token) => {
          dispatch(fetchChangePassword(data, token))
            .then(({ token }) => {
              dispatch(fetchLogin(token))
                .then(() => {
                  setSubmitting(false)
                  if (modal.name) dispatch(setModal({ close: true }))
                  history.push(PathsTypes.INTERFACE)
                })
                .catch(() => setSubmitting(false))
            })
            .catch(() => setSubmitting(false))
        })
        .catch(() => setSubmitting(false))
    }
  }

  return (
    <Formik
      initialValues={{
        password: '',
        passwordConfirm: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={SchemaChangePassword}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          isSubmitting,
        } = props

        const attrs = {
          onChange: handleChange,
          onBlur: handleBlur,
          errors,
          touched,
          size: 'big',
        }

        const formIsValid
          = isValid && dirty

        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Box flex jc="center" className="mb-6">
              <Icon width={55} height={52.5} />
            </Box>
            <Box flex jc="center" className="mb-5">
              <Title tag="h2">
                <Formatted id="modals.new_password.title" />
              </Title>
            </Box>
            <FormGroup>
              <Field name="password">
                {() => (
                  <FormField
                    {...attrs}
                    autoFocus
                    name="password"
                    placeholder={placeholderNew}
                    type="password"
                    value={values.password}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup>
              <Field>
                {() => (
                  <FormField
                    {...attrs}
                    name="passwordConfirm"
                    placeholder={placeholderConfirm}
                    type="password"
                    value={values.passwordConfirm}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup>
              <Button
                defaultStyle="acsent"
                type="submit"
                size="big"
                full
                titleId="button.save"
                isFetching={isSubmitting}
                isDisabled={isSubmitting || !formIsValid}
              />
            </FormGroup>
          </Form>
        )
      }}
    </Formik>
  )
}

export default injectIntl(ChangePassword)
