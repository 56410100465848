export const AuthActionTypes = {
  SET_PHONE: 'SET_PHONE',
  SET_REGISTRATION_FORM: 'SET_REGISTRATION_FORM',
  SET_PHONE_CONFIRMATION_FORM: 'SET_PHONE_CONFIRMATION_FORM',

  SET_FETCHING_GET_AUTH_TOKEN: 'SET_FETCHING_GET_AUTH_TOKEN',
  FETCH_GET_AUTH_TOKEN_SUCCESS: 'FETCH_GET_AUTH_TOKEN_SUCCESS',

  SET_FETCHING_LOGIN: 'SET_FETCHING_LOGIN',
  FETCH_LOGIN_SUCCESS: 'FETCH_LOGIN_SUCCESS',

  SET_FETCHING_LOGOUT: 'SET_FETCHING_LOGOUT',
  FETCH_LOGOUT_SUCCESS: 'FETCH_LOGOUT_SUCCESS',

  SET_FETCHING_REGISTRATION: 'SET_FETCHING_REGISTRATION',
  FETCH_REGISTRATION_SUCCESS: 'FETCH_REGISTRATION_SUCCESS',

  SET_FETCHING_CHANGE_PASSWORD: 'SET_FETCHING_CHANGE_PASSWORD',
  FETCH_CHANGE_PASSWORD: 'FETCH_CHANGE_PASSWORD',

  SET_FETCHING_EMAIL_CONFIRM: 'SET_FETCHING_EMAIL_CONFIRM',
  FETCH_EMAIL_CONFIRM: 'FETCH_EMAIL_CONFIRM',
}
