import React from 'react'

import Container from 'layout/Container'
import Section from 'layout/Section'

import ScrollList from './ScrollList'
import SectionTitle from './SectionTitle'
import CardAdvantage from './CardAdvantage'

const LIST = [
  {
    label: {
      color: 'orange',
      text: 'home.metrics.1.label',
    },
    text: 'home.metrics.1',
  },
  {
    label: {
      color: 'purple',
      text: 'home.metrics.2.label',
    },
    text: 'home.metrics.2',
  },
  {
    label: {
      color: 'success',
      text: 'home.metrics.3.label',
    },
    texts: [ 'home.metrics.3.1', 'home.metrics.3.2' ],
  },
]

const SectionMetrics = () => (
  <Section>
    <Container>
      <SectionTitle titleId="home.heading.metric" />
      <ScrollList
        items={LIST}
        isFullHeight
        renderCard={(props) => <CardAdvantage bgColor="grey100" {...props} />}
      />
    </Container>
  </Section>
)

export default SectionMetrics
