export const WAYPOINT_TOGGLE_SELECTOR = '.w-toggler' // trigger elements selector
const WAYPOINT_TOGGLE_CLASS = WAYPOINT_TOGGLE_SELECTOR.slice(1) // trigger elements class

export const ANIMATION_FADE_OUT_FROM_RIGHT_CLASS = 'a-ffr' // fade to right
export const INIT_ANIMATION_FADE_OUT_FROM_RIGHT_CLASS = 'a-init-ffr' // fade to left init state
export const EL_ANIMATE_FFR = `${WAYPOINT_TOGGLE_CLASS} ${INIT_ANIMATION_FADE_OUT_FROM_RIGHT_CLASS}`

export const ANIMATION_FADE_OUT_FROM_LEFT_CLASS = 'a-ffl' // fade to left
export const INIT_ANIMATION_FADE_OUT_FROM_LEFT_CLASS = 'a-init-ffl' // fade to left state
export const EL_ANIMATE_FFL = `${WAYPOINT_TOGGLE_CLASS} ${INIT_ANIMATION_FADE_OUT_FROM_LEFT_CLASS}`

export const ANIMATION_FADE_OUT_FROM_BOTTOM_CLASS = 'a-ffb' // fade to bottom
export const INIT_ANIMATION_FADE_OUT_FROM_BOTTOM_CLASS = 'a-init-ffb' // fade to bottom state
export const EL_ANIMATE_FFB = `${WAYPOINT_TOGGLE_CLASS} ${INIT_ANIMATION_FADE_OUT_FROM_BOTTOM_CLASS}`

export const ANIMATION_FADE_OUT_FROM_TOP_CLASS = 'a-fft' // fade to top
export const INIT_ANIMATION_FADE_OUT_FROM_TOP_CLASS = 'a-init-fft' // fade to top state
export const EL_ANIMATE_FFT = `${WAYPOINT_TOGGLE_CLASS} ${INIT_ANIMATION_FADE_OUT_FROM_TOP_CLASS}`

export const INIT_ANIMATION_ADS_CLASS = 'a-init-ads' // ads init class

export const ANIMATION_ADS_4_CLASS = 'a-ads4' // fade to top
export const INIT_ANIMATION_ADS_4_CLASS = 'a-init-ads4' // fade to top state
export const EL_ANIMATE_ADS_4 = `${WAYPOINT_TOGGLE_CLASS} ${INIT_ANIMATION_ADS_CLASS} ${INIT_ANIMATION_ADS_4_CLASS}`

export const ANIMATION_DELIVERY_CLASS = 'a-delivery' // fade to top
export const INIT_ANIMATION_DELIVERY_CLASS = 'a-init-delivery' // fade to top state
export const EL_ANIMATE_DELIVERY = `${WAYPOINT_TOGGLE_CLASS} ${INIT_ANIMATION_ADS_CLASS} ${INIT_ANIMATION_DELIVERY_CLASS}`

export const ANIMATION_ADS_LK_CLASS = 'a-adslk' // fade to top
export const INIT_ANIMATION_ADS_LK_CLASS = 'a-init-adslk' // fade to top state
export const EL_ANIMATE_ADS_LK = `${WAYPOINT_TOGGLE_CLASS} ${INIT_ANIMATION_ADS_CLASS} ${INIT_ANIMATION_ADS_LK_CLASS}`

export const ANIMATION_ADS_CENTER_LEFT_CLASS = 'a-adscl' // fade to top
export const INIT_ANIMATION_ADS_CENTER_LEFT_CLASS = 'a-init-adscl' // fade to top state
export const EL_ANIMATE_ADS_CENTER_LEFT = `${WAYPOINT_TOGGLE_CLASS} ${INIT_ANIMATION_ADS_CLASS} ${INIT_ANIMATION_ADS_CENTER_LEFT_CLASS}`

export const ANIMATION_ADS_CENTER_RIGHT_CLASS = 'a-adscr' // fade to top
export const INIT_ANIMATION_ADS_CENTER_RIGHT_CLASS = 'a-init-adscr' // fade to top state
export const EL_ANIMATE_ADS_CENTER_RIGHT = `${WAYPOINT_TOGGLE_CLASS} ${INIT_ANIMATION_ADS_CLASS} ${INIT_ANIMATION_ADS_CENTER_RIGHT_CLASS}`

export function toggleWaypointAnimation(el) {
  const { target } = el
  const { classList } = target

  if (classList.contains(INIT_ANIMATION_FADE_OUT_FROM_RIGHT_CLASS)) {
    classList.add(ANIMATION_FADE_OUT_FROM_RIGHT_CLASS)
  }
  else if (classList.contains(INIT_ANIMATION_FADE_OUT_FROM_LEFT_CLASS)) {
    classList.add(ANIMATION_FADE_OUT_FROM_LEFT_CLASS)
  }
  else if (classList.contains(INIT_ANIMATION_FADE_OUT_FROM_BOTTOM_CLASS)) {
    classList.add(ANIMATION_FADE_OUT_FROM_BOTTOM_CLASS)
  }
  else if (classList.contains(INIT_ANIMATION_FADE_OUT_FROM_TOP_CLASS)) {
    classList.add(ANIMATION_FADE_OUT_FROM_TOP_CLASS)
  }
  else if (classList.contains(INIT_ANIMATION_ADS_4_CLASS)) {
    classList.add(ANIMATION_ADS_4_CLASS)
  }
  else if (classList.contains(INIT_ANIMATION_ADS_LK_CLASS)) {
    classList.add(ANIMATION_ADS_LK_CLASS)
  }
  else if (classList.contains(INIT_ANIMATION_ADS_CENTER_LEFT_CLASS)) {
    classList.add(ANIMATION_ADS_CENTER_LEFT_CLASS)
  }
  else if (classList.contains(INIT_ANIMATION_ADS_CENTER_RIGHT_CLASS)) {
    classList.add(ANIMATION_ADS_CENTER_RIGHT_CLASS)
  }
  else if (classList.contains(INIT_ANIMATION_DELIVERY_CLASS)) {
    classList.add(ANIMATION_DELIVERY_CLASS)
  }
}

export const WAYPOINT_PREVIEW_TOGGLE_SELECTOR = '.w-toggler-preview' // trigger elements selector
export const WAYPOINT_PREVIEW_TOGGLE_CLASS = WAYPOINT_PREVIEW_TOGGLE_SELECTOR.slice(1) // trigger elements class
