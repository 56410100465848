import React from 'react'

// components
import { Box } from 'layout'
import {
  Modal,
  ModalBanner,
  ModalContent,
  ModalActions,
  ModalContentWrapper,
} from 'components/modal'
import FormQuestion from 'components/form/FormQuestion'

// images
import { ReactComponent as Icon } from 'assets/icons/multy/settings.svg'
import bgImage from 'assets/images/modals/help-bg.jpg'

import { formOrderTypes } from 'constants/FormOrderTypes'

const ModalProIntegrations = ({
  closeModal,
  isLaptopBigOrMobile,
  isLaptopOrMobile,
}) => (
  <Modal flex size="full">
    <ModalActions closeModal={closeModal} />
    {!isLaptopBigOrMobile && (
      <ModalBanner
        center
        img={bgImage}
        title="modals.integrations.title"
        description="modals.integrations.description"
      />
    )}
    <ModalContentWrapper>
      <ModalContent p="withSidebar">
        <Box
          height="100%"
          jc="center"
          ai="center"
          flex
          direction="column"
        >
          <Box className={isLaptopOrMobile ? 'mb-3' : 'mb-30'}>
            <Icon width={55} height={49} />
          </Box>
          <Box self="stretch">
            <FormQuestion
              type={formOrderTypes.INTEGRATIONS}
              title="form.integrations.title"
              descriptionPlaceholder="form.textarea.integrations"
            />
          </Box>
        </Box>
      </ModalContent>
    </ModalContentWrapper>
  </Modal>
)

export default ModalProIntegrations
