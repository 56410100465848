export const OrdersStatuses = {
  ERROR: 'ERROR',
  WAITING_CLIENT: 'WAITING_CLIENT',
  WAITING_PAYMENT: 'WAITING_PAYMENT',
  WAITING_COMPANY: 'WAITING_COMPANY',
  IN_PROCESS: 'IN_PROCESS',
  READY: 'READY',
  COMPLETED: 'COMPLETED',
  REJECTED_CLIENT: 'REJECTED_CLIENT',
  REJECTED_COMPANY: 'REJECTED_COMPANY',
}

export const ordersStatusesModel = [
  {
    value: OrdersStatuses.ERROR,
    label: 'text.error',
    variant: 'red',
  },
  {
    value: OrdersStatuses.WAITING_COMPANY,
    label: 'text.new',
    variant: 'green',
  },
  {
    value: OrdersStatuses.IN_PROCESS,
    label: 'text.cooking',
    variant: 'purple',
  },
  {
    value: OrdersStatuses.READY,
    label: 'text.delivery',
    variant: 'blue',
  },
  {
    value: OrdersStatuses.COMPLETED,
    label: 'text.done',
    variant: 'grey',
  },
  {
    value: OrdersStatuses.REJECTED_COMPANY,
    label: 'text.refusal',
    variant: 'grey',
  },
  {
    value: OrdersStatuses.REJECTED_CLIENT,
    label: 'text.canceled',
    variant: 'grey',
  },
]
