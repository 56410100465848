import React from 'react'
import cn from 'classnames'

import s from 'styled-components'

const getStyles = ({ $mb }) => `
  margin-bottom: ${$mb};

  &.--os {
    &-normal {
      margin-bottom: 60px;

      @media (max-width: 1024px) {
        margin-bottom: 40px;
      }

      @media (max-width: 768px) {
        margin-bottom: 24px;
      }
    }
  }

  &.--a {
    &-left {
      text-align: left;
    }

    &-right {
      text-align: right;
    }

    &-center {
      text-align: center;
    }
  }
`

const OffsetWrapperContainer = s.div`
  ${getStyles}
`

const OffsetWrapper = ({
  children,
  offsetSize = 'normal',
  tAlign = '',
  mb = '',
}) => (
  <OffsetWrapperContainer
    className={cn({
      [`--os-${offsetSize}`]: offsetSize,
      [`--a-${tAlign}`]: tAlign,
    })}
    $mb={mb}
  >
    {children}
  </OffsetWrapperContainer>
)

export default OffsetWrapper
