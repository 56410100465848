import React from 'react'

import { ButtonRound } from 'components/base'
import { ReactComponent as IconTrash } from 'assets/icons/trash.svg'

const ButtonDelete = ({ onClick, ...otherProps }) => (
  <ButtonRound
    {...otherProps}
    defaultStyle="light"
    onClick={onClick}
    fill="grey500"
    fillHover="black"
    p="5"
    svgWidth="23px"
    svgHeight="23.5px"
    renderIcon={() => <IconTrash />}
  />
)

export default ButtonDelete
