import React, {
  useRef,
  useEffect,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Field } from 'formik'

import Countdown from 'components/base/Countdown'
import { Text, Link } from 'components/typo'
import {
  Form,
  Input,
  FormGroup,
  FormError,
} from 'components/form'
import { Grid } from 'layout'
import Spinner from 'components/base/Spinner'

import useFirebase from 'services/Firebase'

import { setModal } from 'redux/actions/SystemActions'
import { setPhoneConfirmationForm, fetchUserRegistrationAsync } from 'redux/actions/AuthActions'

const PhoneConfirm = ({
  isCodeError,
  setCodeError,
  // isCodeSuccess,
  setCodeSuccess,
}) => {
  const history = useHistory()
  const [ repeat, setRepaet ] = useState(false)
  const codeRef = useRef([])
  const dispatch = useDispatch()
  const { registrationData, phone } = useSelector((state) => state.auth)
  const { modal: { data } } = useSelector((state) => state.system)
  const {
    codeVerification,
    phoneSignIn,
    resetRecaptcha,
    getCurrentUserTokenId,
    isFetchingPhoneAuth,
    isFetchingCodeVerification,
  } = useFirebase()

  useEffect(() => {
    if (codeRef.current && codeRef.current[0]) {
      codeRef.current[0].focus()
    }
    // eslint-disable-next-line
  }, [codeRef.current])

  const handleCodeVerify = (code) => {
    setCodeError(false)
    setCodeSuccess(true)

    codeVerification(code).then(() => {
      getCurrentUserTokenId().then((token) => {
        if (data && data.password) {
          dispatch(
            setModal({
              name: 'change-password',
              back: true,
              data: { firebase_token: token },
            })
          )
        }
        else {
          dispatch(
            fetchUserRegistrationAsync({
              ...registrationData,
              firebase_token: token,
            }, history)
          )
        }
      })
    }).catch(() => {
      setCodeSuccess(false)
      setCodeError(true)
    })
  }

  const handleRepeat = () => {
    setCodeError(false)

    if (phone) {
      resetRecaptcha()
      phoneSignIn(phone, false)
      setRepaet(false)
      codeRef.current[0].focus()
    }
    else {
      setCodeError(true)
    }
  }

  const handlTimerEnd = () => {
    setRepaet(true)
  }

  const handleKeyPress = (e, handleSubmit) => {
    if (codeRef.current) {
      const idx = +e.target.name
      const { value } = e.target
      const { length } = codeRef.current

      if (e.keyCode === 37) {
        if (idx === 0) codeRef.current[length - 1].focus()
        else codeRef.current[idx - 1].focus()
      }
      else if (e.keyCode === 39) {
        if (idx === length - 1) codeRef.current[0].focus()
        else codeRef.current[idx + 1].focus()
      }
      else if (e.keyCode === 8 && value.length === 0 && idx > 0) {
        codeRef.current[idx - 1].focus()
      }
      else if (e.keyCode === 13) {
        handleSubmit()
      }
    }
  }

  return (
    <div>
      <Formik
        initialValues={{ code: new Array(6).fill('') }}
        onSubmit={(values, {
          setSubmitting,
          setFieldError,
        }) => {
          if (values.code.some((num) => num.length === 0)) {
            setFieldError('code', true)
            setSubmitting(false)
            return
          }

          dispatch(setPhoneConfirmationForm(values))
          handleCodeVerify(values.code.join(''))
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleSubmit,
            handleBlur,
            setFieldValue,
            resetForm,
          } = props

          const handleCodeChange = (e) => {
            if (isCodeError) {
              setCodeError(false)
            }

            const { target } = e

            if (target.value.length <= 1) {
              if (/\d+/.test(target.value)) target.value.replace(/\d+/, '')
              setFieldValue(`code[${target.name}]`, target.value)
            }

            if (
              values.code
                .filter((_, i) => i !== +target.name)
                .every((v) => v.length === 1)
            ) {
              handleSubmit()
            }
            else if (target.value.length === 1) {
              const nextField = codeRef.current[+target.name + 1]

              if (nextField) {
                nextField.focus()
              }
            }
          }

          const attrs = {
            onChange: handleCodeChange,
            onBlur: handleBlur,
            errors,
            touched,
          }

          // const formIsValid = dirty && !errors.code && values.username.length && !errors.password && values.password.length && !isSubmitting;

          return (
            <>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Grid>
                    {values.code.map((value, idx) => (
                      <Field key={idx}>
                        {() => (
                          <Input
                            className="confirmation-code"
                            // autoFocus={idx === 0 && window.innerWidth > 768}
                            disabled={isFetchingCodeVerification || isFetchingPhoneAuth}
                            readOnly={isFetchingCodeVerification || isFetchingPhoneAuth}
                            max={1}
                            onKeyDown={(e) => handleKeyPress(e, handleSubmit)}
                            square
                            ref={(el) => (codeRef.current[idx] = el)}
                            align="center"
                            {...attrs}
                            name={idx}
                            type="text"
                            value={value}
                            isError={isCodeError}
                          />
                        )}
                      </Field>
                    ))}
                  </Grid>
                  {errors.code && <FormError>{errors.code}</FormError>}
                </FormGroup>
              </Form>
              {repeat ? (
                <Link
                  href="/#"
                  onClick={(e) => {
                    e.preventDefault()
                    resetForm()
                    handleRepeat()
                  }}
                  className="mt-6"
                >
                  Прислать новый код
                </Link>
              ) : (
                <Text color="grey500" className="mt-6">
                  Запросить новый код через
                  {' '}
                  <Countdown tag="span" seconds={30} handleEnd={handlTimerEnd} />
                </Text>
              )}
              {isFetchingCodeVerification || isFetchingPhoneAuth ? (
                <Spinner
                  size="medium"
                  relative
                  height={100}
                />
              ) : ''}
            </>
          )
        }}
      </Formik>
    </div>
  )
}

// const dispatchPropsToState = (dispatch) => ({
//   fetchConfirmPhoneAsync: ({ code, history }) => dispatch(fetchConfirmPhoneAsync({ code, history })),
//   fetchUserResetPasswordSubmitAsync: ({ code, phone }) => dispatch(fetchUserResetPasswordSubmitAsync({ code, phone })),
// });

// const mapPropsToState = ({ user }) => ({
//   isFetchingAuth: user.isFetching.auth,
// });

export default PhoneConfirm
