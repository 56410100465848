/* eslint-disable import/no-cycle */
import React, {
  useRef,
  useEffect,
  useCallback,
} from 'react'
import s from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { TimelineLite } from 'services/GsapService'

import ModalLogin from 'components/modals/ModalLogin'
import ModalRegistration from 'components/modals/ModalRegistration'
import ModalPhoneConfirm from 'components/modals/ModalPhoneConfirm'
import ModalEmailConfirm from 'components/modals/ModalEmailConfirm'
import ModalConfirm from 'components/modals/ModalConfirm'
import ModalDownloadClients from 'components/modals/ModalDownloadClients'
import ModalProBanner from 'components/modals/ModalProBanner'
import ModalProIntegrations from 'components/modals/ModalProIntegrations'
import ModalProAnalitics from 'components/modals/ModalProAnalitics'
import ModalHelp from 'components/modals/ModalHelp'
import ModalForgetPassword from 'components/modals/ModalForgetPassword'
import ModalChangePassword from 'components/modals/ModalChangePassword'
import ModalCustomDesign from 'components/modals/ModalCustomDesign'
import ModalPayments from 'components/modals/ModalPayments'
import ModalScreenshots from 'components/modals/ModalScreenshots'
import ModalRequest from 'components/modals/ModalRequest'
import ModalSubscriptions from 'components/modals/ModalSubscriptions'
import ModalOrderSubscription from 'components/modals/ModalOrderSubscription'
import ModalDemos from 'components/modals/ModalDemos'

import useResponsive from 'components/hooks/useResponsive'

// redux
import { setModal } from 'redux/actions/SystemActions'

const ModalWrapperContainer = s.div`
position: fixed;
top: 0;
right: 0;
z-index: 9999;
display: ${(props) => (props.show ? 'block' : 'none')};
width: 100%;
height: 100%;
min-height: 100%;
max-height: 100%;

&::before {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 50px;
  margin-top: -1px;
  background-color: #fff;
  content: "";
}

& > .inner,
& > .backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

& > .inner {
  z-index: 2;
  display: flex;
  justify-content: center;
  transform: translate(-100%, 0, 0);
  opacity: 1;
  opacity: 0;
  pointer-events: none;
}

& > .backdrop {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: transform 0.5s ease-in-out;
  pointer-events: initial;

  ${({ show }) => (show && show.center ? `
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
  ` : '')}
}
`

const modals = {
  login: ModalLogin,
  signup: ModalRegistration,
  problems: ModalHelp,
  confirm: ModalConfirm,
  'phone-confirm': ModalPhoneConfirm,
  'email-confirm': ModalEmailConfirm,
  'pro-banner': ModalProBanner,
  'pro-integrations': ModalProIntegrations,
  payments: ModalPayments,
  'custom-design': ModalCustomDesign,
  'pro-analitics': ModalProAnalitics,
  'forget-password': ModalForgetPassword,
  'change-password': ModalChangePassword,
  screenshots: ModalScreenshots,
  request: ModalRequest,
  subscriptions: ModalSubscriptions,
  'order-subscription': ModalOrderSubscription,
  'download-clients': ModalDownloadClients,
  demos: ModalDemos,
}

const ModalWrapper = () => {
  const { modal } = useSelector((state) => state.system)
  const { isIpadPro, isLaptopOrMobile } = useResponsive()
  const dispatch = useDispatch()

  const wrapperRef = useRef(null)
  const innerRef = useRef(null)
  const backdropRef = useRef(null)

  useEffect(() => {
    if (modal && modal.close) {
      closeModal()
      return
    }

    if (modal && !modal.close && innerRef.current && backdropRef.current) {
      if (!modal.prev) {
        const tl = new TimelineLite()
        const isQuick = modal.name === 'confirm'

        document.body.classList.add('noscroll')

        tl
          .set(innerRef.current, {
            y: '100%',
            opacity: 1,
          })
          .fromTo(backdropRef.current, {
            duration: isQuick ? 0.15 : 0.3,
            alpha: 0,
          }, { alpha: 1 }, isQuick ? '0.15' : '0.3')

          .fromTo(innerRef.current, {
            duration: isQuick ? 0.2 : 0.4,
            y: '100%',
          }, { y: '0' }, isQuick ? 0.1 : 0.2)
      }
    }
    // eslint-disable-next-line
  }, [modal, innerRef.current, backdropRef.current])

  const closeModal = useCallback(() => {
    const tl = new TimelineLite()

    tl.fromTo(backdropRef.current, {
      duration: 0.3,
      alpha: 1,
    }, {
      alpha: 0,
      onComplete() {
        document.body.classList.remove('noscroll')
        dispatch(setModal(null))
      },
    }, '0.1')
      .fromTo(innerRef.current, {
        duration: 0.2,
        y: '0',
      }, { y: '100%' }, '0')
  }, [ dispatch ])

  const prevModal = useCallback(() => {
    if (modal.back && modal.prev) {
      dispatch(setModal({
        name: modal.prev,
        prev: modal.name,
      }))
    }
  }, [ dispatch, modal ])

  const handleModal = useCallback((name, data = {}) => {
    dispatch(setModal({
      name,
      prev: modal ? modal.name : false,
      back: !!(modal && modal.name),
      data,
    }))
  }, [ dispatch, modal ])

  const renderModal = useCallback((key) => {
    const ModalComponent = modals[key]

    if (modal && (modal.name === key || modal.prev === key)) {
      return (
        <ModalComponent
          closeModal={closeModal}
          prevModal={prevModal}
          handleModal={handleModal}
          modal={modal}
          isLaptopBigOrMobile={isIpadPro}
          isLaptopOrMobile={isLaptopOrMobile}
        />
      )
    }

    return null
  }, [ isIpadPro, isLaptopOrMobile, modal, closeModal, prevModal, handleModal ])

  const transformstyles = useCallback((key) => {
    if (!modal) return 'translateY(100%)'

    if (modal.name !== key && !modal.center) {
      return `translateY(100%)`
    }
    if (modal.name !== key && modal.center) {
      return `translateY(100%)`
    }
    if (modal.name === key && modal.center) {
      return `translate(-50%, -50%)`
    }
    if (modal.name === key && !modal.center) {
      return `translateY(0%)`
    }
  }, [ modal ])

  return (
    <ModalWrapperContainer
      className="modal-wrapper"
      ref={wrapperRef}
      show={modal}
    >
      <div
        ref={backdropRef}
        role="button"
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation()
          closeModal()
        }}
        className="backdrop"
      />
      <div ref={innerRef} className="inner">
        {Object.keys(modals).map((key) => (
          <div
            style={{
              transform: transformstyles(key),
              zIndex: modal && modal.name === key ? 10 : 5,
            }}
            className="slide"
            key={key}
          >
            {renderModal(key)}
          </div>
        ))}
      </div>
    </ModalWrapperContainer>
  )
}

export default ModalWrapper
