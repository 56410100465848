import React, {
  useState,
  useEffect,
  createRef,
  useMemo,
} from 'react'
import {
  YMaps,
  Map,
  Placemark,
} from 'react-yandex-maps'

// import IconMarker from 'assets/icons/multy/map-marker.svg'

const YandexMap = ({
  width = '100%',
  height = '100%',
  searchValue = '',
  onCoordsUpdate = () => {},
  settings: { zoom = 4, isMarker = true },
}) => {
  const mapRef = createRef(null)
  const [ ymaps, setYmaps ] = useState(null)
  const [ coords, setCoords ] = useState(null)

  useEffect(() => {
    if (ymaps && searchValue.length) {
      getCoords(searchValue)
    }
    // eslint-disable-next-line
  }, [ searchValue, ymaps ])

  const getCoords = (searchValue) => {
    ymaps.geocode(searchValue)
      .then((result) => {
        const coords = result.geoObjects.get(0).geometry.getCoordinates()
        setCoords(coords)
        onCoordsUpdate(coords)
      })
  }

  const onLoad = (ymaps) => {
    setYmaps(ymaps)
  }

  const mapState = useMemo(() => ({
    center: coords || [ 64.05, 97.04 ],
    zoom,
  }), [ coords, zoom ])

  return (
    <YMaps
      width={width}
      height={height}
      query={{ apikey: process.env.REACT_APP_YANDEX_API_KEY }}
    >
      <Map
        instanceRef={mapRef}
        width={width}
        height={height}
        onLoad={onLoad}
        state={mapState}
        modules={[ 'geocode', 'templateLayoutFactory' ]}

      >
        {coords && isMarker && (
          <Placemark
            geometry={coords}
            // options={{
            //   iconLayout: 'default#image',
            //   iconImageHref: IconMarker,
            //   iconImageSize: [ 60, 72 ],
            //   iconImageOffset: [ -30, -36 ],
            // }}
            options={{ preset: 'islands#greenDotIcon' }}
          />
        )}
      </Map>
    </YMaps>
  )
}

export default YandexMap
