import { LocationsActionTypes } from 'redux/types/LocationsTypes'
import { UserActionTypes } from 'redux/types/UserTypes'

const INITIAL_STATE = {
  name: 'locations',
  homeList: [],
  homeListIsEmpty: false,
  isFetching: false,
  isSubmitting: false,
}

const locationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE }

  case LocationsActionTypes.SET_FETCHING_LOCATIONS:
    return {
      ...state,
      isFetching: action.payload,
    }

  case LocationsActionTypes.FETCH_LOCATIONS_HOME_SUCCESS:
    return {
      ...state,
      homeList: action.payload,
    }

  case LocationsActionTypes.SET_LOCATIONS_HOME_IS_EMPTY:
    return {
      ...state,
      homeListIsEmpty: action.payload,
    }

  case LocationsActionTypes.CLEAR_LOCATIONS_HOME:
    return {
      ...state,
      homeList: [],
      homeListIsEmpty: false,
    }

  case LocationsActionTypes.SET_FETCHING_LOCATION_SUBMITTING:
    return {
      ...state,
      isSubmitting: action.payload,
    }

  case LocationsActionTypes.FETCH_LOCATION_CREATE_SUCCESS:
    return {
      ...state,
      homeList: [ ...state.homeList, action.payload ].sort((a, b) => {
        if (a.position - b.position > 0) return 1
        if (a.position - b.position < 0) return -1
        return 0
      }),
    }

  case LocationsActionTypes.FETCH_LOCATION_UPDATE_SUCCESS: {
    const isExistIdx = state.homeList.findIndex((c) => c.id === action.payload.id)

    if (isExistIdx >= 0) {
      return {
        ...state,
        homeList: [
          ...state.homeList.slice(0, isExistIdx),
          action.payload,
          ...state.homeList.slice(isExistIdx + 1),
        ],
      }
    }

    return state
  }

  case LocationsActionTypes.FETCH_LOCATION_DELETE_SUCCESS:
    return {
      ...state,
      homeList: state.homeList.filter((c) => c.id !== action.payload),
    }

  default:
    return state
  }
}

export default locationsReducer
