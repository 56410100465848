import React from 'react'
import s from 'styled-components'

import { Card } from 'components/cards'
import { Title } from 'components/typo'

const PartnerContainer = s.div`
  a {
    display: block;
  }

  .p-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 192px;
    height: 192px;
    padding: 15px;
    border: 1px solid ${({ theme }) => theme.colors.grey200};
    border-radius: ${({ theme }) => theme.radius.normal};

    @media (max-width: 768px) {
      width: 164px;
      height: 164px;
    }

    img {
      max-width: 90%;
      max-height: 90%;
      pointer-events: none;
    }
  }

  .p-title {
    margin-bottom: 40px;

    @media (max-width: 768px) {
      margin-bottom: 24px;
    }
  }

  .p-list {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;

    @media (max-width: 768px) {
      margin: -8px;
    }

    & > * {
      padding: 10px;

      @media (max-width: 768px) {
        padding: 8px;
      }
    }
  }
`
const PartnersList = ({ title = 'Наши партнеры', items }) => (
  <PartnerContainer>
    <div className="p-title"><Title tag="h2" tagStyle="h-4">{title}</Title></div>
    <div className="p-list">
      {items.map(({
        href,
        img,
        title,
      }, idx) => (
        <div key={idx}>
          <a
            key={href}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            title={title}
          >
            <Card
              pointer="cursor"
              isAnimated
              className="p-img"
            >
              <img src={img} alt={title} />
            </Card>
          </a>
        </div>
      ))}
    </div>
  </PartnerContainer>
)

export default PartnersList
