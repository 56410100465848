import React from 'react'

import { Button } from 'components/base'
import { ReactComponent as IconArrow } from 'assets/icons/arrow-right.svg'

const ButtonArrow = ({
  titleId = 'button.add',
  iconHide = false,
  defaultStyle = 'transparent',
  ...otherProps
}) => (
  <Button
    titleId={titleId}
    defaultStyle={defaultStyle}
    arrow
    {...otherProps}
  >
    {!iconHide && (
      <IconArrow
        width={18}
        height={18}
      />
    )}
  </Button>
)

export default ButtonArrow
