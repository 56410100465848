import { AuthActionTypes } from 'redux/types/AuthTypes'
import { UserActionTypes } from 'redux/types/UserTypes'

const INITIAL_STATE = {
  registrationData: null,
  phoneConfirmationData: null,
  phone: null,
  auth: false,
}

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE }

  case AuthActionTypes.SET_REGISTRATION_FORM:
    return {
      ...state,
      registrationData: action.payload,
    }

  case AuthActionTypes.SET_PHONE_CONFIRMATION_FORM:
    return {
      ...state,
      phoneConfirmationData: action.payload,
    }

  case AuthActionTypes.SET_PHONE:
    return {
      ...state,
      phone: action.payload,
    }

  case AuthActionTypes.FETCH_LOGIN_SUCCESS:
    return {
      ...state,
      auth: true,
    }

  case AuthActionTypes.FETCH_LOGOUT_SUCCESS:
    return {
      ...state,
      auth: false,
    }

  default:
    return state
  }
}

export default authReducer
