import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Field } from 'formik'
import { injectIntl } from 'react-intl'

// components
import { Button } from 'components/base'
import { Box } from 'layout'
import { Title } from 'components/typo'
import {
  Form,
  FormField,
  FormGroup,
  FormTerms,
} from 'components/form'
import useResponsive from 'components/hooks/useResponsive'

// constants
import { SchemaFormRequest } from 'constants/ValidationSchemas'

// helpers
import { getPhone } from 'helpers/RegExp'
import { fetchFormRequesе } from 'redux/actions/SystemActions'
import Formatted from 'components/typo/Formatted'
import FormFieldPhone from './FormFieldPhone'

const FormRequest = ({
  type = '',
  title = 'form.request.title',
  isEmpty = false,
  intl,
}) => {
  const placeholderCompany = intl.formatMessage({ id: 'form.input.company' })
  const placeholderPhone = intl.formatMessage({ id: 'form.input.phone' })

  const { isLaptopOrMobile } = useResponsive()
  const dispatch = useDispatch()
  const { profile } = useSelector((state) => state.user)

  const handleRequest = (values, { setSubmitting }) => {
    const data = {
      email: values.email,
      phone: getPhone(values.phone),
      company_name: values.company,
      order_type: type,
    }

    dispatch(fetchFormRequesе(data)).finally(() => setSubmitting(false))
  }

  return (
    <Formik
      initialValues={{
        phone: isEmpty ? '' : (profile && profile.phone) || '',
        email: isEmpty ? '' : (profile && profile.email) || '',
        company: isEmpty ? '' : (profile && profile.company) ? profile.company.title : '',
        terms: !isEmpty,
      }}
      onSubmit={handleRequest}
      validationSchema={SchemaFormRequest}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          isValid,
          isSubmitting,
        } = props

        const attrs = {
          onChange: handleChange,
          onBlur: handleBlur,
          errors,
          touched,
          size: 'big',
        }

        const formIsValid = isValid

        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Box jc="center" className={isLaptopOrMobile ? 'mb-20' : 'mb-5'}>
              <Title center tag="h2"><Formatted id={title} /></Title>
            </Box>
            <FormGroup>
              <Field>
                {() => (
                  <FormField
                    {...attrs}
                    name="company"
                    placeholder={placeholderCompany}
                    value={values.company}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup>
              <Field>
                {() => (
                  <FormField
                    {...attrs}
                    name="email"
                    placeholder="Email"
                    type="email"
                    value={values.email}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup>
              <Field>
                {() => (
                  <FormFieldPhone
                    {...attrs}
                    value={values.phone}
                    placeholder={placeholderPhone}
                  />
                )}
              </Field>
            </FormGroup>
            <div className={isLaptopOrMobile ? 'my-20' : 'my-4'}>
              <FormTerms
                {...attrs}
                isLaptopOrMobile={isLaptopOrMobile}
                name="terms"
                value={values.terms}
              />
            </div>
            <FormGroup>
              <Button
                defaultStyle="acsent"
                type="submit"
                size="big"
                full
                titleId="form.button.sendRequest"
                loader={isSubmitting || !formIsValid}
                isDisabled={isSubmitting || !formIsValid}
              />
            </FormGroup>
          </Form>
        )
      }}
    </Formik>
  )
}

export default injectIntl(FormRequest)
