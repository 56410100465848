import React from 'react'
import s from 'styled-components'

import { ReactComponent as IconDrag } from 'assets/icons/card-drag.svg'

const CardDragHandlerContainer = s.div`
  position: absolute;
  top: 0;
  right: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 48px;
  margin-right: ${({ rightOffset }) => (rightOffset || 0)};
  color: ${({ theme, isHovered }) => (isHovered ? theme.colors.grey500 : theme.colors.grey400)};
  transition: color 0.3s ease-in-out;


  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
  }

  svg {
    display: block;
    fill: currentColor;
  }
`

const notVisibleStyles = {
  pointerEvents: 'none',
  visibility: 'hidden',
}

const CardDragHandler = ({ isShowDrag = true, ...props }) => (
  <CardDragHandlerContainer style={isShowDrag ? {} : notVisibleStyles} {...props}>
    <div>
      <IconDrag width={9} height={19} />
    </div>
  </CardDragHandlerContainer>
)

export default CardDragHandler
