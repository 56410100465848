import React, {
  useMemo, useRef, useEffect, useCallback,
} from 'react'
import { useDispatch } from 'react-redux'
import s from 'styled-components'
import cn from 'classnames'

import {
  Container, Box, Grid, GridItem,
} from 'layout'
import Logo from 'components/Logo'
import { Text, Link } from 'components/typo'
import * as PathsTypes from 'constants/PathsTypes'
import StickyButton from 'pages/HomePage/components/StickyButton'

import { setModal } from 'redux/actions/SystemActions'
import useResponsive from 'components/hooks/useResponsive'
import TextFormatted from 'components/typo/TextFormatted'
import Formatted from 'components/typo/Formatted'

const PageFooterContainer = s.footer`
  position: relative;
  z-index: 2;
  padding: 50px 0 60px;
  background-color: ${({ theme }) => theme.colors.grey100};
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;

  &.--mob {
    padding: 24px 0 32px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }

  .footer-cop {
    color: ${({ theme }) => theme.colors.grey500};

    a {
      color: currentColor;
    }

    .smd-logo {
      transition: fill 0.3s ease-in-out;
      fill: ${({ theme }) => theme.colors.grey500};

      &:hover {
        fill: ${({ theme }) => theme.colors.black};
      }
    }
  }

  &.--laptop-min .w-footer-nav {
    max-width: 650px;
    margin: 0 auto;
  }
`

const PageFooter = () => {
  const { isLaptopOrMobile, isLaptopBigOrMobile } = useResponsive()
  const dispatch = useDispatch()
  const innerRef = useRef(null)
  const btnRef = useRef(null)
  const btnOffset = isLaptopOrMobile ? 10 : 20
  const btnOffsetRight = isLaptopOrMobile ? 14 : 30

  const footerPrivacy = useMemo(
    () => (
      <div className={cn('footer-cop', { 'mt-5': !isLaptopOrMobile })}>
        <TextFormatted
          titleId="home.footer.address"
          size="small"
          className={isLaptopOrMobile ? 'mb-3' : 'mb-10'}
          color="inherit"
        />
        <Text size="small" color="inherit">
          <Formatted id="home.footer.copyright" />
        </Text>
      </div>
    ),
    [ isLaptopOrMobile ]
  )

  const handleCallback = useCallback(() => {
    dispatch(setModal({ name: 'request' }))
  }, [ dispatch ])

  useEffect(() => {
    const btnHeight = btnRef.current && btnRef.current.getBoundingClientRect().height
    const btnNum = btnHeight + btnOffset

    function handleScroll() {
      if (btnRef.current) {
        const bounds = innerRef.current.getBoundingClientRect()
        const { innerHeight } = window
        const { top, left } = bounds

        if (innerHeight - btnNum < top) {
          if (!btnRef.current.classList.contains('--fixed')) {
            btnRef.current.classList.add('--fixed')
          }
          btnRef.current.style.right = `${btnOffsetRight}px`
        }
        else if (innerHeight - btnNum > top) {
          if (btnRef.current.classList.contains('--fixed')) {
            btnRef.current.classList.remove('--fixed')
          }
          btnRef.current.style.right = `-${left - btnNum / 2}px`
        }
      }
    }

    if (innerRef.current) {
      window.addEventListener('scroll', handleScroll)
      window.addEventListener('resize', handleScroll)
      handleScroll()
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleScroll)
    }
  }, [ innerRef, btnOffset, btnOffsetRight ])

  const handleDemosModal = useCallback(
    (activeTab = null) => {
      dispatch(
        setModal({
          name: 'demos',
          activeTab,
        })
      )
    },
    [ dispatch ]
  )

  return (
    <PageFooterContainer
      className={cn('w-footer', {
        '--mob': isLaptopOrMobile,
        '--laptop-min': !isLaptopBigOrMobile,
      })}
    >
      <Container relative>
        <div className="f-inner" ref={innerRef}>
          <StickyButton
            // eslint-disable-next-line comma-dangle
            style={{ top: `-${btnOffset}px` }}
            onClick={handleCallback}
            ref={btnRef}
          />
          <Grid ai="stretch" gutter="big" className="w-footer-inner">
            <GridItem maxWidth={isLaptopBigOrMobile ? '' : '300'}>
              <Box height="100%" direction="column" className="w-footer-left">
                <div className="mb-a">
                  <Logo isNoMobile defaultStyle="light" notitle isSize={isLaptopBigOrMobile ? 'normal' : ''} />
                </div>
                {isLaptopBigOrMobile && (
                  <Box width="100%" className="mt-7">
                    <FooterNav handleDemosModal={handleDemosModal} isLaptopOrMobile={isLaptopOrMobile} />
                  </Box>
                )}
                {footerPrivacy}
              </Box>
            </GridItem>
            {!isLaptopBigOrMobile && (
              <GridItem flex={isLaptopBigOrMobile ? '' : '1'}>
                <div className="w-footer-nav">
                  <FooterNav handleDemosModal={handleDemosModal} isLaptopOrMobile={isLaptopOrMobile} />
                </div>
              </GridItem>
            )}
          </Grid>
        </div>
      </Container>
    </PageFooterContainer>
  )
}

const FooterNavContainer = s.nav`
  display: flex;
  width: 100%;
  margin: 0 -10px;

  & > * {
    width: 50%;
    padding: 0 10px;
  }

  &.--mob {
    flex-direction: column;
    margin: unset;

    & > * {
      width: 100%;
      margin-bottom: 32px;
      padding: 0;
    }
  }

  .w-nav-list {
    & > * {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  &.--mob .w-nav-list {
    & > * {
      margin-bottom: 20px;
    }
  }
`

const FooterNav = ({ isLaptopOrMobile = false, className = '' }) => {
  const titleClasses = isLaptopOrMobile ? 'mb-20' : 'mb-6'
  const textMainSize = 'medium'

  return (
    <FooterNavContainer className={cn('w-footer-nav', { '--mob': isLaptopOrMobile }, className)}>
      <div className="w-footer-nav-block">
        <TextFormatted titleId="home.footer.nav.additional" color="grey500" className={titleClasses} />
        <div className="w-nav-list">
          <div>
            <Link size={textMainSize} to={PathsTypes.POLITIC}>
              <Formatted id="home.footer.nav.additional.3" />
            </Link>
          </div>
        </div>
      </div>
      <div className="w-footer-nav-block">
        <TextFormatted titleId="home.footer.nav.forContact" color="grey500" className={titleClasses} />
        <div className="w-nav-list">
          <div>
            <Text size={textMainSize} tag="a" hoverColor="black700" target="_blank" href={`mailto:${PathsTypes.WIBY_EMAIL}`}>
              info@wiby.io
            </Text>
          </div>
          <div>
            <Text size={textMainSize} tag="a" hoverColor="black700" target="_blank" href={`tel:${PathsTypes.WIBY_PHONE}`}>
              {PathsTypes.WIBY_PHONE}
            </Text>
          </div>
        </div>
      </div>
    </FooterNavContainer>
  )
}

export default PageFooter
