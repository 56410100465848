import React from 'react'
import s from 'styled-components'
import cn from 'classnames'

import Logo from 'components/Logo'

const InterfacePreloaderContainer = s.div`
  position: relative;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 4px 10px 25px rgba(113, 128, 150, 0.12);
  backdrop-filter: blur(5px);

  &.--bg {
    &-white {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }

  &.--fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  &.--absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }
`

const InterfacePreloader = ({ position = 'fixed', bg = null }) => (
  <InterfacePreloaderContainer
    className={cn('ps-preloader', {
      [`--${position}`]: position,
      [`--bg-${bg}`]: bg,
    })}
  >
    <Logo
      defaultStyle="grey"
      notitle
      nopointers
      animated
    />
  </InterfacePreloaderContainer>
)

export default InterfacePreloader
