import React from 'react'
import { getNumbers } from 'helpers/RegExp'
import { ReactComponent as IconPlus } from 'assets/icons/plus.svg'
import FormField from './FormField'

const FormFieldPhone = ({
  value,
  placeholder = 'Телефон',
  name = 'phone',
  ...attrs
}) => (
  <FormField
    {...attrs}
    type="tel"
    name={name}
    before
    icon={IconPlus}
    value={getNumbers(value)}
    placeholder={placeholder}
  />
)

export default FormFieldPhone
