import React from 'react'

// components
import { Box } from 'layout'
import {
  Modal,
  ModalBanner,
  ModalContent,
  ModalActions,
  ModalContentWrapper,
} from 'components/modal'
import FormRequest from 'components/form/FormRequest'

// images
import { ReactComponent as Icon } from 'assets/icons/multy/mail-green.svg'
import bgImage from 'assets/images/modals/help-bg.jpg'
import { formOrderTypes } from 'constants/FormOrderTypes'

const ModalRequest = ({
  closeModal, isLaptopBigOrMobile, isLaptopOrMobile, modal,
}) => {
  const isEmpty = modal && modal.data && modal.data.isEmpty

  return (
    <Modal flex size="full">
      <ModalActions closeModal={closeModal} />
      {!isLaptopBigOrMobile && (
        <ModalBanner
          center
          img={bgImage}
          title="modals.request.title"
          description="modals.request.description"
        />
      )}
      <ModalContentWrapper>
        <ModalContent p="withSidebar">
          <Box height="100%" jc="center" ai="center" flex direction="column">
            <Box className={isLaptopOrMobile ? 'mb-3' : 'mb-30'}>
              <Icon width={55} height={49} />
            </Box>
            <Box self="stretch">
              <FormRequest type={formOrderTypes.BUSINESS} isEmpty={isEmpty} />
            </Box>
          </Box>
        </ModalContent>
      </ModalContentWrapper>
    </Modal>
  )
}

export default ModalRequest
