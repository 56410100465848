import React from 'react'
import s from 'styled-components'

const CardBodyContainer = s.div`
  padding: ${({ p }) => (p || '20px')};

  ${({ flexVertical }) => (flexVertical
    ? `
      display: flex;
      flex-direction: column;
      flex: 1;
    `
    : '')}
`

const CardBody = ({ children, ...otherProps }) => <CardBodyContainer {...otherProps}>{children}</CardBodyContainer>

export default CardBody
