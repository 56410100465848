import React from 'react'

import { Bage } from 'components/base'

const CardStopList = ({ title = 'button.stop.list', variant = 'grey' }) => (
  <Bage
    titleId={title}
    tt="up"
    variant={variant}
  />
)

export default CardStopList
