import React from 'react'
import cn from 'classnames'

import { Box } from 'layout'
import { Tooltip } from 'components/base'
import Formatted from 'components/typo/Formatted'
import Text from 'components/typo/Text'

const FormHeading = ({
  children,
  title,
  titleId,
  color = 'black',
  nomargin = false,
  onClick = null,
  hidden,
  tooltip = null,
  small,
  jc = 'space-between',
  tooltipPosition = 'top-right',
  ...otherProps
}) => (
  <Box
    jc={jc}
    ai="center"
    className={cn({
      'mb-0': nomargin,
      'mb-3': !nomargin && !small,
      'mb-1': !nomargin && small,
    })}
    pointer={onClick}
    onClick={onClick}
    {...otherProps}
  >
    <Text
      fw={small ? 'n' : 'sb'}
      size={small ? 'xs' : 'normal'}
      tag="div"
      color={hidden ? 'transparent' : color}
    >
      {titleId ? <Formatted id={titleId} /> : title}
    </Text>
    {children}
    {tooltip && (
      <Tooltip
        className="ml-min"
        text={tooltip}
        position={tooltipPosition}
      />
    )}
  </Box>
)

export default FormHeading
