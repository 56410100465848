import React, { useMemo } from 'react'
import cn from 'classnames'
import s from 'styled-components'

import { Text } from 'components/typo'
import { Box } from 'layout'

import Formatted from 'components/typo/Formatted'
import { ReactComponent as IconCheck } from './icons/check.svg'
import { ReactComponent as IconPlus } from './icons/plus.svg'

const ListTextItemContainer = s.div`
  svg {
    transition: fill 0.3s ease-in-out;
    fill: ${({ theme }) => theme.colors.grey500};
  }

  .item-text,
  .item-description {
    transition: color 0.3s ease-in-out;
  }

  &.--m {
    .item-description {
      max-width: 160px;
    }
  }

  &.--s {
    &-dark {
      color: ${({ theme }) => theme.colors.grey500};

      svg {
        fill: ${({ theme }) => theme.colors.black};
      }
    }

    &-white {
      color: ${({ theme }) => theme.colors.white};

      svg {
        fill: ${({ theme }) => theme.colors.white};
      }
    }

    &-grey {
      color: ${({ theme }) => theme.colors.grey500};

      svg {
        fill: ${({ theme }) => theme.colors.grey500};
      }
    }
  }
`

function ListTextItem({
  children,
  title,
  description,
  isLaptopOrMobile,
  defaultStyle = 'black',
  icon = 'check',
  isFormatted,
}) {
  const Icon = useMemo(() => {
    switch (icon) {
    case 'check':
    default:
      return IconCheck

    case 'plus':
      return IconPlus
    }
  }, [ icon ])

  return (
    <ListTextItemContainer className={cn('w-list-item', {
      '--m': isLaptopOrMobile,
      [`--s-${defaultStyle}`]: defaultStyle,
    })}
    >
      <Box>
        <Icon className={isLaptopOrMobile ? 'mr-2' : 'mr-20'} />
        <div>
          <Text className="item-text" size="medium" color="inherit">
            {title
              ? isFormatted
                ? <Formatted id={title} />
                : title
              : children}
          </Text>
          {description && (
            <Text
              className="item-description mt-10"
              size={isLaptopOrMobile ? 'xs' : 'small'}
              color="whitet70"
            >
              {isFormatted ? <Formatted id={description} /> : description}
            </Text>
          )}
        </div>
      </Box>
    </ListTextItemContainer>
  )
}

export default ListTextItem
