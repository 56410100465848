import React from 'react'
import s from 'styled-components'
import cn from 'classnames'

import Title from 'components/typo/Title'
import Text from 'components/typo/Text'
import Card from 'components/cards/Card'
import LazyImage from 'components/cards/LazyImage'
import Box from 'layout/Box'
import {
  pbDefaultD,
  pbDefaultL,
  pDefaultM,
} from 'layout/Section'

import { ReactComponent as IconAppStore } from 'assets/icons/app/app-store-black.svg'
import { ReactComponent as IconGooglePlay } from 'assets/icons/app/google-play-black.svg'

const getStyles = () => `
  min-width: 615px;
  overflow: hidden;

  @media (max-width: 768px) {
    min-width: 334px;
  }

  &.--shadow {
    box-shadow: 80px 60px 60px 0 rgba(113, 128, 150, 0.18);

    margin-bottom: ${pbDefaultD};

    @media (max-width: 768px) {
      margin-bottom: ${pbDefaultL};
    }

    @media (max-width: 600px) {
      margin-bottom: ${pDefaultM};
    }
  }

  .head {
    height: 522px;
    width: 100%;

    @media (max-width: 768px) {
      height: 283px;
    }
  }

  .body {
    padding: 46px;
    padding-top: 24px;

    @media (max-width: 768px) {
      padding: 24px;
      padding-top: 16px;
    }
  }

  .title {
    margin-bottom: 32px;

    @media (max-width: 768px) {
      margin-bottom: 16px;
    }
  }

  .links {
    & > * {
      position: relative;
      transform: translateY(0px);
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: translateY(-3px);
      }
    }

    svg {
      width: 218px;
      height: 63px;

      @media (max-width: 768px) {
        width: 140px;
        height: 40px;
      }
    }
  }
`

const CardCaseContainer = s(Card)`
  ${getStyles}
`

const CardCase = ({
  bgColor = 'white',
  title = '',
  img = null,
  isMobile = false,
  links = [],
  isShadow = false,
}) => (
  <CardCaseContainer
    bgColor={bgColor}
    className={cn('c-case', {
      '--mob': isMobile,
      '--shadow': isShadow,
    })}
    br="xl"
  >
    <div className="head">
      <LazyImage
        br="0px"
        width="100%"
        height="100%"
        objectFit="cover"
        src={img.src}
        srcSet={img.srcSet}
      />
    </div>
    <div className="body">
      <Title
        tag="h3"
        tagStyle="h-2"
        className="title"
      >
        {title}
      </Title>
      <Box className="links">
        <Text
          tag="a"
          browser
          target="_blank"
          href={links.app}
        >
          <IconAppStore />
        </Text>
        <Text
          className={`ml-${isMobile ? '1' : '4'}`}
          tag="a"
          browser
          target="_blank"
          href={links.google}
        >
          <IconGooglePlay />
        </Text>
      </Box>
    </div>
  </CardCaseContainer>
)

export default CardCase
