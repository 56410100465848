import { SystemActionTypes } from 'redux/types/SystemTypes'
import { CategoriesActionTypes } from 'redux/types/CategoriesTypes'
import { ProductsActionTypes } from 'redux/types/ProductsTypes'
import { AdditionsActionTypes } from 'redux/types/AdditionsTypes'
import { BannersActionTypes } from 'redux/types/BannersTypes'
import { LocationsActionTypes } from 'redux/types/LocationsTypes'
import { MailingsActionTypes } from 'redux/types/MailingsTypes'
import { UserActionTypes } from 'redux/types/UserTypes'

import StorageService from 'services/StorageService'
// import { getLocale } from 'i18n/helpers'
import { LOCALES } from 'i18n/locales'

const INITIAL_STATE = {
  preloader: false,
  modal: null,
  init: false,
  search: '',
  locale: null,
  scrollWidth: 0,
  isPanelCollapsed: StorageService.getInterfacePanelIsCollapsed(),
  error: false,
  isFetchinRequest: false,
  isSubmitting: false,
  isFormDirty: false,
  isNeedSaveForm: false,
}

const systemReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE }

  case SystemActionTypes.SET_SHOW_PRELOADER:
    return {
      ...state,
      preloader: action.payload,
    }

  case SystemActionTypes.INIT_STATE_SUCCESS:
    return {
      ...state,
      init: action.payload,
    }

  case SystemActionTypes.SET_FORM_IS_DIRTY:
    return {
      ...state,
      isFormDirty: action.payload,
    }

  case SystemActionTypes.INIT_STATE_ERROR:
    return {
      ...state,
      error: action.payload,
    }

  case SystemActionTypes.SET_SEARCH_VALUE:
    return {
      ...state,
      search: action.payload,
    }

  case SystemActionTypes.SET_MODAL:
    if (action.payload && action.payload.close) {
      return {
        ...state,
        modal: {
          ...state.modal,
          ...action.payload,
        },
      }
    }

    return {
      ...state,
      modal: action.payload
        ? {
          active: true,
          prev: state.modal ? state.modal.name : null,
          ...action.payload,
        }
        : null,
    }

  case CategoriesActionTypes.SET_FETCHING_CATEGORY_SUBMITTING:
  case ProductsActionTypes.SET_FETCHING_PRODUCT_SUBMITTING:
  case AdditionsActionTypes.SET_FETCHING_ADDITION_SUBMITTING:
  case LocationsActionTypes.SET_FETCHING_LOCATION_SUBMITTING:
  case BannersActionTypes.SET_FETCHING_BANNER_SUBMITTING:
  case MailingsActionTypes.SET_FETCHING_MAILING_SUBMITTING:
    return {
      ...state,
      isSubmitting: action.payload,
    }

  case SystemActionTypes.SET_FETCHING_FORM_REQUEST:
    return {
      ...state,
      isFetchinRequest: action.payload,
    }

  case SystemActionTypes.SET_BROWSER_WIDTH:
    return {
      ...state,
      scrollWidth: action.payload,
    }

  case SystemActionTypes.SET_PANNEL_IS_COLLAPSED:
    return {
      ...state,
      isPanelCollapsed: action.payload,
    }

  case SystemActionTypes.SET_NEED_TO_SAVE_FORM:
    return {
      ...state,
      isNeedSaveForm: action.payload,
    }

  case SystemActionTypes.SET_APP_LOCALE:
    return {
      ...state,
      locale: LOCALES.EN,
      // locale: getLocale(action.payload),
    }

  default:
    return state
  }
}

export default systemReducer
