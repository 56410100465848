/* eslint-disable import/no-cycle */
import React, { useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Field } from 'formik'

import {
  Form,
  Input,
  FormGroup,
  FormError,
} from 'components/form'
import { Grid } from 'layout'
import Spinner from 'components/base/Spinner'

import { fetchEmailConfirm } from 'redux/actions/AuthActions'

const EmailConfirm = () => {
  const codeRef = useRef([])
  const dispatch = useDispatch()
  const { isFetchingEmailConfirm } = useSelector((state) => state.auth)

  useEffect(() => {
    if (codeRef.current && codeRef.current[0]) {
      codeRef.current[0].focus()
    }
    // eslint-disable-next-line
  }, [codeRef.current])

  const handleSubmit = (code, uid, setSubmitting) => {
    dispatch(fetchEmailConfirm({
      code,
      uid,
    })).finally(() => setSubmitting(false))
  }

  const handleKeyPress = (e, handleSubmit) => {
    if (codeRef.current) {
      const idx = +e.target.name
      const { value } = e.target
      const { length } = codeRef.current

      if (e.keyCode === 37) {
        if (idx === 0) codeRef.current[length - 1].focus()
        else codeRef.current[idx - 1].focus()
      }
      else if (e.keyCode === 39) {
        if (idx === length - 1) codeRef.current[0].focus()
        else codeRef.current[idx + 1].focus()
      }
      else if (e.keyCode === 8 && value.length === 0 && idx > 0) {
        codeRef.current[idx - 1].focus()
      }
      else if (e.keyCode === 13) {
        handleSubmit()
      }
    }
  }

  return (
    <div>
      <Formik
        initialValues={{ code: new Array(5).fill('') }}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          if (values.code.some((num) => num.length === 0)) {
            setFieldError('code', true)
            setSubmitting(false)
            return
          }

          handleSubmit(values.code.join(''), setSubmitting)
        }}
      >
        {(props) => {
          const {
            values, touched, errors, isSubmitting, handleSubmit, handleBlur, setFieldValue,
          } = props

          const handleCodeChange = (e) => {
            const { target } = e

            if (target.value.length <= 1) {
              if (/\D+/.test(target.value)) target.value.replace(/\D+/, '')
              setFieldValue(`code[${target.name}]`, target.value)
            }

            if (values.code.filter((_, i) => i !== +target.name).every((v) => v.length === 1)) {
              handleSubmit()
            }
            else if (target.value.length === 1) {
              const nextField = codeRef.current[+target.name + 1]

              if (nextField) {
                nextField.focus()
              }
            }
          }

          const attrs = {
            onChange: handleCodeChange,
            onBlur: handleBlur,
            errors,
            touched,
          }

          // const formIsValid = dirty && !errors.code && values.username.length && !errors.password && values.password.length && !isSubmitting;

          return (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Grid>
                  {values.code.map((value, idx) => (
                    <Field key={idx}>
                      {() => (
                        <Input
                          {...attrs}
                          autoFocus={idx === 0}
                          disabled={isSubmitting || isFetchingEmailConfirm}
                          readOnly={isSubmitting || isFetchingEmailConfirm}
                          max={1}
                          onKeyDown={(e) => handleKeyPress(e, handleSubmit)}
                          square
                          ref={(el) => (codeRef.current[idx] = el)}
                          align="center"
                          name={idx}
                          type="text"
                          value={value}
                        />
                      )}
                    </Field>
                  ))}
                </Grid>
                {errors.code && <FormError>{errors.code}</FormError>}
              </FormGroup>
            </Form>
          )
        }}
      </Formik>
      {isFetchingEmailConfirm ? <Spinner /> : ''}
    </div>
  )
}

export default EmailConfirm
