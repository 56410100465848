import ApiService from 'services/ApiService'

import { messageTypes } from 'constants/NotifierTypes'
import { setNotification } from './SystemActions'
import { OrdersActionTypes } from '../types/OrdersTypes'
import { searchResponseIsEmpty } from '../utils/ResponseUtils'

export const setFetchinghOrders = (value) => ({
  type: OrdersActionTypes.SET_FETCHING_ORDERS,
  payload: value,
})

export const fetchOrdersError = (value) => ({
  type: OrdersActionTypes.FETCH_ORDERS_ERROR,
  payload: value,
})

export const fetchOrders = ({
  query = '',
  onSuccess = () => {},
  onError = () => {},
}) => (dispatch) => {
  dispatch(setFetchinghOrders(true))

  return new Promise((res, rej) => ApiService.apiCall({
    url: `${ApiService.paths.orders.ROOT}${query}`,
    isToken: true,
  })
    .then((response) => {
      onSuccess(response)
      dispatch(setFetchinghOrders(false))
      return res(response)
    })
    .catch((error) => {
      onError(error)
      dispatch(setFetchinghOrders(false))
      dispatch(fetchOrdersError(false))
      return rej(error)
    }))
}

export const fetchOrdersHomeSuccess = (value) => ({
  type: OrdersActionTypes.FETCH_ORDERS_HOME_SUCCESS,
  payload: value,
})

export const setOrdersHomeIsEmpty = (value) => ({
  type: OrdersActionTypes.SET_ORDERS_HOME_IS_EMPTY,
  payload: value,
})

export const fetchOrdersHome = (query = '') => (dispatch) => dispatch(fetchOrders({
  query,
  onSuccess: (response) => {
    dispatch(fetchOrdersHomeSuccess(response.results))
    dispatch(setOrdersHomeIsEmpty(searchResponseIsEmpty(query, response)))
  },
}))

export const clearOrdersHome = (value) => ({
  type: OrdersActionTypes.CLEAR_ORDERS_CLIENT,
  payload: value,
})

export const fetchOrdersClientSuccess = (value) => ({
  type: OrdersActionTypes.FETCH_ORDERS_CLIENT_SUCCESS,
  payload: value,
})

export const setOrdersClientIsEmpty = (value) => ({
  type: OrdersActionTypes.SET_ORDERS_CLIENT_IS_EMPTY,
  payload: value,
})

export const fetchOrdersClient = (query = '') => (dispatch) => dispatch(fetchOrders({
  query,
  onSuccess: (response) => {
    dispatch(fetchOrdersClientSuccess(response.results))
    dispatch(setOrdersClientIsEmpty(searchResponseIsEmpty(query, response)))
  },
}))

export const clearOrdersClient = (value) => ({
  type: OrdersActionTypes.CLEAR_ORDERS_CLIENT,
  payload: value,
})

export const setFetchinghGetOrderById = (value) => ({
  type: OrdersActionTypes.SET_FETCHING_GET_ORDER_BY_ID,
  payload: value,
})

export const fetchGetOrderByIdSuccess = (orders) => ({
  type: OrdersActionTypes.FETCH_GET_ORDER_BY_ID_SUCCESS,
  payload: orders,
})

export const fetchGetOrderById = (id) => (dispatch) => {
  dispatch(setFetchinghGetOrderById(true))

  return new Promise((res, rej) => ApiService.apiCall({
    url: `${ApiService.paths.orders.ROOT}${id}/`,
    isToken: true,
  })
    .then((response) => {
      dispatch(fetchGetOrderByIdSuccess(response.results))
      dispatch(setFetchinghGetOrderById(false))
      return res(response)
    })
    .catch(({ data }) => {
      dispatch(setFetchinghGetOrderById(false))
      return rej(data)
    }))
}

export const setFetchinghOrderSubmiting = (value) => ({
  type: OrdersActionTypes.SET_FETCHING_ORDER_SUBMITTING,
  payload: value,
})

export const fetchOrderDeleteSuccess = (orders) => ({
  type: OrdersActionTypes.FETCH_ORDER_DELETE_SUCCESS,
  payload: orders,
})

export const fetchOrderDeleteById = (id) => (dispatch) => {
  dispatch(setFetchinghOrderSubmiting(true))

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'DELETE',
    url: `${ApiService.paths.orders.ROOT}${id}/`,
    isToken: true,
  })
    .then(() => {
      dispatch(fetchOrderDeleteSuccess(id))
      dispatch(setFetchinghOrderSubmiting(false))
      dispatch(setNotification({
        message: 'Заказ успешно удален',
        type: messageTypes.TRASH,
      }))
      return res(true)
    })
    .catch(({ data }) => {
      dispatch(setFetchinghOrderSubmiting(false))
      return rej(data)
    }))
}

export const fetchOrderAcceptSuccess = (orders) => ({
  type: OrdersActionTypes.FETCH_ORDER_ACCEPT_SUCCESS,
  payload: orders,
})

export const fetchOrderAcceptError = (error) => ({
  type: OrdersActionTypes.FETCH_ORDER_ACCEPT_ERROR,
  payload: error,
})

export const fetchOrderAcceptById = (id) => (dispatch) => {
  dispatch(setFetchinghOrderSubmiting(true))

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'POST',
    url: ApiService.paths.orders.ACCEPT(id),
    isToken: true,
    data: {},
  })
    .then((response) => {
      dispatch(fetchOrderAcceptSuccess(response))
      dispatch(setFetchinghOrderSubmiting(false))
      dispatch(setNotification({
        message: 'Заказ принят',
        type: messageTypes.CHECK,
      }))
      return res(true)
    })
    .catch(({ data }) => {
      dispatch(setFetchinghOrderSubmiting(false))
      dispatch(fetchOrderAcceptError(data))
      return rej(data)
    }))
}

export const fetchOrderRejectSuccess = (orders) => ({
  type: OrdersActionTypes.FETCH_ORDER_REJECT_SUCCESS,
  payload: orders,
})

export const fetchOrderRejectError = (error) => ({
  type: OrdersActionTypes.FETCH_ORDER_REJECT_ERROR,
  payload: error,
})

export const fetchOrderRejectById = (id) => (dispatch) => {
  dispatch(setFetchinghOrderSubmiting(true))

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'POST',
    url: ApiService.paths.orders.REJECT(id),
    isToken: true,
    data: {},
  })
    .then((response) => {
      dispatch(fetchOrderRejectSuccess(response))
      dispatch(setFetchinghOrderSubmiting(false))
      dispatch(setNotification({
        message: 'Заказ отменен',
        type: messageTypes.CHECK,
      }))
      return res(true)
    })
    .catch(({ data }) => {
      dispatch(setFetchinghOrderSubmiting(false))
      dispatch(fetchOrderRejectError(data))
      return rej(data)
    }))
}

export const fetchOrderStatusReadySuccess = (orders) => ({
  type: OrdersActionTypes.FETCH_ORDER_STATUS_READY_SUCCESS,
  payload: orders,
})

export const fetchOrderStatusReadyError = (error) => ({
  type: OrdersActionTypes.FETCH_ORDER_STATUS_READY_ERROR,
  payload: error,
})

export const fetchOrderStatusReadyById = (id) => (dispatch) => {
  dispatch(setFetchinghOrderSubmiting(true))

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'POST',
    url: ApiService.paths.orders.READY(id),
    isToken: true,
    data: {},
  })
    .then((response) => {
      dispatch(fetchOrderStatusReadySuccess(response))
      dispatch(setFetchinghOrderSubmiting(false))
      dispatch(setNotification({
        message: 'Заказ доставляется',
        type: messageTypes.CHECK,
      }))
      return res(true)
    })
    .catch(({ data }) => {
      dispatch(setFetchinghOrderSubmiting(false))
      dispatch(fetchOrderStatusReadyError(data))
      return rej(data)
    }))
}

export const fetchOrderStatusCompleteSuccess = (orders) => ({
  type: OrdersActionTypes.FETCH_ORDER_STATUS_COMPLETE_SUCCESS,
  payload: orders,
})

export const fetchOrderStatusCompleteError = (error) => ({
  type: OrdersActionTypes.FETCH_ORDER_STATUS_COMPLETE_ERROR,
  payload: error,
})

export const fetchOrderStatusCompleteById = (id) => (dispatch) => {
  dispatch(setFetchinghOrderSubmiting(true))

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'POST',
    url: ApiService.paths.orders.COMPLETE(id),
    isToken: true,
    data: {},
  })
    .then((response) => {
      dispatch(fetchOrderStatusCompleteSuccess(response))
      dispatch(setFetchinghOrderSubmiting(false))
      dispatch(setNotification({
        message: 'Заказ выполнен',
        type: messageTypes.CHECK,
      }))
      return res(true)
    })
    .catch(({ data }) => {
      dispatch(setFetchinghOrderSubmiting(false))
      dispatch(fetchOrderStatusCompleteError(data))
      return rej(data)
    }))
}

export const setNewOrdersCount = (value, isNewOrder) => (dispatch) => {
  if (isNewOrder) {
    dispatch(setNotification({
      message: 'Новый Заказ',
      type: messageTypes.ORDER,
    }))
  }

  dispatch({
    type: OrdersActionTypes.SET_NEW_ORDERS_COUNT,
    payload: value,
  })
}
