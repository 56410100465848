import React from 'react'

import Section from 'layout/Section'

import imgPreviewApp1 from 'assets/images/home/app/app-1.png'
import imgPreviewApp1x2 from 'assets/images/home/app/app-1@2x.png'

import ButtonArrow from 'components/buttons/ButtonArrow'
import Formatted from 'components/typo/Formatted'
import Hero from './Hero'

export const THEMES_PREVIEW = [
  {
    id: 1,
    img: imgPreviewApp1,
    imgX2: imgPreviewApp1x2,
  },
]

const SectionAppPreview = ({
  isLaptopOrMobile,
  isLaptopBigOrMobile = false,
  onRequest = () => {},
}) => (
  <Section
    className="p-0"
    m="0"
    isRelative
    isOverflowOff
    br="all"
    bg="purple"
  >
    <Hero
      isLaptopBigOrMobile={isLaptopBigOrMobile}
      isLaptopOrMobile={isLaptopOrMobile}
      items={THEMES_PREVIEW}
      isFreeStartBtn={false}
      imageOffsetBig
      actions={(
        <ButtonArrow
          defaultStyle="blured"
          size="big"
          titleId="button.knowMore"
          onClick={onRequest}
        />
      )}
      title={(
        <>
          <Formatted id="home.heading.marketing1" />
          {' '}
          <Formatted id="home.heading.marketing2" />
          {' '}
          <Formatted id="home.heading.marketing3" />
        </>
      )}
    />
  </Section>
)

export default SectionAppPreview
