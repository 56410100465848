import React from 'react'
import s from 'styled-components'
import cn from 'classnames'

const styles = ({ theme }) => `
  position: relative;
  width: 100%;
  padding: 20px 32px;
  border-top: 1px solid ${theme.colors.grey300};
  color: #000;

  &.--big-offset {
    padding: 20px 50px;
  }
`

const ModalFooterContainer = s.div`
  ${(props) => styles(props)}
`

const ModalFooter = ({
  className,
  children,
  bigOffset = false,
  ...otherProps
}) => {
  const classes = cn('w-modal-footer', { '--big-offset': bigOffset }, className)

  const props = {
    ...otherProps,
    className: classes,
  }

  return (
    <ModalFooterContainer className={classes} {...props}>
      {children}
    </ModalFooterContainer>
  )
}

export default ModalFooter
