export const RateTypes = {
  BY_ORDER: 'BY_ORDER',
  BY_PRICE: 'BY_PRICE',
}

export const PeriodTypes = {
  MONTH: 'MONTH',
  YEAR: 'YEAR',
  MONTH_3: 'MONTH_3',
}

export const PeriodTabsModel = [
  {
    id: PeriodTypes.MONTH,
    title: 'tariff.period.month',
    idx: 0,
  },
  {
    id: PeriodTypes.MONTH_3,
    title: 'tariff.period.quarter',
    idx: 1,
  },
  {
    id: PeriodTypes.YEAR,
    title: 'tariff.period.year',
    idx: 2,
  },
]

export const SUBSCRIPTIONS = [
  {
    id: 1,
    title: 'tariff.name.start',
    variant: 'orange',
    label: 'tariff.bage.free',
    description: 'tariff.description.start',
    prices: null,
    periodsPrices: [{
      id: PeriodTypes.MONTH,
      period: PeriodTypes.MONTH,
      raw_price: '',
      price: '0',
    }, {
      id: PeriodTypes.MONTH_3,
      period: PeriodTypes.MONTH_3,
      raw_price: '0',
      price: '0',
    }, {
      id: PeriodTypes.YEAR,
      period: PeriodTypes.YEAR,
      raw_price: '0',
      price: '0',
    }],
    pricesLabel: '0',
    actionTitle: 'button.choose',
    list: [
      'tariff.list.start.1',
      'tariff.list.start.2',
      'tariff.list.start.3',
      'tariff.list.start.4',
      'tariff.list.start.5',
    ],
    info: 'tariff.info.start',
  },
  {
    id: 2,
    title: 'tariff.name.medium',
    variant: 'green',
    label: 'tariff.bage.hit',
    description: 'tariff.description.medium',
    periodsPrices: [
      {
        id: PeriodTypes.MONTH,
        period: PeriodTypes.MONTH,
        raw_price: '500',
        price: '500',
      },
      {
        id: PeriodTypes.MONTH_3,
        period: PeriodTypes.MONTH_3,
        raw_price: '400',
        price: '400',
      },
      {
        id: PeriodTypes.YEAR,
        period: PeriodTypes.YEAR,
        raw_price: '300',
        price: '300',
      },
    ],
    prices: [
      '7 900',
      '5 900',
      '4 900',
    ],
    pricesInfo: [
      '',
      'tariff.priceInfo.2',
      'tariff.priceInfo.3',
    ],
    pricesLabel: null,
    actionTitle: 'button.choose',
    listPrev: 'tariff.listPrev.medium',
    list: [
      'tariff.list.medium.1',
      'tariff.list.medium.2',
      'tariff.list.medium.3',
      'tariff.list.medium.4',
      'tariff.list.medium.5',
      'tariff.list.medium.6',
      'tariff.list.medium.7',
      'tariff.list.medium.8',
      'tariff.list.medium.9',
      'tariff.list.medium.10',
      'tariff.list.medium.11',
      'tariff.list.medium.12',
      'tariff.list.medium.13',
    ],
    info: null,
  },
  {
    id: 3,
    variant: 'purple',
    title: 'tariff.name.business',
    label: 'tariff.bage.premium',
    description: 'tariff.description.business',
    periodsPrices: null,
    prices: null,
    pricesInfo: [
      '',
      'tariff.priceInfo.2',
      'tariff.priceInfo.3',
    ],
    pricesLabel: null,
    actionTitle: 'button.choose',
    listPrev: 'tariff.listPrev.business',
    list: [
      'tariff.list.business.1',
      'tariff.list.business.2',
      'tariff.list.business.3',
      'tariff.list.business.4',
      'tariff.list.business.5',
      'tariff.list.business.6',
      'tariff.list.business.7',
      'tariff.list.business.8',
      'tariff.list.business.9',
      'tariff.list.business.10',
      'tariff.list.business.11',
      'tariff.list.business.12',
      'tariff.list.business.13',
      'tariff.list.business.14',
      'tariff.list.business.15',
    ],
    info: null,
  },
]
