import React from 'react'
import { useDispatch } from 'react-redux'

import { Text, Link } from 'components/typo'
import { setModal } from 'redux/actions/SystemActions'
import Formatted from 'components/typo/Formatted'

const ModalFooterHelp = () => {
  const dispatch = useDispatch()

  return (
    <Text color="grey500" align="center">
      <Formatted id="text.help.problems" />
      {' '}
      <Link
        to="/#help"
        onClick={(e) => {
          e.preventDefault()
          dispatch(setModal({ name: 'problems' }))
        }}
      >
        <Formatted id="text.help.text_support" />
      </Link>
    </Text>
  )
}

export default ModalFooterHelp
