import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import history from 'history/configureHistory'

export function PrivateRoute({
  component: Component,
  auth,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => (auth ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: {
              isNeedAuth: true,
              from: history.location.pathname,
              search: history.location.search,
            },
          }}
        />
      ))}
    />
  )
}
