import React from 'react'
import s from 'styled-components'

const CardBlockContainer = s.div`
  ${({ divider }) => (divider
    ? `border-bottom: 1px solid #E3E3E3;
       padding-bottom: 35px;
       margin-bottom: 35px;`
    : '')}
`

const CardBlock = ({ children, ...otherProps }) => (
  <CardBlockContainer className="ps-card-block" {...otherProps}>
    {children}
  </CardBlockContainer>
)

export default CardBlock
