import React, {
  useEffect,
  useCallback,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import s from 'styled-components'

import { Page } from 'layout'
import useResponsive from 'components/hooks/useResponsive'

import { setModal } from 'redux/actions/SystemActions'
import { fetchSubscriptions } from 'redux/actions/SubscriptionsActions'

import { scrollObserver } from 'utils'
import { WAYPOINT_TOGGLE_SELECTOR, toggleWaypointAnimation } from 'constants/AnimationsTypes'

import SectionAlreadyGet from './components/SectionAlreadyGet'
import SectionWho from './components/SectionWho'
import SectionHero from './components/SectionHero'
import SectionAppPreview from './components/SectionAppPreview'
import SectionMetrics from './components/SectionMetrics'
import SectionDelivery from './components/SectionDelivery'
import SectionCases from './components/SectionCases'
import SectionServices from './components/SectionServices'

const HomePageContainer = s.div`
  .img-icon {
    position: absolute;

    &.--c-icon {
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }

    &.--ads4-i-apay {
      top: 71px;
      left: 0;
    }

    &.--ads4-i-gpay {
      top: 265px;
      right: 25px;
    }

    &.--ads4-i-wallet {
      top: 292px;
      left: 67px;
    }

    &.--ads4-i-card {
      top: 40px;
      right: 0;
    }

    &.--adslk-i-1 {
      top: 175px;
      left: -120px;

      @media (max-width: 768px) {
        top: 15px;
        left: -20px;
      }
    }

    &.--adslk-i-2 {
      top: 250px;
      left: 116px;
      display: flex;
      justify-content: center;

      @media (max-width: 768px) {
        top: 60px;
        left: 15px;
      }
    }

    &.--adslk-i-3 {
      top: 144px;
      left: 306px;

      @media (max-width: 768px) {
        top: 5px;
        right: -20px;
        left: unset;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .ads-lk {
    position: relative;
    padding-bottom: 250px;

    @media (max-width: 768px) {
      margin-top: 10px;
      margin-bottom: 50px;
      padding-bottom: 0;

      svg {
        width: 100px;
        height: 100px;
      }
    }

    &__icons {
      @media (max-width: 768px) {
        display: flex;
        margin: 0 -15px;

        & > * {
          position: relative;
          flex: 1;
          max-width: 33.3333%;
          padding: 0 15px;
        }
      }
    }
  }

  .image-lk {
    width: 100%;
    max-width: 100%;
  }

  .home-page-main {
    position: relative;
  }

  p + p {
    margin-top: 20px;

    @media (max-width: 768px) {
      margin-top: 12px;
    }
  }
`

const initScrollObserver = () => {
  scrollObserver(WAYPOINT_TOGGLE_SELECTOR, toggleWaypointAnimation, { rootMargin: '-15% 0px' })
}

const HomePage = ({ history }) => {
  const { location } = history
  const dispatch = useDispatch()
  const {
    isLaptopOrMobile,
    isLaptopBigOrMobile,
  } = useResponsive()

  // const [ adsMobShow, setAdsMobShow ] = useState(false)
  const { list: subscriptions } = useSelector((state) => state.subscriptions)

  useEffect(() => {
    initScrollObserver()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location.state && location.state.isNeedAuth) {
      dispatch(setModal({ name: 'login' }))
    }

    if (!subscriptions.length) {
      dispatch(fetchSubscriptions())
    }

    // eslint-disable-next-line
  }, []);

  const handleRegistration = useCallback(() => {
    dispatch(setModal({ name: 'signup' }))
  }, [ dispatch ])

  const handleRequest = useCallback(() => {
    dispatch(setModal({ name: 'request' }))
  }, [ dispatch ])

  return (
    <Page noPadding>
      <HomePageContainer className="home-page">
        <SectionHero
          isLaptopOrMobile={isLaptopOrMobile}
          isLaptopBigOrMobile={isLaptopBigOrMobile}
          onRegistration={handleRegistration}
          onRequest={handleRequest}
        />

        <SectionWho />

        <SectionAlreadyGet />

        <SectionAppPreview
          isLaptopOrMobile={isLaptopOrMobile}
          isLaptopBigOrMobile={isLaptopBigOrMobile}
          onRequest={handleRequest}
        />

        <SectionServices isLaptopOrMobile={isLaptopOrMobile} />

        <SectionCases isLaptopOrMobile={isLaptopOrMobile} />

        <SectionMetrics />

        <SectionDelivery
          onRequest={handleRequest}
          isLaptopOrMobile={isLaptopOrMobile}
          isLaptopBigOrMobile={isLaptopBigOrMobile}
        />
      </HomePageContainer>
    </Page>
  )
}

export default HomePage
