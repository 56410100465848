/* eslint-disable import/extensions */
import { LOCALES } from './locales'
import messagesRu from './lang/ru'
import messagesEn from './lang/en'

export function getLang() {
  if (navigator.languages !== undefined) {
    return navigator.languages[0]
  }
  return navigator.language
}

export function getLocale (locale) {
  switch (locale) {
  case LOCALES.EN.split('-')[0]:
    return LOCALES.EN

  case LOCALES.RU.split('-')[0]:
  default:
    return LOCALES.RU
  }
}

export function getLocaleMessages (locale) {
  switch (locale) {
  case LOCALES.EN:
    return messagesEn

  case LOCALES.RU:
  default:
    return messagesRu
  }
}
