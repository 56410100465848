import React, {
  useMemo,
  useCallback,
} from 'react'
import { useSelector } from 'react-redux'

import { ReactComponent as IconSave } from 'assets/icons/confirmModal/save.svg'
import { ReactComponent as IconReset } from 'assets/icons/confirmModal/reset.svg'
import { ReactComponent as IconDelete } from 'assets/icons/confirmModal/delete.svg'
import { ReactComponent as IconExit } from 'assets/icons/confirmModal/exit.svg'
import { ReactComponent as IconSubscription } from 'assets/icons/confirmModal/ghost.svg'
import { ReactComponent as IconWarning } from 'assets/icons/confirmModal/warning.svg'

// components
import {
  Modal,
  ModalContent,
} from 'components/modal'
import { Button } from 'components/base'
import { Text } from 'components/typo'
import Formatted from 'components/typo/Formatted'

const ModalConfirm = (props) => {
  const { isSubmitting } = useSelector((state) => state.system)
  const {
    closeModal = () => {},
    modal: {
      onYes = () => {},
      onNo = () => {},
      type = '',
      title = '',
      description = '',
      btnYesTitle = '',
    },
  } = props

  const renderIcon = useCallback(() => {
    let Icon = null
    switch (type) {
    case 'reset':
      Icon = IconReset
      break
    case 'delete':
      Icon = IconDelete
      break
    case 'save':
      Icon = IconSave
      break
    case 'exit':
      Icon = IconExit
      break
    case 'subscription':
      Icon = IconSubscription
      break
    case 'cancel-subscription':
      Icon = IconWarning
      break

    default:
      break
    }

    return Icon ? <Icon width={46} height={46} fill="#000" /> : null
  }, [ type ])

  const modalTitle = useMemo(() => {
    if (title) return title

    switch (type) {
    case 'reset':
      return 'modals.cancel.title.reset'

    case 'delete':
      return 'modals.cancel.title.delete'

    case 'save':
      return 'modals.cancel.title.save'

    case 'exit':
      return 'modals.cancel.title.exit'

    case 'subscription':
      return 'modals.cancel.title.subscription'

    case 'cancel-subscription':
      return 'modals.cancel.title.cancel_subscription'

    default:
      return 'modals.cancel.title.default'
    }
  }, [ type, title ])

  const modalDescription = useMemo(() => {
    if (description) return description

    switch (type) {
    case 'modals.cancel.description.subscription':
      return ''

    case 'modals.cancel.description.cancel_subscription':
      return ''

    default:
      return 'modals.cancel.description.default'
    }
  }, [ type, description ])

  const btnAttributes = useMemo(() => {
    const attrs = {
      defaultStyle: 'acsent',
      titleId: 'button.save',
    }

    switch (type) {
    case 'reset':
      attrs.titleId = 'button.reset'
      attrs.defaultStyle = 'purple'
      break

    case 'delete':
      attrs.titleId = btnYesTitle || 'button.delete'
      attrs.defaultStyle = 'orange'
      break

    case 'exit':
      attrs.titleId = btnYesTitle || 'button.exit'
      attrs.defaultStyle = 'purple'
      break

    case 'subscription':
      attrs.titleId = 'button.choose.rate'
      attrs.defaultStyle = 'purple'
      break

    case 'cancel-subscription':
      attrs.titleId = 'button.go.free'
      attrs.defaultStyle = 'orange'
      break

    case 'save':
    default:
      break
    }

    return attrs
  }, [ type, btnYesTitle ])

  const handleYes = () => {
    onYes(closeModal)
  }

  const handleNo = () => {
    onNo(closeModal)
    closeModal()
  }

  return (
    <Modal
      center
      width="confirm"
    >
      <ModalContent
        display="flex"
        column
        p="39px 36px 36px 36px"
      >
        <div className="mb-4">
          {renderIcon()}
        </div>
        <div className="mb-3">
          <Text align="center" fw="sb"><Formatted id={modalTitle} /></Text>
        </div>
        {type !== 'save' && type !== 'exit' && (
          <div className="mb-4">
            <Text align="center" color="grey500"><Formatted id={modalDescription} /></Text>
          </div>
        )}
        <Button
          {...btnAttributes}
          full
          className="mb-20"
          onClick={handleYes}
          isDisabled={isSubmitting}
          isFetching={isSubmitting}
        />

        {type !== 'subscription' && (
          <Button
            full
            defaultStyle="whitegrey"
            titleId="button.cancel"
            isDisabled={isSubmitting}
            onClick={handleNo}
          />
        )}
      </ModalContent>
    </Modal>
  )
}

export default ModalConfirm
