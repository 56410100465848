import React from 'react'

// components
import { Box } from 'layout'
import {
  Modal,
  ModalContent,
  ModalActions,
  ModalFooterHelp,
} from 'components/modal'
import ForgetPassword from 'components/ForgetPassword'

const ModalForgetPassword = ({ prevModal, closeModal }) => (
  <Modal flex size="full">
    <ModalActions prevModal={prevModal} closeModal={closeModal} />
    <ModalContent
      display="flex"
      column
      center
      p="withSidebar"
    >
      <Box
        height="100%"
        jc="center"
        ai="center"
        flex
        direction="column"
        className="mb-5"
        self="stretch"
      >
        <ForgetPassword />
      </Box>
      <div className="mt-a mx-a">
        <Box jc="center" self="center">
          <ModalFooterHelp />
        </Box>
      </div>
    </ModalContent>
  </Modal>
)

export default ModalForgetPassword
