import React, { useContext, useMemo } from 'react'
import s, { ThemeContext } from 'styled-components'
import cn from 'classnames'

import { ReactComponent as IconCheck } from 'assets/icons/check.svg'
import Formatted from 'components/typo/Formatted'

const FormCheckboxContainer = s.div`
.wrap {
  display: flex;
  align-items: center;
  cursor: pointer;
  pointer-events: ${({ noPointers }) => (noPointers ? 'none' : '')};
}

.input-hidden {
  position: absolute;
  left: -9999px;
  visibility: hidden;
}

.input-cloned.--hovered,
.wrap:hover .input-cloned {
  border-color: ${({ theme }) => theme.colors.grey300};
}

.input-cloned {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 1px;
  color: ${({ theme }) => theme.colors.grey100};
  background-color: currentColor;
  border: 1px solid ${({ theme }) => theme.colors.grey200};
  transition: border 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out;

  svg {
    transition: fill 0.3s ease-in-out;
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.grey300};
  }

  &.--radio {
    border-radius: 50%;
  }

  &.--checkbox {
    border-radius: 4px;
  }

  & + * {
    margin-left: 10px;

    @media (max-width: 768px) {
      margin-left: 8px;
    }
  }
}

.input-cloned.--checked,
input:checked + .input-cloned {
  color: ${({ theme }) => theme.colors.green};
  background-color: ${({ theme }) => theme.colors.green};
  border-color: ${({ theme }) => theme.colors.green};

  &.--radio {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: block;
      width: 8px;
      height: 8px;
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: 50%;
    }
  }
}

.input-cloned.--hovered.--checked,
.wrap:hover .input-cloned.--checked {
  background-color: ${({ theme }) => theme.colors.greent80};
  border-color: ${({ theme }) => theme.colors.greent80};
}

pointer-events: ${({ noPointers }) => (noPointers ? 'none' : '')};

.app-form-text {
  font-size: 16px;
}

`

const FormCheckbox = ({
  // field: { name, value, onChange, onBlur },
  name,
  value,
  // id,
  className,
  hint,
  html = null,
  // data,
  textMod,
  onChange = null,
  onBlur,
  withLabel = true,
  radio = false,
  isHovered = false,
  defaultChecked = null,
  noPointers,
  readOnly = false,
  // form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const theme = useContext(ThemeContext)

  const check = radio ? <span /> : (
    <IconCheck
      fill={theme.colors[value ? 'white' : 'transparent']}
      width={12.8}
      height={9.6}
    />
  )

  const attrs = useMemo(() => {
    const props = { value }

    if (defaultChecked === null) {
      props.checked = value
    }
    else {
      props.defaultChecked = value
    }

    return props
  }, [ defaultChecked, value ])

  const checkbox = (
    <>
      <input
        {...attrs}
        name={name}
        type="checkbox"
        onChange={onChange}
        onBlur={onBlur}
        className="input-hidden"
        readOnly={readOnly}
      />
      <div className={cn('input-cloned', {
        '--radio': radio,
        '--checkbox': !radio,
        '--hovered': isHovered,
        '--checked': value,
      })}
      >
        {check}
      </div>
      {' '}
      {hint && (
        <p className={`app-form-text ${textMod || ''}`}><Formatted id={hint} /></p>
      )}
      {html && html}
    </>
  )

  return (
    <FormCheckboxContainer noPointers={noPointers} theme={theme} {...props}>
      {withLabel
        ? <label className="wrap">{checkbox}</label>
        : <div className="wrap">{checkbox}</div>}
    </FormCheckboxContainer>
  )
}

export default FormCheckbox
