/* eslint-disable no-octal-escape */
import React from 'react'

// components
import { Title } from 'components/typo'
import {
  Container,
  Article,
  Page,
} from 'layout'

// constants
import * as PathsTypes from 'constants/PathsTypes'
import Link from 'components/typo/Link'

const LicensePage = () => {
  const link = (
    <Link
      browser
      size="inherit"
      target="_blank"
      href={PathsTypes.SITE}
    >
      {PathsTypes.SITE_LABEL}
    </Link>
  )

  return (
    <Page back="/">
      <Container size="xs" className="py-5 mb-5">
        <Article>
          <div className="mb-6">
            <Title center tag="h1" tagStyle="h-2">
              Оферта на предоставление неисключительной лицензии (лицензионный договор)
            </Title>
          </div>

          <div className="my-5">
            <p>
              г. Москва
              <br />
              <br />
              Общество с ограниченной ответственностью «ВАЙБИ ГРУПП», именуемое в дальнейшем «ЛИЦЕНЗИАР», в лице Генерального директора
              Жилина Алексея Сергеевича, действующего на основании Устава, предлагает Вам _______________________________,
              далее именуемый «ЛИЦЕНЗИАТ», приобрести право использования (простую неисключительную лицензию) программ для
              ЭВМ и баз данных (далее - ПРОДУКТЫ) на нижеописанных условиях.
              Полным и безоговорочным принятием (акцептом) условий настоящей оферты на предоставление неисключительной
              лицензии (далее - Договор) считается оплата ЛИЦЕНЗИАТОМ вознаграждения за предоставление права
              использования ПРОДУКТОВ в отведенный для акцепта срок – 5 календарных дней со дня оформления ЛИЦЕНЗИАТОМ заказа на сайте
              {' '}
              {link}
              .
            </p>
          </div>

          <div className="my-5">
            <Title tag="h2" tagStyle="h-4">1. ПРЕДМЕТ ДОГОВОРА</Title>
          </div>

          <p className="mb-20">
            ЛИЦЕНЗИАР, имея соответствующие полномочия от правообладателей,
            предоставляет за вознаграждение ЛИЦЕНЗИАТУ право использования нижеследующих ПРОДУКТОВ:
          </p>

          <table className="mb-20">
            <thead>
              <tr>
                <th>
                  Наименование
                </th>
                <th>
                  Цена за лицензию, руб
                </th>
                <th>
                  Кол-во лицензий, шт.
                </th>
                <th>
                  Цена, руб
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Лицензия на ПО для ЭВМ "Вайби" Покупка (Расширенный, 1 год)
                </td>
                <td>
                  7900
                </td>
                <td>
                  1
                </td>
                <td>
                  7900
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            Право использования (простую неисключительную лицензию) ПРОДУКТОВ в рамках их функциональных возможностей
            путем воспроизведения (подключения к Программе через сеть Интернет), исключительно для
            самостоятельного использования Лицензиатом без права сублицензирования третьим лицам.
          </p>

          <div className="my-5">
            <Title tag="h2" tagStyle="h-4">2. ЦЕНА ДОГОВОРА</Title>
          </div>

          <p>
            Цена Договора для ЛИЦЕНЗИАТА определяется исходя из цены ПРОДУКТОВ, указанной на сайте
            {' '}
            {link}
            ,
            которая действительна в течение срока, установленного для акцепта. При этом под "ценой"
            ПРОДУКТА или Договора подразумевается вознаграждение, которое полагается ЛИЦЕНЗИАРУ за
            предоставление простой неисключительной лицензии. Вознаграждение ЛИЦЕНЗИАРА НДС не облагается на
            основании главы 26.2 НК РФ.
          </p>

          <div className="my-5">
            <Title tag="h2" tagStyle="h-4">3. ПРАВА И ОБЯЗАТЕЛЬСТВА ЛИЦЕНЗИАТА</Title>
          </div>

          <p>
            ЛИЦЕНЗИАТ обязуется строго придерживаться и не нарушать правил лицензионного использования ПРОДУКТОВ,
            определенных правообладателями, а также установленными действующим законодательством Российской Федерации.
            ЛИЦЕНЗИАТ обязуется не осуществлять действий по обходу технических средств защиты, встроенных в ПРОДУКТЫ.
          </p>

          <div className="my-5">
            <Title tag="h2" tagStyle="h-4">4. ПРАВА И ОБЯЗАТЕЛЬСТВА ЛИЦЕНЗИАРА</Title>
          </div>

          <p>
            ЛИЦЕНЗИАР обязуется предоставить ЛИЦЕНЗИАТУ средствами сайта
            {' '}
            {link}
            {' '}
            оплаченные ПРОДУКТЫ в требуемом
            количестве. При этом датой исполнения обязательства ЛИЦЕНЗИАТА по оплате считается дата зачисления
            денежных средств на расчетный счет ЛИЦЕНЗИАРА. Совокупная ответственность ЛИЦЕНЗИАРА по Договору,
            ограничивается денежной суммой, уплаченной ЛИЦЕНЗИАТОМ по Договору в качестве оплаты за предоставляемые
            права использования.
          </p>

          <div className="my-5">
            <Title tag="h2" tagStyle="h-4">5. ПРОЦЕДУРА ПЕРЕДАЧИ ПРАВ</Title>
          </div>

          <p>
            Факт предоставления прав использования отражается в пользовательском интерфейсе сайта
            {' '}
            {link}
            .
          </p>
          <p>
            В случае если ЛИЦЕНЗИАТ является юридическим лицом предоставление прав использования производится по УПД, УПД
            считается предоставленным ЛИЦЕНЗИАТУ для ознакомления и направления возражений с момента, когда электронная копия
            УПД будет отправлена ЛИЦЕНЗИАТУ по электронной почте, указанной в заявке.
          </p>

          <div className="my-5">
            <Title tag="h2" tagStyle="h-4">6. УСТУПКА (ПЕРЕДАЧА) ПРАВ</Title>
          </div>

          <p>
            Лицензиат за исключением случаев, установленных настоящим Договором, имеет право однократно уступить
            полностью свои права и обязанности по настоящему Договору.
          </p>
          <p>
            Уступка (передача) прав и обязанностей осуществляется только при условии полного и безоговорочного
            согласия нового Лицензиата со всеми положениями и условиями настоящего Договора.
          </p>
          <p>
            Лицензиат обязан предоставить Лицензиару полные данные о новом Лицензиате для перерегистрации на него
            Программы в соответствии с настоящим Договором.
          </p>
          <p>
            Уступка (передача) прав по настоящему Договору не может быть осуществлена косвенно или через какое-либо
            третье лицо.
          </p>

          <div className="my-5">
            <Title tag="h2" tagStyle="h-4">7. ПРОЧИЕ УСЛОВИЯ</Title>
          </div>

          <p>
            Договор вступает в силу с даты его акцепта и действует до момента исполнения Сторонами, взятых на себя
            обязательств. Период, на который предоставляются права по настоящему Договору составляет 5 (Пять) лет,
            если иной более короткий срок не вытекает из самого наименования ПРОДУКТА (например, содержит фразу
            «годовая лицензия», «на год») или из документации сопровождающей ПРОДУКТ или из особенностей технических
            средств защиты, которые применяются производителем в конкретном ПРОДУКТЕ. ЛИЦЕНЗИАТ самостоятельно, на
            свое усмотрение и на свой риск выбирает соответствующий ПРОДУКТ, удовлетворяющий его потребностям.
            Во всем остальном, что не урегулировано настоящим Договором, Стороны руководствуются действующим законодательством Российской Федерации.
          </p>

          <h2>8. РЕКВИЗИТЫ СТОРОН</h2>
          <p>Лицензиар:</p>
          <ol className="--dot">
            <li>
              ООО «ВАЙБИ ГРУПП»
            </li>
            <li>
              Юридический адрес: 125315, г. Москва, ул. Усиевича, д.23, кв. 188
            </li>
            <li>
              Почтовый адрес: 125315, г. Москва, ул. Усиевича, д.23, кв. 188
            </li>
            <li>
              ИНН\КПП: 7714472964\771401001
            </li>
            <li>
              ОГРН: 1217700276991
            </li>
            <li>
              ОКПО: 48351157
            </li>
            <li>
              Расчетный счет: 40702810810000829697
            </li>
            <li>
              Кор.счет: 30101810145250000974, БИК: 044525974

            </li>
            <li>
              АО "ТИНЬКОФФ БАНК"
            </li>
            <li>
              E-mail:
              {' '}
              <Link
                size="inherit"
                browser
                target="_blank"
                href="mailto:contact@wiby.io"
              >
                contact@wiby.io
              </Link>
            </li>
          </ol>
        </Article>
      </Container>
    </Page>
  )
}

export default LicensePage
