import React from 'react'

import { Link } from 'components/typo'
import * as PathsTypes from 'constants/PathsTypes'

const EmailLink = ({
  email = PathsTypes.WIBY_EMAIL,
  color = 'white',
  hover = 'whitet70',
}) => (
  <Link
    browser
    color={color}
    hover={hover}
    target="_blank"
    href={`mailto:${email}`}
  >
    {email}
  </Link>
)

export default EmailLink
