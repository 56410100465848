import { combineReducers } from 'redux'

import additions from './AdditionsReducer'
import auth from './AuthReducer'
import banners from './BannersReducer'
import catalog from './CatalogReducer'
import categories from './CategoriesReducer'
import clients from './ClientsReducer'
import demos from './DemosReducer'
import filters from './FiltersReducer'
import locations from './LocationsReducer'
import mailings from './MailingsReducer'
import orders from './OrdersReducer'
import products from './ProductsReducer'
import user from './UserReducer'
import settings from './SettingsReducer'
import system from './SystemReducer'
import subscriptions from './SubscriptionsReducer'

const rootReducer = combineReducers({
  additions,
  auth,
  banners,
  catalog,
  categories,
  clients,
  demos,
  filters,
  locations,
  mailings,
  orders,
  products,
  user,
  settings,
  subscriptions,
  system,
})

export default rootReducer
