export const ClientsStatuses = {
  NEW: 'NEW',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
}

export const clientsStatusesModel = [
  {
    value: ClientsStatuses.INACTIVE, // ACTIVE - react select bug (active and inactive checked togethre on click)
    label: 'Неактивный',
  },
  {
    value: ClientsStatuses.NEW,
    label: 'Новый',
  },
  {
    value: ClientsStatuses.ACTIVE,
    label: 'Активный',
  },
]
