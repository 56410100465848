import React, {
  forwardRef,
  useState,
} from 'react'
import s from 'styled-components'

import { Input } from 'components/form'
import { timeRegExpDinamic } from 'helpers/RegExp'

const FormFieldTimeContainer = s(Input)`
  border: unset;
  box-shadow: unset;

  &:focus {
    color: unset;
    background-color: unset;
    border-color: unset;
  }

  &:hover {
    box-shadow: unset;
  }
`

const FormFieldTime = forwardRef(
  (
    {
      onChange = () => {},
      active = false,
      value = '',
      ...otherProps
    },
    ref
  ) => {
    const [ prevValue, setPrevValue ] = useState(value)

    const handleChange = (e) => {
      const newValue = getNewValue(prevValue, e.target.value)

      if (newValue !== null) {
        setPrevValue(newValue)
        e.target.value = newValue

        if (onChange) {
          onChange(e)
        }
      }
    }

    const getNewValue = (prevValue, newValue) => {
      if (newValue === prevValue || !isValid(newValue)) {
        return null
      }

      if (newValue.length === 1 && +newValue > 2) {
        newValue = `0${newValue}:`
      }
      else if (newValue.length > 1 && newValue.slice(0, 2) === '24') {
        newValue = `24:00`
      }
      else if (
        newValue.length === 2
          && prevValue.length !== 3
          && !newValue.includes(':')
      ) {
        newValue = `${newValue}:`
      }

      if (newValue.length === 2 && prevValue.length === 3) {
        newValue = newValue.slice(0, 1)
      }

      return newValue
    }

    const isValidMinute = (minute) => Number.isInteger(minute) && minute >= 0 && minute < 60

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour <= 24

    const isValid = (value) => {
      if (!timeRegExpDinamic.test(value)) {
        return false
      }

      const [ hourString = '', minuteString = '' ] = value.split(':')
      const hours = parseInt(hourString) || 0
      const minutes = parseInt(minuteString) || 0

      if (!isValidHour(hours) || !isValidMinute(minutes)) {
        return false
      }

      if (minutes < 10 && Number(minuteString[0]) > 5) {
        return false
      }

      return true
    }

    return (
      <FormFieldTimeContainer
        ref={ref}
        type="text"
        value={value}
        onChange={handleChange}
        {...otherProps}
      />
    )
  }
)

FormFieldTime.displayName = 'FormFieldTime'

export default FormFieldTime
