import React, { Suspense, lazy } from 'react'
import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

// constants
import * as PathsTypes from 'constants/PathsTypes'

import InterfacePreloader from 'layout/Interface/InterfacePreloader'

const Orders = lazy(() => import('pages/interface/Orders'))
const Catalog = lazy(() => import('pages/interface/Catalog'))
const Mailings = lazy(() => import('pages/interface/Mailings'))
const Clients = lazy(() => import('pages/interface/Clients'))
const Settings = lazy(() => import('pages/interface/Settings'))
const ErrorPage = lazy(() => import('pages/ErrorPage'))
// const Analitics = lazy(() => import('pages/interface/Analitics'))
// const Integrations = lazy(() => import('pages/interface/Integrations'))

const InterfaceRoutes = () => (
  <>
    <Suspense fallback={<InterfacePreloader position="absolute" />}>
      <Switch>
        <Route exact path={PathsTypes.INTERFACE} render={() => <Redirect to={PathsTypes.INTERFACE_ORDERS} />} />
        <Route exact path={PathsTypes.INTERFACE_ORDERS} component={Orders} />
        <Route path={PathsTypes.INTERFACE_CATALOG} component={Catalog} />
        <Route exact path={PathsTypes.INTERFACE_MAILINGS} component={Mailings} />
        <Route exact path={PathsTypes.INTERFACE_CLIENTS} component={Clients} />
        <Route path={PathsTypes.INTERFACE_SETTINGS} component={Settings} />
        {/* <Route path={PathsTypes.INTERFACE_ANALITICS} component={Analitics} /> */}
        {/* <Route path={PathsTypes.INTERFACE_INTEGRATIONS} component={Integrations} /> */}
        <Route render={() => <ErrorPage title="👽&nbsp;Страница не&nbsp;найдена..." />} />
      </Switch>
    </Suspense>
  </>
)

export default InterfaceRoutes
