import React from 'react'

// components
import { Title, Text } from 'components/typo'
import { Container, Page } from 'layout'
import PartnersList from 'components/PartnersList'
import useResponsive from 'components/hooks/useResponsive'

// constants
import * as PathsTypes from 'constants/PathsTypes'

import imgIIKO from 'assets/images/services/iiko.svg'
import imgKeeper from 'assets/images/services/r-keeper.svg'
import imgApplePay from 'assets/images/services/apple-pay.svg'
import imgGooglePay from 'assets/images/services/google-pay.svg'
import imgKassa from 'assets/images/services/kassa.svg'
import imgAppMetrica from 'assets/images/services/app-metrica.svg'
import imgAppsFlyer from 'assets/images/services/apps-flyer.svg'

const SERVICES = [
  {
    img: imgIIKO,
    href: 'https://iiko.ru/',
    title: 'Iiko',
  },
  {
    img: imgKeeper,
    href: 'https://rkeeper.ru',
    title: 'R-Keeper',
  },
  {
    img: imgKassa,
    href: 'https://yookassa.ru/',
    title: 'Kassa',
  },
  {
    img: imgApplePay,
    href: 'https://www.apple.com/ru/apple-pay/ ',
    title: 'Apple pay',
  },
  {
    img: imgGooglePay,
    href: 'https://pay.google.com/intl/ru_ru/about/ ',
    title: 'Google pay',
  },
  {
    img: imgAppMetrica,
    href: 'https://appmetrica.yandex.ru/about ',
    title: 'App Metrica',
  },
  {
    img: imgAppsFlyer,
    href: 'https://www.appsflyer.com/ru/ ',
    title: 'Apps Flyer',
  },
]

const ServicesPage = () => {
  const { isLaptopOrMobile } = useResponsive()
  const email = (
    <Text target="_blank" tag="a" size="inherit" color="purple" hoverColor="purplet80" href={`mailto:${PathsTypes.PARTNERSHIP_EMAIL}`}>
      {PathsTypes.PARTNERSHIP_EMAIL}
    </Text>
  )

  return (
    <Page back="/" isSecond>
      <Container size="xs" className="pt-5">
        <div className={isLaptopOrMobile ? 'mb-4' : 'mb-6'}>
          <Title tag="h1">Интеграция с сервисами</Title>
        </div>
        <div className={isLaptopOrMobile ? 'mb-4' : 'mb-6'}>
          <p>
            Платформа Wiby заинтересована во взаимовыгодных интеграциях с сервисами, делающими наш продукт еще более удобным и гибким для клиентов.
            Если у вас есть предложения по интеграциям в наши мобильные приложения, напишите на почту
            {' '}
            {email}
          </p>
        </div>
        <PartnersList items={SERVICES} />
      </Container>
    </Page>
  )
}

export default ServicesPage
