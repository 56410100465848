export const SubscriptionsActionTypes = {
  SET_FETCHING_SUBSCRIPTIONS: 'SET_FETCHING_SUBSCRIPTIONS',
  FETCH_SUBSCRIPTIONS_ERROR: 'FETCH_SUBSCRIPTIONS_ERROR',

  FETCH_SUBSCRIPTIONS_SUCCESS: 'FETCH_SUBSCRIPTIONS_SUCCESS',

  SET_FETCHING_USER_BUY_SUBSCRIPTIONS: 'SET_FETCHING_USER_BUY_SUBSCRIPTIONS',
  FETCH_USER_BUY_SUBSCRIPTIONS_SUCCESS: 'FETCH_USER_BUY_SUBSCRIPTIONS_SUCCESS',
  FETCH_USER_BUY_SUBSCRIPTIONS_ERROR: 'FETCH_USER_BUY_SUBSCRIPTIONS_ERROR',
}
