import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Formik, Field } from 'formik'
import { injectIntl } from 'react-intl'

// components
import { Title } from 'components/typo'
import { Button } from 'components/base'
import useFirebase from 'services/Firebase'
import {
  Form,
  FormField,
  FormTerms,
  FormGroup,
} from 'components/form'
import useResponsive from 'components/hooks/useResponsive'

// actions
import { setRegistrationForm, setPhone } from 'redux/actions/AuthActions'

// constants
import { SchemaRegistration } from 'constants/ValidationSchemas'

// helpers
import { getNumbers } from 'helpers/RegExp'
import Formatted from 'components/typo/Formatted'
import FormFieldPhone from 'components/form/FormFieldPhone'

const Registration = ({ handleModal, intl }) => {
  const { isLaptopOrMobile } = useResponsive()
  const dispatch = useDispatch()
  const captchaRef = useRef(null)
  const {
    phoneSignIn,
    isFetchingPhoneAuth,
    resetRecaptcha,
  } = useFirebase()
  const placeholderCompany = intl.formatMessage({ id: 'form.input.company' })
  const placeholderPhone = intl.formatMessage({ id: 'form.input.phone' })
  const placeholderCreatePassword = intl.formatMessage({ id: 'form.input.createPassword' })
  const placeholderRepeatPassword = intl.formatMessage({ id: 'form.input.repeatPassword' })

  const handleSignIn = (phone, setSubmitting) => {
    resetRecaptcha(captchaRef.current)
    phoneSignIn(phone)
      .then(() => {
        setSubmitting(false)
        handleModal({
          name: 'phone-confirm',
          back: true,
        })
      })
      .catch(() => {
        setSubmitting(false)
        resetRecaptcha()
      })
  }

  return (
    <Formik
      initialValues={{
        phone: '',
        email: '',
        company: '',
        terms: true,
        password: '',
        passwordConfirm: '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        const phone = `+${getNumbers(values.phone)}`
        const data = {
          email: values.email,
          phone,
          password: values.password,
          password_2: values.passwordConfirm,
          title: values.company,
        }

        dispatch(setRegistrationForm(data))
        dispatch(setPhone(phone))
        handleSignIn(phone, setSubmitting)
      }}
      validationSchema={SchemaRegistration}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          isValid,
          dirty,
        } = props

        const attrs = {
          onChange: (e) => {
            console.log(e)
            if (e.target.name === 'email') {
              e.target.value = e.target.value.trim()
            }
            handleChange(e)
          },
          onBlur: handleBlur,
          errors,
          touched,
          size: 'big',
        }

        const formIsValid
          = isValid && dirty

        return (
          <Form noValidate onSubmit={handleSubmit}>
            <div className={isLaptopOrMobile ? 'mb-20' : 'mb-5'}>
              <Title center tag="h2"><Formatted id="form.signin.title" /></Title>
            </div>
            <FormGroup>
              <Field>
                {() => (
                  <FormField
                    {...attrs}
                    autoFocus
                    name="company"
                    placeholder={placeholderCompany}
                    value={values.company}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup>
              <Field>
                {() => (
                  <FormField
                    {...attrs}
                    name="email"
                    placeholder="Email"
                    type="email"
                    value={values.email.trim()}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup>
              <Field>
                {() => (
                  <FormFieldPhone
                    {...attrs}
                    value={values.phone}
                    placeholder={placeholderPhone}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup>
              <Field name="password">
                {() => (
                  <FormField
                    {...attrs}
                    name="password"
                    placeholder={placeholderCreatePassword}
                    type="password"
                    value={values.password}
                  />
                )}
              </Field>
            </FormGroup>
            <FormGroup>
              <Field>
                {() => (
                  <FormField
                    {...attrs}
                    name="passwordConfirm"
                    placeholder={placeholderRepeatPassword}
                    type="password"
                    value={values.passwordConfirm}
                  />
                )}
              </Field>
            </FormGroup>
            <div className={isLaptopOrMobile ? 'my-20' : 'my-4'}>
              <FormTerms
                {...attrs}
                isLaptopOrMobile={isLaptopOrMobile}
                name="terms"
                value={values.terms}
              />
            </div>
            <FormGroup>
              <Button
                defaultStyle="acsent"
                type="submit"
                size="big"
                full
                titleId="form.button.signin"
                isFetching={isFetchingPhoneAuth}
                isDisabled={isFetchingPhoneAuth || !formIsValid}
              />
            </FormGroup>
            <div ref={captchaRef}>
              <div id="recaptcha-container" />
            </div>

          </Form>
        )
      }}
    </Formik>
  )
}

export default injectIntl(Registration)
