import React from 'react'
import { components } from 'react-select'
import Select from 'components/form/Select'
import s from 'styled-components'

// components
import { FormCheckbox } from 'components/form'
import { Text } from 'components/typo'
import { Spinner } from 'components/base'

import { ReactComponent as CaretDownIcon } from 'assets/icons/dropdown-caret.svg'
import { ReactComponent as CaretDownIconSmall } from 'assets/icons/dropdown-caret-small.svg'
import Formatted from 'components/typo/Formatted'

const SelectContainer = s(Select)`
  .Select-menu-outer { max-height: initial !important; }
  .Select-menu { max-height: initial !important; }

  .select-group__value-container {
    height: 100%;
    padding-right: 0;

    input {
      position: absolute;
    }
  }

  .select-group__value-container--has-value {
    color: ${({ theme }) => theme.colors.black} !important;
  }

  .select-group__indicator {
    padding: 0 12px;
  }

  .select-group__clear-indicator {
    svg {
      width: 16px;
      height: 16px;
      background: ${({ theme }) => theme.colors.grey500};
      border-radius: 50%;
      transition: background-color 0.3s;
      fill: #fff;
    }

    &:hover svg {
      background: ${({ theme }) => theme.colors.black};
    }
  }

  ${({ toggler, theme }) => (toggler ? `
    .select-group__value-container {
      padding: 0;
    }

    .select-group__control.select-group__control--menu-is-open,
    .select-group__control.select-group__control--is-focused {
      background-color: unset;
      border-color: unset;

      .select-group__indicator svg {
        fill: ${theme.colors.black} !important;
      }

      .select-group__single-value {
        color: ${theme.colors.black};
      }
    }

    .select-group__menu {
      z-index: 100;
      margin-right: -17px;
    }

    .select-group__control {
      height: 24px;
      color: ${theme.colors.grey500};
      background-color: ${theme.colors.transparent};
      border: unset;
      cursor: pointer;
      transition: color 0.3s ease-in-out;

      &:hover {
        border-color: unset;
      }
    }

    .select-group__single-value {
      position: relative;
      top: unset;
      transform: unset;
      max-width: 100%;
      color: ${theme.colors.grey500};
    }

    .select-group__indicators {
      align-self: center;
    }

    .select-group__indicator {
      padding: 0;
    }
  ` : '')}
`

const getTogglerStyles = {
  control: (base) => ({
    ...base,
    background: 'unset !important',
  }),
}

const optionStyles = () => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0)',
  cursor: 'pointer',
})

const FormSelectSimple = (selectProps) => {
  const {
    errors,
    name,
    touched,
    onChange = () => {},
    labelNameId = '',
    labelName = 'label',
    toggler,
    isMulti = false,
    isLoading = false,
    isClearable = false,
    sizeSmall = false,
  } = selectProps

  const isError = errors[name] && touched[name]

  const SingleValue = (props) => {
    const { data } = props

    return isLoading
      ? <Spinner variant="small" />
      : (
        <components.SingleValue {...props}>
          <Text color="inherit">
            {labelNameId
              ? <Formatted id={data[labelNameId]} />
              : data[labelName]}
          </Text>
        </components.SingleValue>
      )
  }

  const MultiValue = (props) => {
    const {
      index,
      selectProps: { selectedOption },
    } = props

    const valueStr = selectedOption.reduce((acc, next) => {
      acc.push(next.label)
      return acc
    }, [])

    return index === selectedOption.length - 1
      ? (
        <Text textOverflow>
          {valueStr.join(', ')}
        </Text>
      ) : ''
  }

  const CustomScrollbarMenuList = (props) => (
    <components.MenuList {...props} />
  )

  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      {sizeSmall
        ? <CaretDownIconSmall />
        : <CaretDownIcon />}
    </components.DropdownIndicator>
  )

  const Option = (optionProps) => {
    const { data, isSelected } = optionProps

    return (
      <components.Option
        {...optionProps}
        getStyles={optionStyles}
      >
        <FormCheckbox
          radio={!isMulti}
          value={isSelected}
          onChange={() => null}
          onBlur={() => null}
          withLabel={false}
        />
        <Text
          className="ml-2"
          color={isSelected ? 'black' : 'grey500'}
          nowrap
          hoverColor="black"
        >
          {labelNameId
            ? <Formatted id={data[labelNameId]} />
            : data[labelName]}
        </Text>
      </components.Option>
    )
  }

  return (
    <SelectContainer
      {...selectProps}
      isMulti={isMulti}
      isError={isError}
      components={{
        Option,
        SingleValue,
        MultiValue,
        MenuList: CustomScrollbarMenuList,
        DropdownIndicator,
      }}
      closeMenuOnSelect={!isMulti}
      hideSelectedOptions={false}
      backspaceRemovesValue={false}
      isClearable={isMulti || isClearable}
      isSearchable={false}
      onChange={onChange}
      captureMenuScroll={false}
      styles={toggler ? getTogglerStyles : {}}
    />
  )
}

export default FormSelectSimple
