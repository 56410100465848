import React, { Fragment } from 'react'
import s from 'styled-components'
import cn from 'classnames'

import Text from 'components/typo/Text'
import Card from 'components/cards/Card'
import LazyImage from 'components/cards/LazyImage'
import {
  pbDefaultD,
  pbDefaultL,
  pDefaultM,
} from 'layout/Section'
import Formatted from 'components/typo/Formatted'

const getStyles = ({ theme }) => `
  padding: 30px;
  min-width: 400px;

  @media (max-width: 768px) {
    padding: 24px;
    min-width: 310px;
  }

  &.--shadow {
    box-shadow: 80px 60px 60px 0 rgba(113, 128, 150, 0.18);

    margin-bottom: ${pbDefaultD};

    @media (max-width: 768px) {
      margin-bottom: ${pbDefaultL};
    }

    @media (max-width: 600px) {
      margin-bottom: ${pDefaultM};
    }
  }

  &.--img {
    padding: 0;

    .body {
      padding: 30px;
      padding-top: 0 !important;

      @media (max-width: 768px) {
        padding: 24px;
      }
    }
  }

  .head {
    margin-bottom: 20px;

    @media (max-width: 768px) {
      margin-bottom: 16px;
    }
  }

  .head-img {
    height: 114px;
    margin-bottom: 8px;

    @media (max-width: 768px) {
      height: 96px;
    }
  }

  .text {
    max-width: 312px;

    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 28.8px;
    }
  }

  .head-label {
    margin-bottom: 30px;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  .label {
    display: inline-block;
    position: relative;
    transform: rotate(-5deg);
    padding: 10px 22px;
    border-radius: 100px;
    color: ${theme.colors.white};
    font-size: 48px;
    line-height: 1.4;
    letter-spacing: -0.3px;
    font-weight: 500;


    @media (max-width: 768px) {
      padding: 8px 16px;
      font-size: 32px;
    }

    &.--purple {
      background-color: ${theme.colors.purple};
    }

    &.--orange {
      background-color: ${theme.colors.orange};
    }

    &.--success {
      background-color: ${theme.colors.green};
    }
  }
`

const CardAdvantagesContainer = s(Card)`
  ${getStyles}
`

const CardAdvantage = ({
  text = '',
  texts = null,
  icon: Icon = null,
  img = null,
  label = null,
  bgColor = 'white',
  isMobile = false,
  isShadow = false,
}) => (
  <CardAdvantagesContainer
    className={cn('c-adv', {
      '--img': img,
      '--shadow': isShadow,
    })}
    bg={bgColor}
    br="xl"
  >
    {Icon && (
      <div className="head">
        <Icon />
      </div>
    )}
    {img && (
      <div className="head-img">
        <LazyImage
          width="100%"
          height="100%"
          objectFit="contain"
          src={img.src}
          srcSet={img.srcSet}
        />
      </div>
    )}
    {label && (
      <div className="head-label">
        <div className={cn('label', { [`--${label.color}`]: label.color })}>
          <Formatted id={label.text} />
        </div>
      </div>
    )}
    <div className="body">
      <Text className="text" size={isMobile ? 'medium' : 'xl'}>
        {text && <Formatted id={text} />}
        {texts && texts.map((t, i) => (
          <Fragment key={i}>
            {i !== 0 && <br />}
            <Formatted id={t} />
          </Fragment>
        ))}
      </Text>
    </div>
  </CardAdvantagesContainer>
)

export default CardAdvantage
