import React from 'react'
import styled from 'styled-components'

const FormFieldTitleContainer = styled.div`
  padding: 2px;
  font-size: 15px;
`

const FormFieldTitle = ({ children, ...otherProps }) => (
  <FormFieldTitleContainer {...otherProps}>
    {children}
  </FormFieldTitleContainer>
)

export default FormFieldTitle
