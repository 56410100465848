import React from 'react'
import s from 'styled-components'
import cn from 'classnames'

const CardLabelContainer = s.div`
  display: inline-block;
  padding: 10px 15px;
  color: #000;
  font-size: 14px;
  background-color: #fff;
  border-radius: 10px;

  &.--bg {
    &-light {
      background-color: #eae8ea;
    }
  }
`

const CardLabel = ({
  children, title, bg = null, ...otherProps
}) => (
  <>
    <CardLabelContainer
      {...otherProps}
      className={cn('ps-card-label', { [`--bg-${bg}`]: bg })}
    >
      {children || title}
    </CardLabelContainer>
  </>
)

export default CardLabel
