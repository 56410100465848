import React from 'react'
import styled from 'styled-components'

const FormErrorContainer = styled.div`
  margin-top: 4px;
  color: ${({ theme, isFocused }) => (isFocused ? theme.colors.grey500 : theme.colors.red)};
  font-size: 12px;
  line-height: 1.5;
  text-align: left;

  ${({ absolute }) => (absolute ? `
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: 4px;
  ` : '')}
`

const FormError = ({ children = null, ...otherProps }) => (
  <FormErrorContainer {...otherProps}>{children}</FormErrorContainer>
)

export default FormError
