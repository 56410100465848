import {
  FiltersActionTypes,
  FiltersNamesTypes,
  // CATEGORIES_INIT,
  // SEARCH_INIT,
  LIMIT_INIT,
  // OFFSET_INIT,
} from 'redux/types/FiltersTypes'
import { getObjectFromQueryString } from 'helpers/Query'
import { UserActionTypes } from 'redux/types/UserTypes'

const initSearch = getObjectFromQueryString(window.location.search) || {}

export const FILTERS_INIT_STATE = {
  [FiltersNamesTypes.CATEGORIES]: null,
  [FiltersNamesTypes.SEARCH]: null,
  [FiltersNamesTypes.LIMIT]: LIMIT_INIT,
  [FiltersNamesTypes.OFFSET]: null,
}

export const searcState = {
  [FiltersNamesTypes.CATEGORIES]: initSearch[FiltersNamesTypes.CATEGORIES] || FILTERS_INIT_STATE[FiltersNamesTypes.CATEGORIES],
  [FiltersNamesTypes.SEARCH]: initSearch[FiltersNamesTypes.SEARCH] || FILTERS_INIT_STATE[FiltersNamesTypes.SEARCH],
  [FiltersNamesTypes.LIMIT]: FILTERS_INIT_STATE.LIMIT,
  [FiltersNamesTypes.OFFSET]: initSearch[FiltersNamesTypes.OFFSET] || FILTERS_INIT_STATE[FiltersNamesTypes.OFFSET],
}

const filtersReducer = (state = { ...FILTERS_INIT_STATE }, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...FILTERS_INIT_STATE }

  case FiltersActionTypes.SET_FILTER: {
    const { name, value } = action.payload

    return {
      ...state,
      [name]: value,
    }
  }
  case FiltersActionTypes.SET_FILTERS: {
    return {
      ...state,
      ...action.payload,
    }
  }

  case FiltersActionTypes.RESET_ALL_FILTERS:
    return { ...FILTERS_INIT_STATE }

  default:
    return state
  }
}

export default filtersReducer
