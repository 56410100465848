import React from 'react'

import Title from 'components/typo/Title'
import Formatted from 'components/typo/Formatted'
import OffsetWrapper from './OffsetWrapper'

const SectionTitle = ({
  children,
  titleId = '',
  isCenter = true,
  tag = '',
  size = 'h-1',
  tAlign = '',
}) => (
  <OffsetWrapper tAlign={isCenter ? 'center' : tAlign}>
    <Title tag={tag} tagStyle={size}>
      {titleId ? <Formatted id={titleId} /> : children}
    </Title>
  </OffsetWrapper>
)

export default SectionTitle
