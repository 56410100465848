import React, { useContext, forwardRef } from 'react'
import cn from 'classnames'
import s, { ThemeContext } from 'styled-components'

const SwitcherContainer = s.div`
position: relative;
width: 40px;
height: 24px;
padding: 3px;
background-color: ${({ theme }) => theme.colors.grey300};
border-radius: 40px;
cursor: pointer;
transition: background-color 0.3s ease-in-out;

&.--hovered,
&:hover {
  background-color: ${({ theme }) => theme.colors.grey400};
}

&.--disabled {
  background-color: ${({ theme }) => theme.colors.grey200} !important;
  pointer-events: none;
}

&.--on {
  background-color: ${({ theme }) => theme.colors.green};

  &:hover {
    background-color: ${({ theme }) => theme.colors.greent80};
  }

  &.--disabled {
    background-color: ${({ theme }) => theme.colors.greent40} !important;
  }
}

&.--on .w-switcher-toggle {
  left: 100%;
  color: ${({ theme }) => theme.colors.white};
  transform: translateX(-100%);
}

.w-switcher {
  &-inner {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &-toggle {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 100%;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;
    font-size: 12px;
    text-transform: capitalize;
    background-color: #fff;
    border-radius: 18px;
    transition: transform 0.3s ease-in-out, left 0.3s ease-in-out;
  }
}

.fake-input {
  position: absolute;
  left: -9999px;
  visibility: hidden;
}
`

const Switcher = forwardRef(
  (
    {
      onLabel = '',
      offLabel = '',
      className = '',
      value = false,
      isDisabled = false,
      readOnly = false,
      isHovered = false,
      ...attr
    },
    ref
  ) => {
    const theme = useContext(ThemeContext)

    const handleClick = (e) => {
      if (isDisabled) {
        e.preventDefault()
      }
      e.stopPropagation()
    }

    return (
      <SwitcherContainer
        ref={ref}
        type="button"
        theme={theme}
        className={cn('w-switcher', {
          '--on': value,
          '--disabled': isDisabled,
          '--hovered': isHovered,
        }, className)}
        onClick={handleClick}
      >
        <label className="w-switcher-inner">
          <input
            {...attr}
            value={value}
            type="checkbox"
            className="fake-input"
            disabled={isDisabled}
            readOnly={readOnly || isDisabled}
          />
          <div className="w-switcher-toggle">
            {onLabel || offLabel ? (
              <span className="w-switcher-label">
                {value ? onLabel : offLabel}
              </span>
            ) : (
              ''
            )}
          </div>
        </label>
      </SwitcherContainer>
    )
  }
)

Switcher.displayName = 'Switcher'

export default Switcher
