import gsap, {
  TweenLite,
  TweenMax,
  TimelineLite,
  TimelineMax,
} from 'gsap'
import { CSSRulePlugin } from 'gsap/CSSRulePlugin'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)
gsap.registerPlugin(CSSRulePlugin)

export {
  gsap,
  TweenMax,
  TweenLite,
  TimelineLite,
  TimelineMax,
}
