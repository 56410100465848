import React from 'react'
import s from 'styled-components'
import cn from 'classnames'

import useResponsive from 'components/hooks/useResponsive'
import SubscriptionItem from './SubscriptionItem'

const SubscriptionsListContainer = s.div`
  display: flex;
  margin: 0 -10px;

  & > * {
    flex: 1;
    padding: 0 10px;
  }

  &.--mob {
    flex-direction: column;
    align-items: center;
    margin: unset;
  }

  &.--mob > * {
    flex: unset;
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
    padding: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
const SubscriptionsList = ({
  children,
  subscriptions = [],
  ...props
}) => {
  const { isLaptopBigOrMobile, isLaptopOrMobile } = useResponsive()

  return (
    <SubscriptionsListContainer className={cn('subscriptions', { '--mob': isLaptopBigOrMobile })}>
      {subscriptions.map((item) => (
        <div key={item.id}>
          <SubscriptionItem
            isLaptopOrMobile={isLaptopOrMobile}
            {...item}
            {...props}
          />
        </div>
      ))}
    </SubscriptionsListContainer>
  )
}

export default SubscriptionsList
