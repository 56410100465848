import React from 'react'

import { ButtonRound } from 'components/base'
import { ReactComponent as IconAdd } from 'assets/icons/add.svg'

const ButtonRoundAdd = ({ onClick, ...otherProps }) => (
  <ButtonRound
    {...otherProps}
    padding="normal"
    bgColor="light"
    onClick={onClick}
    renderIcon={() => <IconAdd />}
  />
)

export default ButtonRoundAdd
