import React, { forwardRef, useContext } from 'react'
import s, { ThemeContext } from 'styled-components'
import cn from 'classnames'

import { Link, Text } from 'components/typo'
import { Spinner } from './index'

const styles = ({
  size,
  theme,
  defaultStyle,
  fill,
  fillHover,
  p,
  svgWidth,
  svgHeight,
  bgHover,
  isFetching,
}) => {
  const btnTheme = theme.components.buttonRound
  const sizes = btnTheme.sizes[size] || btnTheme.sizes.normal
  const sizesMob = size === 'xl' ? btnTheme.sizes.big : size === 'big' ? btnTheme.sizes.normal : size === 'normal' ? btnTheme.sizes.small : btnTheme.sizes.small
  const styles = btnTheme.styles[defaultStyle] || btnTheme.styles.light

  return `
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: ${sizes.size};
    height: ${sizes.size};
    padding: ${p || sizes.padding};
    background-color: ${styles.bg};
    color: ${styles.color || ''};
    border-radius: 50%;
    transition: background-color 0.3s ease-in-out;
    backdrop-filter: ${styles.blur || 'unset'};

    @media (max-width: 768px) {
      width: ${sizesMob.size};
      height: ${sizesMob.size};
      padding: ${p || sizesMob.padding};
    }

    svg {
      width: ${svgWidth || '100%'};
      height: ${svgHeight || '100%'};
      transition: fill 0.3s ease-in-out;
      fill: ${fill ? theme.colors[fill] : styles.fill} !important;
    }

    &.--hovered,
    &:hover {
      background-color: ${bgHover && theme[bgHover] ? theme[bgHover] || bgHover : styles.bgHover || ''};

      svg {
        fill: ${fillHover ? theme.colors[fillHover] : styles.fillHover} !important;
      }
    }

    ${isFetching ? `
      & > * {
        opacity: 0;
      }
    ` : ''}
  `
}

const ButtonRoundDivContainer = s.button`
  ${(props) => styles(props)}
`

const ButtonRoundLinkContainer = s(Link)`
  ${(props) => styles(props)}
`

const ButtonRound = forwardRef(({
  renderIcon = null,
  type = 'button',
  className,
  as,
  children,
  title,
  defaultStyle = 'light',
  size = 'normal',
  isHovered = false,
  isFetching,
  isDisabled,
  blured = false,
  ...otherProps
}, ref) => {
  const theme = useContext(ThemeContext)
  const classes = cn('w-button-round', {
    '--hovered': isHovered,
    '--blured': blured,
  }, className)
  const props = {
    theme,
    defaultStyle,
    size,
    className: classes,
    isDisabled,
    isFetching,
    ...otherProps,
  }
  const Component = as === 'link'
    ? ButtonRoundLinkContainer
    : ButtonRoundDivContainer

  return (
    <Component
      ref={ref}
      type={type}
      disabled={isDisabled}
      {...props}
    >
      {children}
      {title && (
        <Text
          fw="sb"
          size="medium"
          className="--h5"
          color="white"
        >
          {title}
        </Text>
      )}
      {renderIcon && renderIcon()}
      {isFetching && <Spinner size="small" fillColor="white" />}
    </Component>
  )
})

ButtonRound.displayName = 'ButtonRound'

export default ButtonRound
