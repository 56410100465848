import React from 'react'

import { ButtonRound } from 'components/base'

import { ReactComponent as IconTrash } from 'assets/icons/trash.svg'

const CardBtnTrash = ({ isHovered, ...props }) => (
  <ButtonRound
    {...props}
    defaultStyle="settings"
    size="settings"
    fillHover="black"
    fill={isHovered ? 'grey500' : null}
  >
    <IconTrash
      width={23}
      height={23.5}
    />
  </ButtonRound>
)

export default CardBtnTrash
