const scrollObserver = (selector, callback, {
  root,
  rootMargin,
  threshold,
}) => {
  const observerElements = document.querySelectorAll(selector)
  const observerOptions = {
    root: root || null,
    rootMargin: rootMargin || '-25% 0px',
    threshold: threshold || 0,
  }

  const Observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          // Run GSAP animation tween.
          callback(entry)
          // remove the observer after it's triggered
          Observer.unobserve(entry.target)
        }
      })
    },
    observerOptions
  )

  observerElements.forEach((el) => {
    Observer.observe(el)
  })

  return observerElements
}

export default scrollObserver
