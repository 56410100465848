export const ClientsActionTypes = {
  SET_FETCHING_CLIENTS: 'SET_FETCHING_CLIENTS',
  FETCH_CLIENTS_ERROR: 'FETCH_CLIENTS_ERROR',

  CLEAR_CLIENTS_HOME: 'CLEAR_CLIENTS_HOME',
  SET_CLIENTS_HOME_IS_EMPTY: 'SET_CLIENTS_HOME_IS_EMPTY',
  FETCH_CLIENTS_HOME_SUCCESS: 'FETCH_CLIENTS_HOME_SUCCESS',

  SET_FETCHING_GET_CLIENT_BY_ID: 'SET_FETCHING_GET_CLIENT_BY_ID',
  FETCH_GET_CLIENT_BY_ID_SUCCESS: 'FETCH_GET_CLIENT_BY_ID_SUCCESS',
}
