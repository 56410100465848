// navigation icons
import { ReactComponent as IconOrders } from 'assets/icons/orders.svg'
import { ReactComponent as IconCatalog } from 'assets/icons/catalog.svg'
import { ReactComponent as IconMailings } from 'assets/icons/subscriptions.svg'
import { ReactComponent as IconClients } from 'assets/icons/clients.svg'
import { ReactComponent as IconSettings } from 'assets/icons/settings.svg'
import { ReactComponent as IconAnalitics } from 'assets/icons/analitics.svg'
import { ReactComponent as IconIntegration } from 'assets/icons/refresh.svg'
import { ReactComponent as IconHelp } from 'assets/icons/chat.svg'

import * as PathsTypes from 'constants/PathsTypes'

export const NAV = [
  {
    id: 1,
    label: 'sidebar.nav.orders',
    icon: IconOrders,
    path: PathsTypes.INTERFACE_ORDERS,
    count: true,
  },
  {
    id: 2,
    label: 'sidebar.nav.catalog',
    icon: IconCatalog,
    path: PathsTypes.INTERFACE_CATALOG,
  },
  {
    id: 3,
    label: 'sidebar.nav.mailings',
    icon: IconMailings,
    path: PathsTypes.INTERFACE_MAILINGS,
  },
  {
    id: 4,
    label: 'sidebar.nav.clients',
    icon: IconClients,
    path: PathsTypes.INTERFACE_CLIENTS,
  },
  {
    id: 5,
    label: 'sidebar.nav.settings',
    icon: IconSettings,
    path: PathsTypes.INTERFACE_SETTINGS,
  },
  {
    id: 6,
    label: 'sidebar.nav.analytics',
    icon: IconAnalitics,
    path: PathsTypes.INTERFACE_ANALITICS,
    bage: {
      variant: 'purple',
      titleId: 'sidebar.nav.pro',
    },
    modalName: 'pro-analitics',
  },
  {
    id: 7,
    label: 'sidebar.nav.integrations',
    icon: IconIntegration,
    path: PathsTypes.INTERFACE_INTEGRATIONS,
    bage: {
      variant: 'purple',
      titleId: 'sidebar.nav.pro',
    },
    modalName: 'pro-integrations',
  },
  {
    id: 8,
    label: 'sidebar.nav.support',
    icon: IconHelp,
    path: '/help#help',
    modalName: 'problems',
  },
]
