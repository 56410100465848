import React, { useState, useEffect } from 'react'
import s from 'styled-components'
import { checkCorrectUrl } from 'helpers/System'

const CardImageContainer = s.img`
  display: block;
  width: ${({ width }) => width || '100%'};
  max-width: ${({ maxwidth }) => (maxwidth || 'unset')};
  height: ${({ height }) => height || 'auto'};
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : 'unset')};

  ${({ br }) => (br ? `border-radius: ${br}` : '')}
`

const CardImage = ({
  src,
  placeholder = null,
  ...props
}) => {
  const [ img, setImg ] = useState(src)

  useEffect(() => {
    setImg(src || null)
  }, [ src ])

  const onImgUrlError = () => setImg(null)

  return (
    <CardImageContainer
      {...props}
      src={checkCorrectUrl(img) || placeholder}
      onError={onImgUrlError}
    />
  )
}

export default CardImage
