import React from 'react'
import { FormattedMessage } from 'react-intl'

import Text from './Text'

const TextFormatted = ({
  titleId = '',
  values = {},
  ...otherProps
}) => (
  <Text {...otherProps}>
    <FormattedMessage id={titleId} values={values} />
  </Text>
)

export default TextFormatted
