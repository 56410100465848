import React, { useMemo } from 'react'
import s from 'styled-components'

import { FormHeading } from 'components/form'
import { AnimateHeight } from 'components/base'
// import { ListItem } from 'components/list'
import { Box } from 'layout'
import Switcher from 'components/Switcher'
import TextFormatted from 'components/typo/TextFormatted'

const FormSwitchDropdownItemContainer = s.div`
  position: relative;
  display: block;
  padding-top: 34px;
  padding-bottom: ${({ value }) => (value ? 0 : 22)}px;
  border-bottom-color: ${({ theme, bc }) => theme.colors[bc]};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  transition: padding 0.3s linear;

  .drop-content {
    padding-top: 16px;
  }
`

const FormSwitchDropdownItem = ({
  title,
  children,
  name,
  value,
  label,
  handleChange,
  reversed = false,
}) => {
  const toggleEventHandler = () => {
    handleChange()
  }

  const valueMapped = useMemo(() => (reversed ? !value : value), [ reversed, value ])

  return (
    <FormSwitchDropdownItemContainer
    // className={!value ? 'pb-4' : 'pb-0'}
      bc={valueMapped ? 'transparent' : 'grey200'}
      value={valueMapped}
    >
      <FormHeading
        nomargin
        color={value || reversed ? 'black' : 'grey500'}
        title={title}
        onClick={toggleEventHandler}
      >
        <Box ai="center">
          {label && (
            <TextFormatted
              className="mr-3"
              color={value ? 'black' : 'grey500'}
              titleId={label}
            />
          )}
          <Switcher
            name={name}
            value={value}
            onChange={handleChange}
          />
        </Box>
      </FormHeading>
      <AnimateHeight show={valueMapped}>
        <Box display="block" className="drop-content">
          {children}
        </Box>
      </AnimateHeight>
    </FormSwitchDropdownItemContainer>
  )
}

export default FormSwitchDropdownItem
