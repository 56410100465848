import React from 'react'

import { Box } from 'layout'

import { Text } from 'components/typo'
import Formatted from 'components/typo/Formatted'

const CardPrice = ({
  rawPrice,
  price,
  amount = null,
  isActive,
  isHovered,
}) => (
  <Box jc="flex-end">
    {amount && amount >= 1 ? (
      <Text
        tag="span"
        fw="m"
        color="grey500"
      >
        {Number(amount).toFixed()}
        {' '}
        x
        {' '}
      </Text>
    ) : ''}
      &nbsp;
    {rawPrice && (
      <Text
        noPointers
        td="line-through"
        className="mr-1"
        nowrap
        color="grey500"
      >
        {rawPrice}
      </Text>
    )}
    <Text
      noPointers
      color={isHovered ? 'grey700' : isActive ? 'black' : 'grey500'}
      fw="m"
      nowrap
      transitionOn
    >
      {price}
      {' '}
      <Formatted id="currency.name" />
    </Text>
  </Box>
)

export default CardPrice
