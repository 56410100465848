import React, { useState, useCallback } from 'react'
import s from 'styled-components'

import { ButtonRoundBack } from 'components/buttons'
import { Box } from 'layout'

import { FormField } from 'components/form'
import { urlRegExp } from 'helpers/RegExp'

import { ReactComponent as IconLink } from 'assets/icons/link.svg'
import { OutsideAlerter } from 'utils'

const FormFieldPopupContainer = s(OutsideAlerter)`
  position: absolute;
  top: ${({ top }) => top || '100%'};
  right: 0;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.whitet90};
  border: ${({ theme }) => `0.5px solid ${theme.colors.grey300}`};
  border-radius: 10px;
  box-shadow: 4px 10px 25px rgba(113, 128, 150, 0.12);
  backdrop-filter: blur(50px);
`

const FormFieldPopup = ({
  onChange = () => {},
  value = '',
  type = 'text',
  onOutside,
  onSubmit,
  socialId,
  ...otherProps
}) => {
  const [ isError, setError ] = useState(false)

  const isValid = (value) => (value === '' || !!urlRegExp.test(value))

  const handleSubmit = useCallback((e) => {
    setError(false)

    if (isValid(value)) {
      onSubmit(value, socialId)
    }
    else {
      setError(true)
    }
  }, [ value, onSubmit, socialId ])

  return (
    <FormFieldPopupContainer
      onClickOutside={onOutside}
      onClick={(e) => e.stopPropagation()}
    >
      <Box p="24px" ai="center">
        <FormField
          preventSubmit
          onChange={onChange}
          type={type}
          value={value}
          placeholder="Добавте ссылку"
          autoFocus
          onBlur={handleSubmit}
          iconWidth={20}
          iconHeight={20}
          icon={IconLink}
          fieldError={isError}
          fieldTouched
          onSubmit={handleSubmit}
          {...otherProps}
        />
        <ButtonRoundBack
          className="ml-3"
          forward
          onClick={handleSubmit}
        />
      </Box>
    </FormFieldPopupContainer>
  )
}

export default FormFieldPopup
