import React, { forwardRef } from 'react'
import s from 'styled-components'

import { Button } from 'components/base'

const StickyButtonContainer = s.div`
  position: absolute;
  z-index: 998;

  &.--fixed {
    position: fixed;
    top: unset !important;
    bottom: 40px;

    @media (max-width: 768px) {
      bottom: 20px;
    }
  }
`

const StickyButton = forwardRef(({ onClick, style }, ref) => (
  <StickyButtonContainer ref={ref} style={style} className="w-sticky-btn --init">
    <Button
      titleId="button.requestCall"
      className="el-blink"
      size="xl"
      defaultStyle="green-blur"
      blur
      isRoundedBorders
      onClick={onClick}
    />
  </StickyButtonContainer>
))

StickyButton.displayName = 'StickyButton'

export default StickyButton
