import React from 'react'

import DropdownMenu from 'components/dropdown/DropdownMenu'
import DropdownMenuItem from 'components/dropdown/DropdownMenuItem'

const CompanyLogoMenu = ({
  onAccount,
  onSubscription,
  onLogout,
  position,
}) => (
  <DropdownMenu position={position}>
    <DropdownMenuItem dark onClick={onAccount} title="sidebar.menu.account" />
    <DropdownMenuItem dark onClick={onSubscription} title="sidebar.menu.rate" />
    <DropdownMenuItem dark onClick={onLogout} title="sidebar.menu.exit" />
  </DropdownMenu>
)

export default CompanyLogoMenu
