import React, {
  useEffect,
  useState,
  useCallback,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'

// components
import { Container, Box } from 'layout'
import { Title } from 'components/typo'
import {
  Modal,
  ModalContent,
  ModalActions,
} from 'components/modal'
import Logo from 'components/Logo'
import { Spinner, Tabs } from 'components/base'
import Demos from 'components/Demos'

import { fetchDemosHome } from 'redux/actions/DemosActions'

export const TabsModel = [
  {
    id: 'iPhone',
    title: 'iPhone',
    idx: 0,
    prefix: 'ios',
  },
  {
    id: 'Android',
    title: 'Android',
    idx: 1,
    prefix: 'android',
  },
]

const ModalDemos = ({
  closeModal,
  isLaptopOrMobile,
  modal = {},
}) => {
  const { activeTab: activeDefaultTab = null } = modal
  const [ activeTab, setActiveTab ] = useState(activeDefaultTab || TabsModel[0].id)

  const dispatch = useDispatch()
  const { homeList: demos = [], isFetching = false } = useSelector((state) => state.demos)

  useEffect(() => {
    if (!demos.length) {
      dispatch(fetchDemosHome())
    }
    // eslint-disable-next-line
  }, [])

  const handleTab = useCallback((id) => {
    setActiveTab(id)
  }, [ setActiveTab ])

  return (
    <Modal flex size="full">
      <ModalActions closeModal={closeModal} />
      <ModalContent
        display="flex"
        column
        p={isLaptopOrMobile ? '46px 32px' : '46px'}
      >
        <Box
          jc="center"
          ai="center"
          flex
          direction="column"
        >
          <Box self="stretch">
            <div>
              <Box jc="center" className={isLaptopOrMobile ? 'mb-3' : 'mb-5'}>
                <Logo
                  defaultStyle="black"
                  notitle
                  nopointers
                />
              </Box>
              <div className={isLaptopOrMobile ? 'mb-4' : 'mb-7'}>
                <Title center tag="h2">Демо-приложения</Title>
              </div>
              <Box
                jc="center"
                className={cn('subscriptions__tabs', {
                  'mb-6': !isLaptopOrMobile,
                  'mb-4 --mob': isLaptopOrMobile,
                })}
              >
                <Tabs
                  items={TabsModel}
                  active={activeTab}
                  onToggle={handleTab}
                />
              </Box>
            </div>
          </Box>
        </Box>
        <Box width="100%">
          <Container>
            {isFetching ? <Spinner relative height="30vh" />
              : <Demos demos={demos} prefix={TabsModel.find((i) => i.id === activeTab).prefix} />}
          </Container>
        </Box>
      </ModalContent>
    </Modal>
  )
}

export default ModalDemos
