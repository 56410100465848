import { ClientsActionTypes } from 'redux/types/ClientsTypes'
import { UserActionTypes } from 'redux/types/UserTypes'

const INITIAL_STATE = {
  name: 'clients',
  homeList: [],
  homeListIsEmpty: false,
  isFetching: false,
}

const clientsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE }

  case ClientsActionTypes.SET_FETCHING_CLIENTS:
    return {
      ...state,
      isFetching: action.payload,
    }

  case ClientsActionTypes.FETCH_CLIENTS_HOME_SUCCESS:
    return {
      ...state,
      homeList: action.payload,
    }

  case ClientsActionTypes.SET_CLIENTS_HOME_IS_EMPTY:
    return {
      ...state,
      homeListIsEmpty: action.payload,
    }

  case ClientsActionTypes.CLEAR_CLIENTS_HOME:
    return {
      ...state,
      homeList: [],
      homeListIsEmpty: false,
    }

  default:
    return state
  }
}

export default clientsReducer
