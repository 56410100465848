import React from 'react'

import ButtonArrow from 'components/buttons/ButtonArrow'
import Section from 'layout/Section'

import { ReactComponent as IconDelivery1 } from 'assets/images/home/delivery/d-icon-1.svg'
import { ReactComponent as IconDelivery2 } from 'assets/images/home/delivery/d-icon-2.svg'
import { ReactComponent as IconDelivery3 } from 'assets/images/home/delivery/d-icon-3.svg'
import { ReactComponent as IconDelivery4 } from 'assets/images/home/delivery/d-icon-4.svg'
import { ReactComponent as IconDelivery5 } from 'assets/images/home/delivery/d-icon-5.svg'
import { ReactComponent as IconDelivery6 } from 'assets/images/home/delivery/d-icon-6.svg'
import { ReactComponent as IconDelivery7 } from 'assets/images/home/delivery/d-icon-7.svg'

import imgAd4 from 'assets/images/home/advantages/ad-4.png'
import imgAd4x2 from 'assets/images/home/advantages/ad-4@2x.png'

import { EL_ANIMATE_DELIVERY } from 'constants/AnimationsTypes'
import Formatted from 'components/typo/Formatted'
import Hero from './Hero'
import ImageBlock from './ImageBlock'

const SectionDelivery = ({
  isLaptopOrMobile,
  isLaptopBigOrMobile = false,
  onRequest = () => {},
}) => (
  <Section
    isRelative
    isOverflowOff
    isBrBottomOff
    br="top"
    bg="purple"
    m={!isLaptopOrMobile ? '60px' : '32px'}
    className={isLaptopOrMobile ? 'pt-0' : ''}
  >
    <Hero
      isLaptopBigOrMobile={isLaptopBigOrMobile}
      isLaptopOrMobile={isLaptopOrMobile}
      // imageAbsolute
      actions={(
        <ButtonArrow
          defaultStyle="blured"
          size="big"
          titleId="button.contact.us"
          onClick={onRequest}
        />
      )}
      title={(
        <>
          <Formatted id="home.heading.tell1" />
          {' '}
          <i className="dec-under --s"><Formatted id="home.heading.tell2" /></i>
        </>
      )}
      rightContent={(
        <ImageBlock
          src={imgAd4}
          srcX2={imgAd4x2}
          className={`img-delivery ${EL_ANIMATE_DELIVERY}`}
        >
          <div className="img-icon --del-i-1">
            <IconDelivery1 />
          </div>
          <div className="img-icon --del-i-2">
            <IconDelivery2 />
          </div>
          <div className="img-icon --del-i-3">
            <IconDelivery3 />
          </div>
          <div className="img-icon --del-i-4">
            <IconDelivery4 />
          </div>
          <div className="img-icon --del-i-5">
            <IconDelivery5 />
          </div>
          <div className="img-icon --del-i-6">
            <IconDelivery6 />
          </div>
          <div className="img-icon --del-i-7">
            <IconDelivery7 />
          </div>
        </ImageBlock>
      )}
    />
  </Section>
)

export default SectionDelivery
