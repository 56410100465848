import { CatalogActionTypes } from 'redux/types/CatalogTypes'
import { UserActionTypes } from 'redux/types/UserTypes'

const INITIAL_STATE = {
  name: 'catalog',
  list: [],
}

const catalogReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE }

  case CatalogActionTypes.SET_FETCHING_ADD_CATALOG_POSITION:
    return {
      ...state,
      isFetchingAdd: action.payload,
    }

  case CatalogActionTypes.FETCH_ADD_CATALOG_POSITION_SUCCESS:
    return {
      ...state,
      list: action.payload,
    }

  default:
    return state
  }
}

export default catalogReducer
