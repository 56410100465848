import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import ruLocale from 'date-fns/locale/ru'
import s from 'styled-components'

import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg'
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg'

import 'react-datepicker/dist/react-datepicker.css'
import 'assets/scss/components/_custom-datepicker.scss'

import { Box } from 'layout'
import { Text } from 'components/typo'

// helpers
// import { formatDateDiary } from 'helpers/Date'
registerLocale('ru', ruLocale) // register it with the name you want

const getYear = (date) => date.getFullYear()
const getMonth = (date) => date.getMonth()

const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
]

const DatepickerContainer = s(DatePicker)`
  .arrow {
    color: ${({ theme }) => theme.colors.grey500};
    transition: color 0.3s ease-in-out;

    svg {
      fill: currentColor;
    }
  }
`

const renderCustomHeader = ({
  date,
  // changeYear,
  // changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const year = getYear(date)
  const month = months[getMonth(date)]

  return (
    <Box p="16px" ai="center" jc="space-between">
      <button
        className="arrow arrow-prev"
        type="button"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <ChevronLeft />
      </button>

      <Box>
        <Text fw="m">{month}</Text>
        &nbsp;
        <Text color="grey500">{year}</Text>
      </Box>

      <button
        className="arrow arrow-next"
        type="button"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <ChevronRight />
      </button>
    </Box>
  )
}

const Datepicker = ({
  dateFormat = 'd MMMM, yyyy H:mm',
  timeIntervals = 15,
  timeFormat = 'HH:mm',
  showTimeSelect = false,
  customInput = null,
  inline = true,
  ...props
}) => (
  <div className="custom-datepicker">
    <DatepickerContainer
      {...props}
      inline={inline}
      customInput={customInput}
      showTimeSelect={showTimeSelect}
      timeFormat={timeFormat}
      timeIntervals={timeIntervals}
      timeCaption="Время"
      locale="ru"
      dateFormat={dateFormat}
      disabledKeyboardNavigation
      renderCustomHeader={renderCustomHeader}
    // showMonthDropdown
    // showYearDropdown
    // adjustDateOnChange
    />
  </div>
)

export default Datepicker
