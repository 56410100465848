import React from 'react'
import s from 'styled-components'
import cn from 'classnames'

const ModalContentWrapperContainer = s.div`
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`

const ModalContentWrapper = ({
  className,
  children,
  ...otherProps
}) => {
  const classes = cn('w-content-wrapper', className)

  const props = {
    ...otherProps,
    className: classes,
  }

  return (
    <ModalContentWrapperContainer className={classes} {...props}>
      {children}
    </ModalContentWrapperContainer>
  )
}

export default ModalContentWrapper
