import React from 'react'

import { EmailLink } from 'components/socials'
import Formatted from 'components/typo/Formatted'

const ModalSidebarBannerHelp = () => (
  <div>
    <Formatted id="modals.banner.help.description.1" />
    {' '}
    <EmailLink />
    {' '}
    <Formatted id="modals.banner.help.description.2" />
    .
    {' '}
    <Formatted id="modals.banner.help.description.3" />
  </div>
)

export default ModalSidebarBannerHelp
