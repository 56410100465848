import React from 'react'
import { Link } from 'components/typo'
import s from 'styled-components'
import cn from 'classnames'

// images
import { ReactComponent as IconLogo } from 'assets/images/logo.svg'

// constants
import * as PathsTypes from 'constants/PathsTypes'

const styles = ({
  theme,
  isSize,
  $isNoMobile,
  defaultStyle = 'light',
  animated = false,
}) => {
  const componentTheme = theme.components.logo
  const sizes = componentTheme.sizes[isSize] || componentTheme.sizes.normal
  const mobSizes = componentTheme.sizes.small
  const styles = componentTheme.styles[defaultStyle] || componentTheme.styles.light

  return `
    display: inline-flex;
    align-items: center;

    svg {
      width: ${sizes.width};
      height: ${sizes.height};
      fill: ${styles.fill};
      transition: 0.3s fill ease-in-out;

      @media (max-width: 768px) {
        width: ${$isNoMobile ? sizes.width : mobSizes.width};
        height: ${$isNoMobile ? sizes.height : mobSizes.height};
      }
    }

    span {
      color: ${styles.color};
      transition: 0.3s color ease-in-out;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }

    svg + span {
      margin-left: ${isSize === 'small' ? '12px' : '15px'};
    }

    &:hover {
      span {
        color: ${styles.colorHover || styles.color};
      }

      svg {
        fill: ${styles.fillHover || styles.fill};
      }
    }

    ${animated
    ? `
        position: relative;
        animation: scale 1.5s linear infinite;
      `
    : ''}
    `
}

const LogoContainer = s.div`
  ${(props) => styles(props)}
`

const Logo = ({
  notitle = false,
  nopointers = false,
  className = '',
  size = 'normal',
  isNoMobile = false,
  ...otherProps
}) => {
  const logo = (
    <LogoContainer
      $isNoMobile={isNoMobile}
      isSize={size}
      {...otherProps}
      className={cn('w-logo', className)}
    >
      <IconLogo alt="Wiby" />
      {notitle ? '' : <span className="text-fwsb">Wiby</span>}
    </LogoContainer>
  )

  const handleClick = (e) => {
    if (window.location.pathname === '/') {
      e.preventDefault()
      window.location.href = '/'
    }
  }

  return nopointers ? logo : (
    <Link noTransition onClick={handleClick} display="inline-flex" to={PathsTypes.HOME}>
      {logo}
    </Link>
  )
}

export default Logo
