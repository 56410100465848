import React from 'react'
import s from 'styled-components'

// components
import { Input } from 'components/form'
import { ReactComponent as IconCarret } from 'assets/icons/dropdown-caret.svg'

const FormInputDropdownContainer = s.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .toggler {
    position: absolute;
    top: 50%;
    right: 0;
    right: 12px;
    color: ${({ theme, isActive }) => (isActive ? theme.colors.black : theme.colors.grey500)};
    transform: translateY(-50%);
    pointer-events: none;

    svg {
      position: relative;
      transform: rotateX(${({ isActive }) => (isActive ? '180deg' : '0deg')});
    }
  }

  input {
    padding-right: 44px;
  }
`

const FormInputDropdown = (props) => {
  const {
    children,
    name = '',
    isActive = false,
    ...otherProps
  } = props
  return (
    <FormInputDropdownContainer isActive={isActive}>
      <Input
        {...otherProps}
        readOnly
        name={name}
      />
      <span className="toggler">
        <IconCarret fill="currentColor" />
      </span>
      {children}
    </FormInputDropdownContainer>
  )
}

export default FormInputDropdown
