import React from 'react'

import Container from 'layout/Container'
import Section from 'layout/Section'

import image1 from 'assets/images/stop-adv/i-1.png'
import image2 from 'assets/images/stop-adv/i-2.png'
import image3 from 'assets/images/stop-adv/i-3.png'

import image1x2 from 'assets/images/stop-adv/i-1@2x.png'
import image2x2 from 'assets/images/stop-adv/i-2@2x.png'
import image3x2 from 'assets/images/stop-adv/i-3@2x.png'

import ScrollList from './ScrollList'
import SectionTitle from './SectionTitle'
import CardAdvantage from './CardAdvantage'

const LIST = [
  {
    img: {
      src: image1,
      srcSet: `${image1x2} 2x`,
    },
    texts: [ 'home.get.1.1', 'home.get.1.2', 'home.get.1.3' ],
  },
  {
    img: {
      src: image2,
      srcSet: `${image2x2} 2x`,
    },
    text: 'home.get.2.1',
  },
  {
    img: {
      src: image3,
      srcSet: `${image3x2} 2x`,
    },
    text: 'home.get.3.1',
  },

]

const SectionAlreadyGet = () => (
  <Section>
    <Container>
      <SectionTitle titleId="home.heading.stopWait" />
      <ScrollList
        isFullHeight
        items={LIST}
        renderCard={(props) => <CardAdvantage bgColor="grey100" {...props} />}
      />
    </Container>
  </Section>
)

export default SectionAlreadyGet
