import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IntlProvider } from 'react-intl'
import {
  Route,
  Switch,
  useHistory,
} from 'react-router-dom'

// components
import { ModalWrapper, Notifier } from 'components/base'

// constants
import * as PathsTypes from 'constants/PathsTypes'
import { getScrollbarWidth } from 'utils'
import { setAppLang, setBrowserScrollWidth } from 'redux/actions/SystemActions'

// // services
import StorageService from 'services/StorageService'

// styles
import './App.scss'
import HomePage from 'pages/HomePage/HomePage'
import PoliticPage from 'pages/PoliticPage'
import LicensePage from 'pages/LicensePage'
import PartnersPage from 'pages/PartnersPage'
import ServicesPage from 'pages/ServicesPage'
import Interface from 'layout/Interface/Interface'
import ErrorPage from 'pages/ErrorPage'
import { PrivateRoute } from 'layout/PrivateRouter'
import { clearHistoryOnRefreshPage } from 'helpers/History'
import { LOCALES } from 'i18n/locales'
import { getLang, getLocaleMessages } from 'i18n/helpers'

// const locale = getLang()
// const locale = LOCALES.EN // FOR DEBUG, remove after integration translations

const App = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { profile } = useSelector((state) => state.user)
  const { locale } = useSelector((state) => state.system)

  useEffect(() => {
    clearHistoryOnRefreshPage(history)
    dispatch(setBrowserScrollWidth(getScrollbarWidth()))
    dispatch(setAppLang(getLang()))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    StorageService.setUserSession(profile)
    // eslint-disable-next-line
  }, [profile])

  return locale && (
    <IntlProvider
      messages={getLocaleMessages(locale)}
      locale={locale}
      defaultLocale={LOCALES.RU}
      key={locale}
    >
      <div className="app">
        <Switch>
          <Route exact path={PathsTypes.HOME} component={HomePage} />
          <Route exact path={PathsTypes.POLITIC} component={PoliticPage} />
          <Route exact path={PathsTypes.LICENSE} component={LicensePage} />
          <Route exact path={PathsTypes.PARTNERS} component={PartnersPage} />
          <Route exact path={PathsTypes.SERVICES} component={ServicesPage} />
          <PrivateRoute
            auth={profile}
            path={PathsTypes.INTERFACE}
            component={Interface}
          />
          <Route path="*" render={() => <ErrorPage title="👽&nbsp;Page&nbsp;not found..." />} />
        </Switch>
        <Notifier />
        <ModalWrapper history={history} />
      </div>
    </IntlProvider>
  )
}

export default App
