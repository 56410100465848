import React from 'react'

// components
import { Container, Box } from 'layout'

import Logo from 'components/Logo'
import { Modal } from 'components/modal'
import { Title, Text } from 'components/typo'
import { Button } from 'components/base'

import useResponsive from 'components/hooks/useResponsive'

const ErrorPage = ({
  title = '',
  btnTitle = 'Перейти на главную',
  htmlIcon = null,
  noBtn = false,
}) => {
  const { isMobile } = useResponsive()

  return (
    <Modal fixedTop>
      <Container
        fullHeight
        size="xs"
        className="mb-5"
      >
        <Box
          height="100%"
          direction="column"
          jc="space-between"
          ai="center"
          className={isMobile ? 'pt-5 pb-4' : 'pt-60 pb-6'}
        >
          <div className="mb-a">
            <Logo notitle />
          </div>
          <Box
            direction="column"
            ai="center"
            className="my-a"
          >
            <Title
              className={isMobile ? 'mb-5' : 'mb-40'}
              center
              tag={isMobile ? 'h4' : 'h2'}
            >
              {!!htmlIcon && (
                <span className="mr-3" role="img">
                  {htmlIcon}
                </span>
              )}
              {title}
            </Title>
            {!noBtn && (
              <a href="/" title="На главную">
                <Button size="big" title={btnTitle} />
              </a>
            )}
          </Box>
          <div className="mt-a">
            <Text size="small" color="grey500">© ООО «Вайби групп». 2020</Text>
          </div>
        </Box>
      </Container>
    </Modal>
  )
}

export default ErrorPage
