export const formOrderTypes = {
  TESTING: "TESTING",
  PROBLEM: "PROBLEM",
  INTEGRATIONS: "INTEGRATIONS",
  BUSINESS: "BUSINESS",
  PRO_BANNERS: "PRO_BANNERS",
  ACQUIRING: "ACQUIRING",
  DESIGN_THEME: "DESIGN_THEME",
  DESIGN_BUTTON: "DESIGN_BUTTON",
  ANALITICS: "ANALITICS",
};
