import React, {
  useCallback,
  useMemo,
} from 'react'
import s from 'styled-components'
import cn from 'classnames'
import { CardImage } from 'components/Card'

import {
  ANIMATION_FADE_OUT_FROM_RIGHT_CLASS,
  ANIMATION_FADE_OUT_FROM_LEFT_CLASS,
  INIT_ANIMATION_FADE_OUT_FROM_RIGHT_CLASS,
  INIT_ANIMATION_FADE_OUT_FROM_LEFT_CLASS,
} from 'constants/AnimationsTypes'

const ThemesPreviewImagesContainer = s.div`
  position: relative;

  .w-preview-image {
    position: absolute;
    box-shadow: 80px 60px 60px 0 rgba(113, 128, 150, 0.18);

    &.--active {
      position: relative;
    }

    img {
      user-select: none;
      pointer-events: none;
      touch-action: none;
    }
  }
`

const ThemesPreviewImages = ({
  activeTheme = null,
  items = [],
  isLaptopBigOrMobile = false,
}) => {
  const animationClass = useMemo(() => (isLaptopBigOrMobile
    ? ANIMATION_FADE_OUT_FROM_LEFT_CLASS
    : ANIMATION_FADE_OUT_FROM_RIGHT_CLASS),
  [ isLaptopBigOrMobile ])

  const animationInitClass = useMemo(() => (isLaptopBigOrMobile
    ? INIT_ANIMATION_FADE_OUT_FROM_LEFT_CLASS
    : INIT_ANIMATION_FADE_OUT_FROM_RIGHT_CLASS),
  [ isLaptopBigOrMobile ])

  const isActive = useCallback((themeId) => activeTheme === themeId, [ activeTheme ])

  const getClasses = useCallback((themeId) => ({
    [animationClass]: isActive(themeId),
    '--active': isActive(themeId),
  }), [ animationClass, isActive ])

  return (
    <ThemesPreviewImagesContainer className="w-preview-images">
      {items.map((theme) => (
        <div
          key={theme.id}
          className={cn('w-preview-image', animationInitClass, getClasses(theme.id))}
        >
          <CardImage
            width="345"
            height="auto"
            src={theme.img}
            srcSet={`${theme.imgX2} 2x`}
            alt={theme.title}
            isActive={isActive(theme.id)}
            isNoPlaceholder
          />
        </div>
      ))}
    </ThemesPreviewImagesContainer>
  )
}

export default ThemesPreviewImages
