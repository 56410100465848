import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import s from 'styled-components'
import cn from 'classnames'

import Logo from 'components/Logo'
import CompanyLogo from 'components/CompanyLogo'

import { Link } from 'components/typo'
import { Box } from 'layout'
import useResponsive from 'components/hooks/useResponsive'

import { setModal } from 'redux/actions/SystemActions'

import Formatted from 'components/typo/Formatted'
import InterfaceNavigationLink from './InterfaceNavigationLink'
import { NAV } from './navigation.data'

const InterfaceNavigationContainer = s.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: ${({ theme }) => theme.components.sidebar.width}px;
  height: 100vh;
  background-color: #fff;
  border-right: 1px solid ${({ theme }) => theme.colors.grey200};

  &.--desktop-small {
    width: 92px;
  }

  .navigation-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .navigation-head {
    margin-bottom: 26px;
    padding: 24px;
    font-weight: 600;
    font-size: 18px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      margin-right: 15px;
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.blue};
      border-radius: 12px;
    }

    span {
      color: ${({ theme }) => theme.colors.black};
    }
  }
`

const InterfaceNavigation = () => {
  const { isDesktopSmall } = useResponsive()
  const dispatch = useDispatch()
  const { company } = useSelector((state) => state.user && state.user.profile)
  const { newOrdersCount } = useSelector((state) => state.orders)
  const { active_subscription: activeSubscription } = company

  const handleModal = useCallback((name) => {
    dispatch(setModal({ name }))
  }, [ dispatch ])

  const handleSubscriptions = useCallback((e) => {
    e.preventDefault()
    handleModal('subscriptions')
  }, [ handleModal ])

  return (
    <InterfaceNavigationContainer className={cn('navigation', { '--desktop-small': isDesktopSmall })}>
      <div className="navigation-inner">
        <CompanyLogo isDesktopSmall={isDesktopSmall} company={company} />

        <div>
          {NAV.map(({
            icon,
            path,
            id,
            label,
            bage,
            modalName,
            count,
          }) => (
            <InterfaceNavigationLink
              className="navigation-link"
              key={id}
              to={path}
              bage={bage}
              label={label}
              icon={icon}
              hover="black"
              count={count}
              newOrdersCount={newOrdersCount}
              isDesktopSmall={isDesktopSmall}
              onClick={modalName ? (e) => {
                e.preventDefault()
                handleModal(modalName)
              } : null}
            />
          ))}
        </div>
        <Box
          p="24px"
          display="flex"
          ai="center"
          jc="space-between"
          className="mt-a navigation-footer"
        >
          <Logo
            size="small"
            notitle={isDesktopSmall}
          />
          {!isDesktopSmall && activeSubscription && (
            <Link
              to="#subscriptions"
              onClick={handleSubscriptions}
              color="grey500"
            >
              {activeSubscription.rate_name || <Formatted id="tariff.name.start" />}
            </Link>
          )}
        </Box>
      </div>
    </InterfaceNavigationContainer>
  )
}

export default InterfaceNavigation
